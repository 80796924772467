export class Employee {
  id?: number
  code: string
  fullName: string
  roleId: number
  roleTitle: string
  regions: string
  territoryCode: string
  territoryDesc: string
  status: string
  registeredDate: string
  userId: number
  phone: string
  totalPoint: number
  organizationChart: object
  saleType: string
  level: number
  levels: number[]
  shops: string
  createdAt: string
  updatedAt: string
  deletedAt: string
  points: number

  constructor() {
    this.code = ''
    this.fullName = ''
    this.roleId = 0
    this.roleTitle = ''
    this.regions = ''
    this.territoryCode = ''
    this.territoryDesc = ''
    this.status = ''
    this.registeredDate = ''
    this.userId = 0
    this.phone = ''
    this.totalPoint = 0
    this.organizationChart = {}
    this.saleType = ''
    this.level = 0
    this.levels = []
    this.shops = ''
    this.createdAt = ''
    this.updatedAt = ''
    this.deletedAt = ''
    this.points = 0
  }
}

export type EmployeeList = Employee[]