
import { Component, Vue } from "vue-property-decorator";
import { forEach } from "lodash";
import Utils from "@/mixin";
import { itemsPerPageOptions } from "@/plugins/common";

import LightBox from "@/components/LightBox.vue";

@Component({
  components: {
    LightBox,
  },
  mixins: [Utils],
  mounted() {
    // console.log(this);
    this.getInitialData();
    this.itemsPerPageOptions = itemsPerPageOptions;
  },
  watch: {
    // keyword(nextProps, prevProps) {
    //   /* istanbul ignore else */
    //   if (nextProps !== prevProps) {
    //     this.getInitialData();
    //   }
    // },
    itemPerPage(nextProps, prevProps) {
      /* istanbul ignore else */
      if (nextProps !== prevProps) {
        this.getInitialData();
      }
    },
    page(nextProps, prevProps) {
      /* istanbul ignore else */
      if (nextProps !== prevProps) {
        this.getInitialData();
      }
    },
    /* istanbul ignore next */
    historyList(nextProps, prevProps) {
      if (nextProps !== prevProps) {
        forEach(this.historyList, (i) => {
          this.total = i.total;
          // this.itemPerPage = i.perPage;
        });
      }
    },
  },
})
export default class History extends Vue {
  page = 1;
  total = 0;
  itemsPerPageOptions = [];
  itemPerPage = 10;
  keyword = "";
  redeemType = "Reward";
  redeemItems = ["Reward", "Luckydraw"];
  headers = [
    { text: "Create Date", value: "createdAt", sortable: false },
    { text: "Title", value: "title", align: "start", sortable: false },
    { text: "Shop Code", value: "shopCode", sortable: false },
    { text: "Description", value: "description", sortable: false },
    { text: "Image", value: "imageUrl", sortable: false },
    { text: "Quantity", value: "quantity", align: "center", sortable: false },
    { text: "Status", value: "status", align: "center", sortable: false },
    { text: "Type", value: "type", align: "center", sortable: false },
    {
      text: "Spent Point",
      value: "spentPoint",
      align: "center",
      sortable: false,
    },
    {
      text: "Redeem Point",
      value: "redeemPoint",
      align: "center",
      sortable: false,
    },
    { text: "Actions", value: "actions", sortable: false },
  ];
  imgArr: Array<string> = [];
  imgShow = false;

  getHistory!: (searchForm?: object) => void;
  // eslint-disable-next-line
  onKeyDown(e: any) {
    const { keyCode } = e;
    if (keyCode === 13) {
      this.getInitialData();
    }
  }
  // eslint-disable-next-line
  showImg(image: any) {
    this.imgShow = true;
    if (typeof image === "string") {
      this.imgArr = [image];
    } else {
      this.imgArr = image;
    }
  }

  getInitialData() {
    const { page, itemPerPage, keyword, redeemType } = this;
    const searchForm = {
      page,
      perPage: itemPerPage,
      keyword,
      mode: redeemType.toLowerCase(),
      type: "employee",
    };
    this.getHistory(searchForm);
  }
}
