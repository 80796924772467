
import { Component, Ref, Vue } from "vue-property-decorator";
import { findIndex, isEmpty } from "lodash";
import Utils from "@/mixin";
import { Reward, VForm } from "@/type";

@Component({
  props: {
    editId: {
      type: [Number, String],
      default: "",
    },
    winnerId: {
      type: [Number, String],
      default: "",
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    toggleDialog: {
      type: Function,
      // eslint-disable-next-line
      default: /* istanbul ignore next */ () => {},
    },
    setInitialDialog: {
      type: Function,
      // eslint-disable-next-line
      default: /* istanbul ignore next */ () => {},
    },
  },
  mixins: [Utils],
  computed: {
    validate() {
      const { order, employeeCode, employeeName, point, territoryCode, percentage } =
        this.formData;
      if (
        (this.editId === "" &&
          (isEmpty(employeeName) ||
            isEmpty(employeeCode) ||
            isEmpty(territoryCode) ||
            order === 0 ||
            point === 0 ||
            percentage === 0)) ||
        (this.editId !== "" &&
          (isEmpty(employeeName) ||
            isEmpty(employeeCode) ||
            isEmpty(territoryCode) ||
            order === 0 ||
            point === 0 ||
            percentage === 0))
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    editId(nextProps, prevProps) {
      /* istanbul ignore else */
      if (prevProps !== nextProps && nextProps !== "") {
        this.modalTitle = "Edit";
        const { heroWinnerList } = this;
        const index = findIndex(heroWinnerList, (i: Reward) => i.id === nextProps);
        const data = heroWinnerList[index];
        this.formData = {
          order: data.order,
          employeeCode: data.employeeCode,
          employeeName: data.employeeName,
          point: data.point,
          territoryCode: data.territoryCode,
          percentage: data.percentage,
        };
      }
    },
  },
})
export default class EmployeeWinnerForm extends Vue {
  modalTitle = "Create";
  formData = {
    order: "",
    employeeCode: "",
    employeeName: "",
    point: 0,
    territoryCode: "",
    percentage: 0,
  };
  initialForm = {
    order: "",
    employeeCode: "",
    employeeName: "",
    point: 0,
    territoryCode: "",
    percentage: 0,
  };
  /* istanbul ignore next */
  rules = {
    required: (value: [number, string]) => !!value || "Required.",
    min: (value: number) => value > 0 || "Point must more than 0",
  };

  @Ref("form") readonly form!: VForm;
  createHeroWinnerDetail!: (formData: object) => void;
  editHeroWinnerDetail!: (formData: object) => void;
  toggleDialog!: () => void;

  clearData() {
    if (this.$props.editId !== "") this.$props.setInitialDialog();
    this.toggleDialog();
    this.$nextTick(() => {
      this.modalTitle = "Create";
      this.formData = Object.assign({}, this.initialForm);
      /* istanbul ignore next */
      if (this.form) this.form.resetValidation();
    });
  }

  submitForm() {
    const { formData } = this;
    if (this.$props.editId !== "") {
      const form = {
        ...formData,
        winnerId: this.$props.winnerId,
        editId: this.$props.editId,
      };
      this.editHeroWinnerDetail(form);
    } else {
      const form = {
        ...formData,
        winnerId: this.$props.winnerId,
      };
      this.createHeroWinnerDetail(form);
    }
    this.clearData();
  }
}
