import axios from 'axios'

import { getToken } from '@/plugins/auth'

const token = getToken()

let headers = {}
/* istanbul ignore next */
if (token) {
  headers = {
    Authorization: `Bearer ${token}`
  }
}

const baseURL = process.env.VUE_APP_API_URL

const REQUEST = axios.create({
  baseURL,
  headers
})

export async function REQUEST_GET(URL: string, data?: object) {
  const res = await REQUEST.get(URL, { params: data })
  return res
}

export async function REQUEST_POST(URL: string, data?: object) {
  const res = await REQUEST.post(URL, data)
  return res
}

export async function REQUEST_PUT(URL: string, data?: object) {
  const res = await REQUEST.put(URL, data)
  return res
}

export async function REQUEST_DELETE(URL: string, paramId?: number) {
  const res = await REQUEST.delete(URL, { data: paramId })
  return res
}

export async function REQUEST_PATCH(URL: string, data?: object) {
  const res = await REQUEST.patch(URL, data)
  return res
}

export async function REQUEST_DOWNLOAD(URL: string, data?: object) {
  const res = await REQUEST.get(URL, { 
    params: data ,
    responseType:"blob"
  })
  return res
}