
import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component({
  computed: {
    dateValue: {
      get() {
        return this.value;
      },
      set() {
        return this.value;
      },
    },
  },
})
export default class DateInput extends Vue {
  @Prop({ default: "Date Input" }) label!: string;
  @Prop(String) value!: string;
  @Prop({ default: false }) disableMin!: boolean;
  @Prop({ default: false }) clearable!: boolean;

  min = new Date().toISOString().substring(0, 10);
  dialog = false;

  handleDialog() {
    this.dialog = !this.dialog;
  }

  @Emit()
  updateDate(e: string) {
    return e;
  }
}
