export class Auth {
  email: string
  password: string
  token?: string | null

  constructor() {
    this.email = ''
    this.password = ''
  }
}

export class Token {
  accessToken: string
  tokenType: string
  expiresIn: number

  constructor() {
    this.accessToken = ''
    this.tokenType = ''
    this.expiresIn = 0
  }
}