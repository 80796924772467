import { Module } from 'vuex'
import { get, isEmpty } from 'lodash'

import { RootState, Auth, Token, AlertMessage } from '@/type'
import { REQUEST_POST } from './API_Request'
import router from '@/router'
import { getToken } from '@/plugins/auth'
import { errorMessage } from '@/plugins/common'
import { GLOBAL_MESSAGE, LOGIN_MESSAGE } from '@/plugins/message'

export const state = {
  email: '',
  password: '',
  token: ''
}

export const mutations = {
  async LOGIN(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      const res = await REQUEST_POST('/auth', data.formData)
      // console.log(res);

      const token = new Token()
      token.accessToken = get(res, 'data.access_token')
      token.tokenType = get(res, 'data.token_type')
      token.expiresIn = get(res, 'data.expires_in')

      state.token = token.accessToken
      localStorage.setItem('token', token.accessToken)
      data.toggleLoading()

      switch (get(res, 'status')) {
        case 200: {
          const alert = new AlertMessage(LOGIN_MESSAGE.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          router.push({ name: 'Home' })
          location.reload()
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
       /* istanbul ignore else */
      if (error) alert = new AlertMessage(LOGIN_MESSAGE.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return e
    }
  },
  LOGOUT() {
    localStorage.removeItem('token')
    // if (get(router, 'history.current.name') !== 'Login') router.push({ name: "Login" })
    location.reload()
  }
}

export const actions = {
  login(context: any, formData: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm: AlertMessage) => context.dispatch('toggleAlertMessage', alertForm)
    context.commit('LOGIN', { toggleLoading, formData, toggleAlert })
  },
  logout(context: any) { // eslint-disable-line
    context.commit('LOGOUT')
  }
}

export const getters = {
  // Add Logic Before Computed
  auth(state: any): Auth { // eslint-disable-line
    const token = getToken()
     /* istanbul ignore next */
    if (!isEmpty(token)) state.token = token
    return state
  },
}

export const authentication: Module<Auth, RootState> = {
  state,
  mutations,
  actions,
  getters
}