export class User {
  id: number | string
  email: string
  name: string
  remark?: string
  createdAt?: Date
  updatedAt?: Date
  deletedAt?: Date

  constructor(id = 0, email = '', name = '', remark = '') {
    this.id = id
    this.email = email
    this.name = name
    this.remark = remark
  }
}

export class LineUser {
  id: number | string
  displayName?: string
  createdAt?: Date
  updatedAt?: Date
  deletedAt?: Date
  lineID: number | string
  citizenID?: number | string
  phoneNumber?: number | string
  otpRef?: string
  pincode?: number | string
  citizenType?: string
  shop?: string
  employee?: string
  Type?: string
  taxType?: string
  taxCitizenId?: number | string
  taxPrefix?: string
  taxFirstName?: string
  taxLastName?: string
  taxAddressNumber?: number | string
  taxBuilding?: string
  taxFloor?: string
  taxMoo?: string
  taxAlley?: string
  taxRoad?: string
  taxDistrict?: string
  taxSubDistrict?: string
  taxProvince?: string
  taxPostCode?: number | string
  taxCompanyType?: string
  taxCompanyName?: string

  constructor() {
    this.id = 0
    this.lineID = ''
  }
}

export type UserList = User[]

export type LineUserList = LineUser[]