
import moment from "moment";
import { Component, Vue } from "vue-property-decorator";
import { findIndex, forEach } from "lodash";
import Utils from "@/mixin";
import { itemsPerPageOptions } from "@/plugins/common";

import CreateAnnouncement from "./HeroAnnouncement/Announcement.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";

@Component({
  components: {
    CreateAnnouncement,
    ConfirmDialog,
  },
  mixins: [Utils],
  mounted() {
    // console.log(this);
    this.getInitialData();
    this.itemsPerPageOptions = itemsPerPageOptions;
  },
  watch: {
    // keyword(nextProps, prevProps) {
    //   /* istanbul ignore else */
    //   if (nextProps !== prevProps) {
    //     this.getInitialData();
    //   }
    // },
    itemPerPage(nextProps, prevProps) {
      /* istanbul ignore else */
      if (nextProps !== prevProps) {
        this.getInitialData();
      }
    },
    page(nextProps, prevProps) {
      /* istanbul ignore else */
      if (nextProps !== prevProps) {
        this.getInitialData();
      }
    },
    /* istanbul ignore next */
    heroLeaderList(nextProps, prevProps) {
      if (nextProps !== prevProps) {
        forEach(this.heroLeaderList, (i) => {
          this.total = i.total;
          // this.itemPerPage = i.perPage;
        });
      }
    },
  },
})
export default class HeroAnnouncement extends Vue {
  editId = "";
  deleteId = -1;
  confirmDialog = false;
  page = 1;
  total = 0;
  itemsPerPageOptions = [];
  itemPerPage = 10;
  keyword = "";
  level = "6 - พนักงานขาย";
  dialog = false;
  levelItems = [
    { text: "6 - พนักงานขาย", value: 6 },
    { text: "5 - หัวหน้าเขต", value: 5 },
    { text: "4 - หัวหน้าภาค", value: 4 },
  ];
  headers = [
    {
      text: "Create Date",
      value: "createdAt",
      align: "start",
      sortable: false,
    },
    {
      text: "Announce Date",
      value: "startDate",
      align: "start",
      sortable: false,
    },
    {
      text: "Level",
      value: "level",
      align: "center",
      sortable: false,
    },
    {
      text: "Title",
      value: "title",
      sortable: false,
    },
    {
      text: "Status",
      value: "status",
      sortable: false,
    },
    { text: "Actions", value: "actions", sortable: false, width: "100px" },
  ];

  getHeroWinner!: (searchForm?: object) => void;
  removeHeroWinner!: (paramId: number) => void;
  // eslint-disable-next-line
  onKeyDown(e: any) {
    const { keyCode } = e;
    if (keyCode === 13) {
      this.getInitialData();
    }
  }

  dateFormat(date: string) {
    return moment(date, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY");
  }

  getInitialData() {
    const { page, itemPerPage, keyword, level, levelItems } = this;
    const index = findIndex(levelItems, { text: level });
    const searchForm = {
      page,
      perPage: itemPerPage,
      keyword,
      level: levelItems[index].value,
    };
    this.getHeroWinner(searchForm);
  }

  setInitialDialog() {
    this.editId = "";
  }

  toggleDialog() {
    this.dialog = !this.dialog;
  }

  toggleConfirmModal(paramId: number) {
    this.deleteId = paramId;
    this.confirmDialog = !this.confirmDialog;
  }

  selectItem(employeeId: string) {
    this.$router.push({
      name: "Hero-Announcement-Employee-Camp",
      params: { employeeId },
    });
  }

  editItem(paramId: string) {
    this.editId = paramId;
    this.toggleDialog();
  }

  cancel() {
    this.deleteId = -1;
    this.confirmDialog = !this.confirmDialog;
  }

  actionItem() {
    const { deleteId } = this;
    this.removeHeroWinner(deleteId);
    this.cancel();
  }
}
