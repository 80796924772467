import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

const opts = {}

const vuetify = new Vuetify(opts)

export default vuetify;
