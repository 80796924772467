
import { Component, Ref, Vue } from "vue-property-decorator";
import { findIndex, isEmpty, get, forEach } from "lodash";
import Utils from "@/mixin";
import { Product, SelectItem, VForm } from "@/type";

@Component({
  props: {
    editId: {
      type: [Number, String],
      default: "",
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    toggleDialog: {
      type: Function,
      // eslint-disable-next-line
      default: /* istanbul ignore next */ () => {},
    },
    setInitialDialog: {
      type: Function,
      // eslint-disable-next-line
      default: /* istanbul ignore next */ () => {},
    },
  },
  mixins: [Utils],
  computed: {
    generataSelectItems() {
      const { brandList, editId } = this;
      const newArray: SelectItem[] = [];
      forEach(brandList, (i) => {
        const selectItem = {
          text: get(i, "name", ""),
          value: get(i, "id"),
        };
        newArray.push(selectItem);
      });
      if (!editId) this.formData.brandId = brandList[0]?.id;
      return newArray;
    },
    validate() {
      const { name, sku, point, price, size, unit } = this.formData;
      if (
        (this.editId === "" &&
          (isEmpty(name) ||
            isEmpty(sku) ||
            isEmpty(unit) ||
            parseInt(size) === 0 ||
            parseInt(point) === 0 ||
            parseInt(price) === 0)) ||
        (this.editId !== "" &&
          (isEmpty(name) ||
            isEmpty(sku) ||
            isEmpty(unit) ||
            parseInt(size) === 0 ||
            parseInt(point) === 0 ||
            parseInt(price) === 0))
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    editId(nextProps, prevProps) {
      /* istanbul ignore else */
      if (prevProps !== nextProps && nextProps !== "") {
        this.modalTitle = "Edit";
        const { productList } = this;
        const index = findIndex(
          productList,
          (i: Product) => i.id === this.editId
        );
        /* istanbul ignore else */
        this.formData = {
          name: productList[index].name,
          sku: productList[index].sku,
          price: productList[index].price,
          pack: productList[index].pack,
          point: productList[index].point,
          isRecommend: productList[index].isRecommend,
          size: productList[index].size,
          unit: productList[index].unit,
          brandId: productList[index].brandId,
        };
      }
    },
  },
})
export default class ProductForm extends Vue {
  modalTitle = "Create";
  packItem = [6, 10, 12, 24, 36];
  initialForm = {
    name: "",
    sku: "",
    price: 0,
    point: 0,
    isRecommend: false,
    pack: 6,
    unit: "",
    size: 0,
    brandId: null,
  };
  formData = {
    name: "",
    sku: "",
    price: 0,
    point: 0,
    isRecommend: false,
    pack: 6,
    unit: "",
    size: 0,
    brandId: null,
  };
  /* istanbul ignore next */
  rules = {
    required: (value: [number, string]) => !!value || "Required.",
    min: (value: number) => value > 0 || "Must more than 0",
  };

  @Ref("form") readonly form!: VForm;
  createCalculatorProduct!: (formData: object) => void;
  editCalculatorProduct!: (formData: object) => void;
  toggleDialog!: () => void;

  clearData() {
    if (this.$props.editId !== "") this.$props.setInitialDialog();
    this.toggleDialog();
    this.$nextTick(() => {
      this.modalTitle = "Create";
      this.formData = Object.assign({}, this.initialForm);
      /* istanbul ignore next */
      if (this.form) this.form.resetValidation();
    });
  }

  submitForm() {
    const { formData } = this;
    if (this.$props.editId !== "") {
      const submitForm = {
        name: get(formData, "name"),
        sku: get(formData, "sku"),
        size: get(formData, "size"),
        unit: get(formData, "unit"),
        price: get(formData, "price"),
        pack: get(formData, "pack"),
        point: get(formData, "point"),
        brandId: get(formData, "brandId"),
        isRecommend: get(formData, "isRecommend"),
        editId: this.$props.editId,
      };
      this.editCalculatorProduct(submitForm);
    } else {
      const submitForm = {
        name: get(formData, "name"),
        sku: get(formData, "sku"),
        size: get(formData, "size"),
        unit: get(formData, "unit"),
        price: get(formData, "price"),
        pack: get(formData, "pack"),
        point: get(formData, "point"),
        brandId: get(formData, "brandId"),
        isRecommend: get(formData, "isRecommend"),
      };
      this.createCalculatorProduct(submitForm);
    }

    this.clearData();
  }
}
