export const GLOBAL_MESSAGE = {
  ERROR: 'Something when wrong!',
  CREATE: {
    SUCCESS: 'Create Successfully',
    ERROR: 'Create Failed'
  },
  EDIT: {
    SUCCESS: 'Edit Successfully',
    ERROR: 'Edit Failed'
  },
  DELETE: {
    SUCCESS: 'Delete Successfully',
    ERROR: 'Delete Failed'
  }
}

export const LOGIN_MESSAGE = {
  SUCCESS: 'Login Successfully',
  ERROR: 'Login Failed',
}

export const BANNER_MESSAGE = {
  CREATE: {
    SUCCESS: 'Create Successfully',
    ERROR: 'Create Failed'
  },
  DELETE: {
    SUCCESS: 'Delete Successfully',
    ERROR: 'Delete Failed'
  }
}

export const EMPLOYEE_MEESAGE = {
  SYNC: {
    SUCCESS: 'Sync Successfully',
    ERROR: 'Sync Failed'
  },
  UNLINK: {
    SUCCESS: 'Unlink Successfully',
    ERROR: 'Unlink Failed'
  }
}

export const POINT_RULE_MEESAGE = {
  CREATE: {
    SUCCESS: 'Create Successfully',
    ERROR: 'Create Failed'
  },
  EDIT: {
    SUCCESS: 'Edit Successfully',
    ERROR: 'Edit Failed'
  },
  DELETE: {
    SUCCESS: 'Delete Successfully',
    ERROR: 'Delete Failed'
  }
}

export const PRODUCT_MEESAGE = {
  CREATE: {
    SUCCESS: 'Create Successfully',
    ERROR: 'Create Failed'
  },
  EDIT: {
    SUCCESS: 'Edit Successfully',
    ERROR: 'Edit Failed'
  },
  DELETE: {
    SUCCESS: 'Delete Successfully',
    ERROR: 'Delete Failed'
  },
  IMPORT: {
    SUCCESS: 'Import Successfully',
    ERROR: 'Import Failed'
  },
}

export const HERO_MESSAGE = {
  REWARD: {
    CREATE: {
      SUCCESS: 'Create Successfully',
      ERROR: 'Create Failed'
    },
    EDIT: {
      SUCCESS: 'Edit Successfully',
      ERROR: 'Edit Failed'
    },
    DELETE: {
      SUCCESS: 'Delete Successfully',
      ERROR: 'Delete Failed'
    }
  },
  WINNER: {
    CREATE: {
      SUCCESS: 'Create Successfully',
      ERROR: 'Create Failed'
    },
    EDIT: {
      SUCCESS: 'Edit Successfully',
      ERROR: 'Edit Failed'
    },
    DELETE: {
      SUCCESS: 'Delete Successfully',
      ERROR: 'Delete Failed'
    }
  }
}

export const PROMOTION_MESSAGE = {
  CREATE: {
    SUCCESS: 'Create Successfully',
    ERROR: 'Create Failed'
  },
  EDIT: {
    SUCCESS: 'Edit Successfully',
    ERROR: 'Edit Failed'
  },
  DELETE: {
    SUCCESS: 'Delete Successfully',
    ERROR: 'Delete Failed'
  }
}

export const SHOP_MESSAGE = {
  DELETE: {
    SUCCESS: 'Delete Successfully',
    ERROR: 'Delete Failed'
  },
  SYNC: {
    SUCCESS: 'Sync Successfully',
    ERROR: 'Sync Failed'
  },
  UNLINK: {
    SUCCESS: 'Unlink Successfully',
    ERROR: 'Unlink Failed'
  },
  UPLOAD:{
    SUCCESS: 'Upload Successfully',
    ERROR: 'Upload Failed'
  }
}

export const USER_MEESAGE = {
  CREATE: {
    SUCCESS: 'Create Successfully',
    ERROR: 'Create Failed'
  },
  DELETE: {
    SUCCESS: 'Delete Successfully',
    ERROR: 'Delete Failed'
  }
}

export const REWARD_MESSAGE = {
  REWARD: {
    CREATE: {
      SUCCESS: 'Create Successfully',
      ERROR: 'Create Failed'
    },
    EDIT: {
      SUCCESS: 'Edit Successfully',
      ERROR: 'Edit Failed'
    },
    DELETE: {
      SUCCESS: 'Delete Successfully',
      ERROR: 'Delete Failed'
    }
  },
  CATEGORY: {
    CREATE: {
      SUCCESS: 'Create Successfully',
      ERROR: 'Create Failed'
    },
    EDIT: {
      SUCCESS: 'Edit Successfully',
      ERROR: 'Edit Failed'
    },
    DELETE: {
      SUCCESS: 'Delete Successfully',
      ERROR: 'Delete Failed'
    }
  }
}

export const LUCKY_DRAW_MESSAGE = {
  CONTENT: {
    CREATE: {
      SUCCESS: 'Create Successfully',
      ERROR: 'Create Failed'
    },
    EDIT: {
      SUCCESS: 'Edit Successfully',
      ERROR: 'Edit Failed'
    }
  },
  REWARD: {
    CREATE: {
      SUCCESS: 'Create Successfully',
      ERROR: 'Create Failed'
    },
    EDIT: {
      SUCCESS: 'Edit Successfully',
      ERROR: 'Edit Failed'
    }
  },
  WINNER: {
    CREATE: {
      SUCCESS: 'Create Successfully',
      ERROR: 'Create Failed'
    },
  },
  ANNOUNCE: {
    SUCCESS: 'Announcement Successfully',
    ERROR: 'Announcement Failed',
  },
  DELETE: {
    SUCCESS: 'Delete Successfully',
    ERROR: 'Delete Failed',
  },
}