
import { Component, Vue } from "vue-property-decorator";
import { forEach, size } from "lodash";
import Utils from "@/mixin";
import { Product as ProductType } from "@/type";
import { itemsPerPageOptions } from "@/plugins/common";

import CreateProduct from "./Calculator/Form.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
// import ImportExcel from "@/components/ImportExcel.vue";

@Component({
  components: {
    CreateProduct,
    ConfirmDialog,
    // ImportExcel,
  },
  mixins: [Utils],
  mounted() {
    this.getInitialData();
    this.itemsPerPageOptions = itemsPerPageOptions;
  },
  computed: {
    generateTable() {
      const { productList, brandList } = this;
      /* istanbul ignore else */
      if (
        (productList !== undefined || size(productList) > 0) &&
        (brandList !== undefined || size(brandList) > 0)
      ) {
        const tableList: ProductType[] = [];
        forEach(productList, (i) => {
          const product = new ProductType();
          product.id = i.id;
          product.sku = i.sku;
          product.name = i.name;
          product.brandId = i.brandId;
          forEach(brandList, (j) => {
            if (j.id === i.brandId) {
              product.brand = j.name;
            }
          });
          product.size = i.size;
          product.price = i.price;
          product.point = i.point;
          product.pack = i.pack;
          product.isRecommend = i.isRecommend;
          product.unit = i.unit;
          this.total = i.total;
          // this.itemPerPage = i.perPage;
          tableList.push(product);
        });
        return tableList;
      } else {
        return [];
      }
    },
  },
  watch: {
    // keyword(nextProps, prevProps) {
    //   /* istanbul ignore else */
    //   if (nextProps !== prevProps) {
    //     this.getInitialData();
    //   }
    // },
    itemPerPage(nextProps, prevProps) {
      /* istanbul ignore else */
      if (nextProps !== prevProps) {
        this.getInitialData();
      }
    },
    page(nextProps, prevProps) {
      /* istanbul ignore else */
      if (nextProps !== prevProps) {
        this.getInitialData();
      }
    },
  },
})
export default class Calculator extends Vue {
  editId = "";
  deleteId = -1;
  confirmDialog = false;
  page = 1;
  total = 0;
  itemsPerPageOptions = [];
  itemPerPage = 10;
  keyword = "";
  dialog = false;
  headers = [
    { text: "SKU", value: "sku", sortable: false },
    { text: "Brand", value: "brand", sortable: false },
    {
      text: "Product Name",
      value: "name",
      align: "start",
      sortable: false,
    },
    { text: "Size", value: "size", sortable: false },
    { text: "Price", value: "price", sortable: false },
    { text: "Pack", value: "pack", sortable: false },
    { text: "Point", value: "point", sortable: false },
    { text: "Unit", value: "unit", sortable: false },
    {
      text: "Recommend",
      value: "isRecommend",
      align: "center",
      width: "120px",
      sortable: false,
    },
    { text: "Actions", value: "actions", sortable: false },
  ];

  getCalculatorProduct!: (searchForm?: object) => void;
  getBrand!: () => void;
  removeCalculatorProduct!: (paramId: number) => void;
  importCalculatorProduct!: (formData: object) => void;
  // eslint-disable-next-line
  onKeyDown(e: any) {
    const { keyCode } = e;
    if (keyCode === 13) {
      this.getInitialData();
    }
  }

  getInitialData() {
    const { page, itemPerPage, keyword } = this;
    const searchForm = {
      page,
      perPage: itemPerPage,
      keyword,
    };
    this.getCalculatorProduct(searchForm);
    this.getBrand();
  }

  toggleDialog() {
    this.dialog = !this.dialog;
  }

  toggleConfirmModal(paramId: number) {
    this.deleteId = paramId;
    this.confirmDialog = !this.confirmDialog;
  }

  editItem(paramId: string) {
    this.editId = paramId;
    this.toggleDialog();
  }

  setInitialDialog() {
    this.editId = "";
  }

  cancel() {
    this.deleteId = -1;
    this.confirmDialog = !this.confirmDialog;
  }

  actionItem() {
    const { deleteId } = this;
    this.removeCalculatorProduct(deleteId);
    this.cancel();
  }

  importExcel(file: File) {
    const formData = { file };
    this.importCalculatorProduct(formData);
  }
}
