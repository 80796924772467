import { Winner } from '@/type'

export class LuckyReward {
  id?: number
  createdAt?: string
  updatedAt?: string
  deletedAt?: string
  title: string
  description: string
  image: string
  quantity: number
  imageUrl?: string
  luckyDrawId?: number
  winners?: Winner[]

  constructor() {
    this.title = ''
    this.description = ''
    this.image = ''
    this.quantity = 0
  }
}

export class Reward {
  id?: number
  title: string
  detail: string
  categoryId?: number
  redeemPoint?: number
  type?: string
  redeemAble?: boolean
  image?: File

  constructor() {
    this.title = ''
    this.detail = ''
  }
}

export class RewardCategory {
  id?: number
  title?: string
  imageUrl?: string

  constructor() {
    this.title = ''
  }
}

export type LuckyDrawRewardList = LuckyReward[]

export type RewardList = Reward[]

export type RewardCategoryList = RewardCategory[]