
import { Component, Vue } from "vue-property-decorator";
import { getJWTToken } from "@/plugins/auth";
import { forEach } from "lodash";
import Utils from "@/mixin";
import { itemsPerPageOptions } from "@/plugins/common";

import CreateUser from "./User/Form.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";

@Component({
  components: {
    CreateUser,
    ConfirmDialog,
  },
  mixins: [Utils],
  mounted() {
    // console.log(this);
    this.getInitialData();
    this.itemsPerPageOptions = itemsPerPageOptions;
  },
  computed: {
    /* istanbul ignore next */
    currentUserId() {
      const userId = getJWTToken();
      return userId;
    },
  },
  watch: {
    // keyword(nextProps, prevProps) {
    //   /* istanbul ignore else */
    //   if (nextProps !== prevProps) {
    //     this.getInitialData();
    //   }
    // },
    itemPerPage(nextProps, prevProps) {
      /* istanbul ignore else */
      if (nextProps !== prevProps) {
        this.getInitialData();
      }
    },
    page(nextProps, prevProps) {
      /* istanbul ignore else */
      if (nextProps !== prevProps) {
        this.getInitialData();
      }
    },
    /* istanbul ignore next */
    userList(nextProps, prevProps) {
      if (nextProps !== prevProps) {
        forEach(this.userList, (i) => {
          this.total = i.total;
          // this.itemPerPage = i.perPage;
        });
      }
    },
  },
})
export default class User extends Vue {
  deleteId = -1;
  confirmDialog = false;
  page = 1;
  total = 0;
  itemsPerPageOptions = [];
  itemPerPage = 10;
  keyword = "";
  dialog = false;
  headers = [
    { text: "Name", value: "name", align: "start", sortable: false },
    { text: "Email", value: "email", sortable: false },
    { text: "Remark", value: "remark", sortable: false },
    { text: "Actions", value: "actions", sortable: false },
  ];

  getUser!: (searchForm?: object) => void;
  removeUser!: (paramId: number) => void;
  // eslint-disable-next-line
  onKeyDown(e: any) {
    const { keyCode } = e;
    if (keyCode === 13) {
      this.getInitialData();
    }
  }

  getInitialData() {
    const { page, itemPerPage, keyword } = this;
    const searchForm = {
      page,
      perPage: itemPerPage,
      keyword,
    };
    this.getUser(searchForm);
  }

  toggleDialog() {
    this.dialog = !this.dialog;
  }

  toggleConfirmModal(paramId: number) {
    this.deleteId = paramId;
    this.confirmDialog = !this.confirmDialog;
  }

  cancel() {
    this.deleteId = -1;
    this.confirmDialog = !this.confirmDialog;
  }

  actionItem() {
    const { deleteId } = this;
    this.removeUser(deleteId);
    this.cancel();
  }
}
