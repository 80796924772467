
import Vue from "vue";

export default Vue.extend({
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    _close() {
      this.$emit("update:show", false);
    },
  },
});
