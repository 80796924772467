
import { Component, Ref, Vue } from "vue-property-decorator";
import { capitalize, forEach, findIndex, isEmpty, get } from "lodash";
import Utils from "@/mixin";
import { Promotion, PromotionShopType, VForm } from "@/type";

import DateInput from "@/components/DateInput.vue";
import TimeInput from "@/components/TimeInput.vue";

@Component({
  components: {
    DateInput,
    TimeInput,
  },
  props: {
    editId: {
      type: [Number, String],
      default: "",
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    toggleDialog: {
      type: Function,
      // eslint-disable-next-line
      default: /* istanbul ignore next */ () => {},
    },
    setInitialDialog: {
      type: Function,
      // eslint-disable-next-line
      default: /* istanbul ignore next */ () => {},
    },
  },
  mixins: [Utils],
  computed: {
    validate() {
      const { name, title, description, image, order } = this.formData;
      if (
        (this.editId === "" &&
          (isEmpty(name) ||
            isEmpty(title) ||
            get(description, "length") === 0 ||
            image === undefined ||
            order === 0)) ||
        (this.editId !== "" &&
          (isEmpty(name) ||
            isEmpty(title) ||
            get(description, "length") === 0 ||
            order === 0))
      ) {
        return true;
      } else {
        return false;
      }
    },
    shopTypeItems() {
      const { promotionShopTypeList } = this;
      const arrayList: object[] = [];
      forEach(promotionShopTypeList, (e) => {
        arrayList.push({ text: capitalize(e.type), value: e.id });
      });
      if (arrayList.length > 0 && this.formData.shopType === undefined) {
        const newArray: number[] = [];
        newArray.push(parseInt(get(arrayList[0], "value", 0)));
        this.formData.shopType = newArray;
      }
      return arrayList;
    },
  },
  watch: {
    editId(nextProps, prevProps) {
      /* istanbul ignore else */
      if (prevProps !== nextProps && nextProps !== "") {
        this.modalTitle = "Edit";
        const { promotionList, promotionShopTypeList } = this;
        const index = findIndex(
          promotionList,
          (i: Promotion) => i.id === nextProps
        );
        const data = promotionList[index];
        const shopTypeList: any = [] // eslint-disable-line
        forEach(data.shopType, (j) => {
            const shopTypeIndex = findIndex(
              promotionShopTypeList,
              (e: PromotionShopType) => e.id === j.shopTypeId
            );
            shopTypeList.push(parseInt(promotionShopTypeList[shopTypeIndex]?.id));
          });
        // console.log(data);
        this.formData = {
          name: data.name,
          title: data.title,
          description: data.description,
          isPublic: data.isPublic,
          order: data.order,
          startDate: data.startTime.substring(0, 10),
          startTime: this.generateTime(data.startTime),
          endDate: data.endTime.substring(0, 10),
          endTime: this.generateTime(data.endTime),
          shopType: shopTypeList,
        };
      }
    },
  },
})
export default class PromotionForm extends Vue {
  modalTitle = "Create";
  today = new Date();
  formData = {
    name: "",
    title: "",
    description: "",
    image: undefined,
    isPublic: true,
    order: 0,
    startDate: this.today.toISOString().substring(0, 10),
    startTime: this.generateTime(),
    endDate: this.today.toISOString().substring(0, 10),
    endTime: this.generateTime(),
    shopType: undefined,
  };
  initialForm = {
    name: "",
    title: "",
    description: "",
    image: undefined,
    isPublic: true,
    order: 0,
    startDate: this.today.toISOString().substring(0, 10),
    startTime: this.generateTime(),
    endDate: this.today.toISOString().substring(0, 10),
    endTime: this.generateTime(),
    shopType: undefined,
  };
  publicItems = [
    { text: "Yes", value: true },
    { text: "No", value: false },
  ];
  /* istanbul ignore next */
  rules = {
    required: (value: [number, string]) => !!value || "Required.",
    min: (value: number) => value > 0 || "Point must more than 0",
  };

  @Ref("form") readonly form!: VForm;

  createPromotion!: (formData: object) => void;
  editPromotion!: (formData: object) => void;
  toggleDialog!: () => void;

  updateDateTime(value: string, name: string) {
    const newFormData = {
      ...this.formData,
      [name]: value,
    };
    this.formData = newFormData;
  }

  generateTime(date?: string) {
    let today = new Date();
    if (date) today = new Date(date);
    const hour = today.getHours();
    const min = today.getMinutes();
    if (hour > 9) {
      if (min > 9) {
        return hour + ":" + min;
      } else {
        return hour + ":0" + min;
      }
    } else {
      if (min > 9) {
        return "0" + hour + ":" + min;
      } else {
        return "0" + hour + ":0" + min;
      }
    }
  }

  clearData() {
    if (this.$props.editId !== "") this.$props.setInitialDialog();
    this.toggleDialog();
    this.$nextTick(() => {
      this.modalTitle = "Create";
      this.formData = Object.assign({}, this.initialForm);
      /* istanbul ignore next */
      if (this.form) this.form.resetValidation();
    });
  }

  submitForm() {
    const { formData } = this;
    if (this.$props.editId !== "") {
      const submitForm = {
        name: get(formData, "name"),
        title: get(formData, "title"),
        description: get(formData, "description"),
        image: formData.image,
        isPublic: get(formData, "isPublic"),
        order: get(formData, "order"),
        startTime:
          get(formData, "startDate") + " " + get(formData, "startTime") + ":00",
        endTime:
          get(formData, "endDate") + " " + get(formData, "endTime") + ":00",
        shopType: get(formData, "shopType"),
        editId: this.$props.editId,
      };
      this.editPromotion(submitForm);
    } else {
      const submitForm = {
        name: get(formData, "name"),
        title: get(formData, "title"),
        description: get(formData, "description"),
        image: formData.image,
        isPublic: get(formData, "isPublic"),
        order: get(formData, "order"),
        startTime:
          get(formData, "startDate") + " " + get(formData, "startTime") + ":00",
        endTime:
          get(formData, "endDate") + " " + get(formData, "endTime") + ":00",
        shopType: get(formData, "shopType"),
      };
      this.createPromotion(submitForm);
    }
    this.clearData();
  }
}
