
import { Component, Vue } from "vue-property-decorator";
import { isEmpty } from "lodash";
import Utils from "@/mixin";
import { Auth } from "@/type";

@Component({
  mixins: [Utils],
  mounted() {
    const token = this.auth.token;
    /* istanbul ignore next */
    if (!isEmpty(token)) {
      this.$router.push({ name: "Home" });
    }
  },
})
export default class Login extends Vue {
  show = false;
  valid = false;
  email = "";
  password = "";
  /* istanbul ignore next */
  rules = {
    required: (value: [number, string]) => !!value || "Required.",
    min: (v: [number, string]) => v.length >= 8 || "Min 8 characters",
    emailValidate: (email: [string]) => {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
      if (re.test(String(email).toLowerCase())) {
        return true;
      }
      return "Invalid email format";
    },
  };
  login!: (formData: Auth) => void;

  submitForm() {
    const formData = {
      email: this.email,
      password: this.password,
    };
    this.login(formData);
  }
}
