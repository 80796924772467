
import { Component, Vue } from "vue-property-decorator";
import { forEach, findIndex, get, isEmpty, toLower } from "lodash";
import { Content, LuckyReward, Winner } from "@/type";
import Utils from "@/mixin";

import CreateWinner from "./LuckyDraw/Winner.vue";

@Component({
  components: {
    CreateWinner,
  },
  mixins: [Utils],
  computed: {
    /* istanbul ignore next */
    contentId() {
      if (!isEmpty(this.$route.params) && !isEmpty(this.luckyDraw)) {
        return get(this, "$route.params.luckyDrawId");
      } else {
        this.$router.push({ name: "LuckyDrawContent" });
      }
      return;
    },
    /* istanbul ignore next */
    rewardId() {
      if (!isEmpty(this.$route.params) && !isEmpty(this.luckyDraw)) {
        return get(this, "$route.params.rewardId");
      } else {
        this.$router.push({ name: "LuckyDrawContent" });
      }
      return;
    },
    /* istanbul ignore next */
    generateTable() {
      if (!isEmpty(this.luckyDraw)) {
        const { luckydraws } = this.luckyDraw;
        const tableList: Winner[] = [];
        const contentIndex = findIndex(
          luckydraws,
          (i: Content) => i.id === this.contentId
        );
        const { rewards } = luckydraws[contentIndex];
        const rewardIndex = findIndex(
          rewards,
          (i: LuckyReward) => i.id === this.rewardId
        );
        const { winners } = luckydraws[contentIndex]?.rewards[rewardIndex];
        forEach(winners, (i) => {
          const winner = new Winner();
          winner.displayName =
            toLower(i.taxType) === "corporation"
              ? i.taxCompanyName
              : i.taxFirstName + " " + i.taxLastName;
          winner.taxType = i.taxType;
          tableList.push(winner);
        });
        return tableList;
      } else {
        return [];
      }
    },
  },
  mounted() {
    const luckyDrawId = get(this, "$route.params.luckyDrawId");
    const rewardId = get(this, "$route.params.rewardId");
    this.breadcrumbItems.push(
      {
        text: "Rewards",
        disabled: false,
        href: "/lucky-draws/" + luckyDrawId,
      },
      {
        text: "Winners",
        disabled: true,
        href: "/lucky-draws/" + luckyDrawId + "/" + rewardId,
      }
    );
  },
})
export default class LuckyDrawContent extends Vue {
  page = 1;
  itemPerPage = 10;
  breadcrumbItems = [
    {
      text: "LuckDraws",
      disabled: false,
      href: "/lucky-draws",
    },
  ];
  headers = [
    {
      text: "Name",
      value: "displayName",
      align: "center",
      sortable: false,
    },
    {
      text: "Tax Type",
      value: "taxType",
      align: "center",
      sortable: false,
    },
  ];
}
