import { Module } from 'vuex'
import { forEach, findIndex, get } from 'lodash'

import { RootState, LuckyDrawState, AlertMessage, LuckyDraw, LuckyDrawParticipant, Winner, LuckyReward, Content, Participant } from '@/type'
import { REQUEST_GET, REQUEST_POST, REQUEST_PUT, REQUEST_DELETE } from './API_Request'
import { errorMessage } from '@/plugins/common'
import { GLOBAL_MESSAGE, LUCKY_DRAW_MESSAGE } from '@/plugins/message'

export const state = {
  luckyDraw: undefined,
  luckyDrawParticipant: undefined,
  isParticipantLoading: false
}

export const mutations = {
  async CREATE_CONTENT(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      // Generate Form
      const formData = new FormData()
      formData.append('title', get(data, 'formData.title'))
      formData.append('description', get(data, 'formData.description'))
      formData.append('startAt', get(data, 'formData.startAt'))
      formData.append('stopAt', get(data, 'formData.stopAt'))
      formData.append('announceAt', get(data, 'formData.announceAt'))
      formData.append('redeemPoint', get(data, 'formData.redeemPoint'))
      formData.append('type', get(data, 'formData.type'))
      formData.append('image', data.formData?.image)
      formData.append('imageBannerUrl', data.formData?.imageBannerUrl)

      const res = await REQUEST_POST('/lucky_draws', formData)
      // console.log(res);
      switch (get(res, 'status')) {
        case 200: {
          data.toggleLoading()
          const alert = new AlertMessage(LUCKY_DRAW_MESSAGE.CONTENT.CREATE.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          location.reload()
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
       /* istanbul ignore else */
      if (error) alert = new AlertMessage(LUCKY_DRAW_MESSAGE.CONTENT.CREATE.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  },
  async EDIT_CONTENT(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      // Generate Form
      const formData = new FormData()
      formData.append('title', get(data, 'formData.title'))
      formData.append('description', get(data, 'formData.description'))
      formData.append('startAt', get(data, 'formData.startAt'))
      formData.append('stopAt', get(data, 'formData.stopAt'))
      formData.append('announceAt', get(data, 'formData.announceAt'))
      formData.append('redeemPoint', get(data, 'formData.redeemPoint'))
      formData.append('type', get(data, 'formData.type'))
       /* istanbul ignore else */
      if (data.formData?.image !== undefined) formData.append('image', data.formData?.image)
       /* istanbul ignore else */
      if (data.formData?.imageBannerUrl !== undefined) formData.append('imageBannerUrl', data.formData?.imageBannerUrl)

      const res = await REQUEST_PUT('/lucky_draws/' + get(data, 'formData.editId'), formData)
      // console.log(res);
      switch (get(res, 'status')) {
        case 200: {
          data.toggleLoading()
          forEach(state.luckyDraw?.luckydraws, i => {
             /* istanbul ignore else */
            if (i.id === res.data?.id) {
              i.title = res.data?.title
              i.description = res.data?.description
              i.startAt = res.data?.startAt
              i.stopAt = res.data?.stopAt
              i.announceAt = res.data?.announceAt
              i.redeemPoint = res.data?.redeemPoint
              i.type = res.data?.type
              i.imageUrl = res.data?.imageUrl
              i.listBannerImageUrl = res.data?.listBannerImageUrl
            }
          })
          const alert = new AlertMessage(LUCKY_DRAW_MESSAGE.CONTENT.EDIT.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
       /* istanbul ignore else */
      if (error) alert = new AlertMessage(LUCKY_DRAW_MESSAGE.CONTENT.EDIT.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  },
  async CREATE_LUCKYDRAW_REWARD(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      // Generate Form
      const formData = new FormData()
      formData.append('title', get(data, 'formData.title'))
      formData.append('description', get(data, 'formData.description'))
      formData.append('quantity', get(data, 'formData.quantity'))
      formData.append('image', data.formData?.image)
      const luckyDrawId: number = data.formData?.luckyDrawId
      const index = findIndex(state?.luckyDraw?.luckydraws, (i: Content) => i.id === luckyDrawId)

      const res = await REQUEST_POST('/lucky_draws/' + luckyDrawId + '/rewards', formData)
      // console.log(res);
      switch (get(res, 'status')) {
        case 200: {
          state.luckyDraw?.luckydraws[index].rewards?.push(get(res, 'data'))
          data.toggleLoading()
          const alert = new AlertMessage(LUCKY_DRAW_MESSAGE.REWARD.CREATE.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
       /* istanbul ignore else */
      if (error) alert = new AlertMessage(LUCKY_DRAW_MESSAGE.REWARD.CREATE.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  },
  async EDIT_LUCKYDRAW_REWARD(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      // Generate Form
      const formData = new FormData()
      formData.append('title', get(data, 'formData.title'))
      formData.append('description', get(data, 'formData.description'))
      formData.append('quantity', get(data, 'formData.quantity'))
       /* istanbul ignore else */
      if (data.formData?.image !== undefined) formData.append('image', data.formData?.image)
      const luckyDrawId: number = data.formData?.luckyDrawId
      const contentIndex = findIndex(state?.luckyDraw?.luckydraws, (i: Content) => i.id === luckyDrawId)

      const res = await REQUEST_PUT('/lucky_draws/' + luckyDrawId + '/rewards/' + get(data, 'formData.editId'), formData)
      // console.log(res);
      switch (get(res, 'status')) {
        case 200: {
          const rewards = state.luckyDraw?.luckydraws[contentIndex].rewards
          forEach(rewards, i => {
             /* istanbul ignore else */
            if (i.id === res.data?.id) {
              i.title = res.data?.title
              i.description = res.data?.description
              i.quantity = res.data?.quantity
              i.imageUrl = res.data?.imageUrl
            }
          })
          data.toggleLoading()
          const alert = new AlertMessage(LUCKY_DRAW_MESSAGE.REWARD.EDIT.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
       /* istanbul ignore else */
      if (error) alert = new AlertMessage(LUCKY_DRAW_MESSAGE.REWARD.EDIT.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  },
  async GET_LUCKYDRAW(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      const res = await REQUEST_GET('/lucky_draws', data.searchForm)
      // console.log(res);
      state.luckyDraw = get(res, 'data')
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async REQUEST_ANNOUNCEMENT(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      const res = await REQUEST_POST('/lucky_draw/' + get(data, 'luckyDrawId') + '/announce', {})
      // console.log(res);
      switch (get(res, 'status')) {
        case 200: {
          data.toggleLoading()
          const alert = new AlertMessage(LUCKY_DRAW_MESSAGE.ANNOUNCE.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
       /* istanbul ignore else */
      if (error) alert = new AlertMessage(LUCKY_DRAW_MESSAGE.ANNOUNCE.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  },
  async REMOVE_LUCKY_DRAW(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      const luckyDrawId: number = get(data, 'luckyDrawId')

      const res = await REQUEST_DELETE('/lucky_draw/' + luckyDrawId)
      // console.log(res);
      switch (get(res, 'status')) {
        case 200: {
          const contentList: Array<Content> = []
          forEach(state.luckyDraw?.luckydraws, i => {
             /* istanbul ignore else */
            if (get(i, 'id') !== luckyDrawId) {
              contentList.push(i)
            }
          })
          const luckyDraw = new LuckyDraw()
          luckyDraw.total = parseInt(get(state, 'luckyDraw.total')) - 1
          luckyDraw.perPage = parseInt(get(state, 'luckyDraw.perPage'))
          luckyDraw.lastPage = parseInt(get(state, 'luckyDraw.lastPage'))
          luckyDraw.currentPage = parseInt(get(state, 'luckyDraw.currentPage'))
          luckyDraw.fistPageUrl = get(state, 'luckyDraw.fistPageUrl')
          luckyDraw.lastPageUrl = get(state, 'luckyDraw.lastPageUrl')
          luckyDraw.nextPageUrl = get(state, 'luckyDraw.nextPageUrl')
          luckyDraw.prevPageUrl = get(state, 'luckyDraw.prevPageUrl')
          luckyDraw.from = parseInt(get(state, 'luckyDraw.from'))
          luckyDraw.to = parseInt(get(state, 'luckyDraw.to'))
          luckyDraw.luckydraws = contentList

          state.luckyDraw = luckyDraw
          data.toggleLoading()
          const alert = new AlertMessage(LUCKY_DRAW_MESSAGE.DELETE.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
       /* istanbul ignore else */
      if (error) alert = new AlertMessage(LUCKY_DRAW_MESSAGE.DELETE.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  },
  async REMOVE_LUCKY_DRAW_REWARD(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      const luckyDrawId: number = data.formData?.luckyDrawId
      const index = findIndex(state?.luckyDraw?.luckydraws, (i: Content) => i.id === luckyDrawId)

      const res = await REQUEST_DELETE('/lucky_draw/reward/' + get(data, 'formData.deleteId'))
      // console.log(res);
      switch (get(res, 'status')) {
        case 200: {
          const rewardList = state.luckyDraw?.luckydraws[index].rewards
          const arrayList: Array<LuckyReward> = []
          const deleteIndex = findIndex(rewardList, { id: get(data, 'formData.deleteId') })
          forEach(rewardList, (i, index) => {
             /* istanbul ignore else */
            if (parseInt(index) !== deleteIndex) {
              arrayList.push(i)
            }
          })
          forEach(state.luckyDraw?.luckydraws, i => {
             /* istanbul ignore else */
            if (get(i, 'id') === luckyDrawId) {
              i.rewards = arrayList
            }
          })

          data.toggleLoading()
          const alert = new AlertMessage(LUCKY_DRAW_MESSAGE.DELETE.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
       /* istanbul ignore else */
      if (error) alert = new AlertMessage(LUCKY_DRAW_MESSAGE.DELETE.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  },
  async GET_PARTICIPANT(state: any, data: any) { // eslint-disable-line
    data.toggleParticipantLoading()
    try {
      const searchForm = {
        page: data.searchForm?.page,
        search: data.searchForm?.search
      }
      const res = await REQUEST_GET('/lucky_draw/' + data.searchForm?.luckyDrawId + '/participants', searchForm)
      // console.log(res);
      state.luckyDrawParticipant = get(res, 'data')
      data.toggleParticipantLoading()
      return state
    } catch (e) {
      data.toggleParticipantLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async CREATE_WINNER(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      const formData = {
        luckyDrawRewardId: data.formData?.luckyDrawRewardId,
        luckyDrawTransactionId: data.formData?.luckyDrawTransactionId
      }
      const luckyDrawId: number = data.formData?.luckyDrawId
      const rewardId: number = data.formData?.luckyDrawRewardId
      const contentIndex = findIndex(state?.luckyDraw?.luckydraws, (i: Content) => i.id === luckyDrawId)
      const rewardIndex = findIndex(state?.luckyDraw?.luckydraws[contentIndex].rewards, (i: LuckyReward) => i.id === rewardId)

      const res = await REQUEST_POST('/lucky_draw_winners', formData)
      // console.log(res);
      switch (get(res, 'status')) {
        case 200: {
          const luckydraws = get(state, 'luckyDraw.luckydraws')[contentIndex]
          const rewards = get(luckydraws, 'rewards')[rewardIndex]
          const participants = get(state, 'luckyDrawParticipant.participants')
          const transactionIndex = findIndex(state.luckyDrawParticipant?.participants, (i: Participant) => i.TransactionID === data.formData?.luckyDrawTransactionId)
          const winner = new Winner()
          winner.taxFirstName = participants[transactionIndex].TaxFirstName
          winner.taxLastName = participants[transactionIndex].TaxLastName
          winner.taxType = participants[transactionIndex].TaxType
          rewards.winners?.push(winner)
          data.toggleLoading()
          const alert = new AlertMessage(LUCKY_DRAW_MESSAGE.WINNER.CREATE.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
       /* istanbul ignore else */
      if (error) alert = new AlertMessage(LUCKY_DRAW_MESSAGE.REWARD.CREATE.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  },
  TOGGLE_PARTICIPANT_LOADING(state: any) { // eslint-disable-line
    state.isParticipantLoading = !state.isParticipantLoading
    return state
  },
}

export const actions = {
  createContent(context: any, formData: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm: AlertMessage) => context.dispatch('toggleAlertMessage', alertForm)
    context.commit('CREATE_CONTENT', { toggleLoading, formData, toggleAlert })
  },
  editContent(context: any, formData: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm: AlertMessage) => context.dispatch('toggleAlertMessage', alertForm)
    context.commit('EDIT_CONTENT', { toggleLoading, formData, toggleAlert })
  },
  createLuckyDrawReward(context: any, formData: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm: AlertMessage) => context.dispatch('toggleAlertMessage', alertForm)
    context.commit('CREATE_LUCKYDRAW_REWARD', { toggleLoading, formData, toggleAlert })
  },
  editLuckyDrawReward(context: any, formData: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm: AlertMessage) => context.dispatch('toggleAlertMessage', alertForm)
    context.commit('EDIT_LUCKYDRAW_REWARD', { toggleLoading, formData, toggleAlert })
  },
  createWinner(context: any, formData: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm: AlertMessage) => context.dispatch('toggleAlertMessage', alertForm)
    context.commit('CREATE_WINNER', { toggleLoading, formData, toggleAlert })
  },
  getLuckyDraw(context: any, searchForm: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    context.commit('GET_LUCKYDRAW', { toggleLoading, searchForm })
  },
  requestAnnouncement(context: any, luckyDrawId: number) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm: AlertMessage) => context.dispatch('toggleAlertMessage', alertForm)
    context.commit('REQUEST_ANNOUNCEMENT', { toggleLoading, luckyDrawId, toggleAlert })
  },
  removeLuckyDraw(context: any, luckyDrawId: number) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm: AlertMessage) => context.dispatch('toggleAlertMessage', alertForm)
    context.commit('REMOVE_LUCKY_DRAW', { toggleLoading, luckyDrawId, toggleAlert })
  },
  removeLuckyDrawReward(context: any, formData: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm: AlertMessage) => context.dispatch('toggleAlertMessage', alertForm)
    context.commit('REMOVE_LUCKY_DRAW_REWARD', { toggleLoading, formData, toggleAlert })
  },
  getParticipant(context: any, searchForm: object) { // eslint-disable-line
    const toggleParticipantLoading = () => context.dispatch('toggleParticipantLoading')
    context.commit('GET_PARTICIPANT', { toggleParticipantLoading, searchForm })
  },
  toggleParticipantLoading(context: any) { // eslint-disable-line
    context.commit('TOGGLE_PARTICIPANT_LOADING')
  },
}

export const getters = {
  // Add Logic Before Computed
  luckyDraw(state: any): LuckyDraw | undefined { // eslint-disable-line
    return state.luckyDraw
  },
  luckyDrawParticipant(state: any): LuckyDrawParticipant | undefined { // eslint-disable-line
    return state.luckyDrawParticipant
  },
  isParticipantLoading(state: any): boolean { // eslint-disable-line
    return state.isParticipantLoading
  },
}

export const luckyDraw: Module<LuckyDrawState, RootState> = {
  state,
  mutations,
  actions,
  getters
}