
import Vue from 'vue'
import { isEmpty } from 'lodash'
import Utils from "@/mixin";

import Alert from "@/components/Alert.vue";

export default Vue.extend({
  components: {
    Alert,
  },
  computed: {
    snackbar() {
      if (!isEmpty(this.alert?.message)) return true;
      return false;
    },
    alertText() {
      if (!isEmpty(this.alert?.message)) return this.alert?.message;
      return "";
    },
    alertType() {
      if (!isEmpty(this.alert?.message)) return this.alert?.type;
      return "";
    },
  },
  watch: {
    snackbar(newValue, oldValue) {
      if (oldValue === false && newValue === true) {
        setTimeout(() => {
          this.alert.message = "";
        }, 3000);
      }
    },
  },
  mixins: [Utils],
})
