
import { Component, Vue } from "vue-property-decorator";
import Utils from "@/mixin";
import { itemsPerPageOptions } from "@/plugins/common";

import CreateBrand from "./Brand/Form.vue";
import LightBox from "@/components/LightBox.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";

@Component({
  components: {
    CreateBrand,
    LightBox,
    ConfirmDialog,
  },
  mixins: [Utils],
  mounted() {
    this.getInitialData();
    this.itemsPerPageOptions = itemsPerPageOptions;
  },
})
export default class Brand extends Vue {
  deleteId = -1;
  confirmDialog = false;
  item = {};
  itemsPerPageOptions = [];
  keyword = "";
  dialog = false;
  headers = [
    { text: "Name", value: "name", align: "start", sortable: false },
    { text: "Order", value: "order", align: "end", sortable: false },
    { text: "Image", value: "image", align: "center", sortable: false },
    { text: "Actions", value: "actions", sortable: false, width: "100px" },
  ];
  imgArr: Array<string> = [];
  imgShow = false;

  getBrand!: (searchForm?: object) => void;
  removeFamilyBrand!: (paramId: number) => void;

  getInitialData() {
    this.getBrand({});
  }
  // eslint-disable-next-line
  toggleDialog(data: any = {}) {
    if (data?.id) {
      this.item = { ...data };
    }
    this.dialog = !this.dialog;
  }

  toggleConfirmModal(paramId: number) {
    this.deleteId = paramId;
    this.confirmDialog = !this.confirmDialog;
  }

  editItem(data: object = {}) {
    this.toggleDialog(data);
  }

  // eslint-disable-next-line
  showImg(image: string = "") {
    this.imgShow = true;
    this.imgArr = [image];
  }

  cancel() {
    this.deleteId = -1;
    this.confirmDialog = !this.confirmDialog;
  }

  deleteItem() {
    const { deleteId } = this;
    this.removeFamilyBrand(deleteId);
    this.cancel();
  }
}
