export class Promotion {
  id?: string | number
  name?: string
  title?: string
  image?: File
  description?: string
  startTime?: string
  endTime?: string
  order?: number
  isPublic?: boolean
  shopType?: string

  constructor() {
    this.name = ''
    this.title = ''
    this.image = new File([], '')
    this.description = ''
    this.startTime = ''
    this.endTime = ''
    this.order = 0
    this.isPublic = true
  }
}

export class PromotionShopType {
  id?: number
  type?: string

  constructor() {
    this.id = 0
  }
}

export type PromotionList = Promotion[]

export type PromotionShopTypeList = PromotionShopType[]