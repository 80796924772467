
import { Component, Vue } from "vue-property-decorator";
import { findIndex, forEach } from "lodash";
import Utils from "@/mixin";
import { itemsPerPageOptions } from "@/plugins/common";

import CreateReward from "./Reward/Form.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import LightBox from "@/components/LightBox.vue";
import { RewardCategory } from "@/type";

@Component({
  components: {
    CreateReward,
    ConfirmDialog,
    LightBox,
  },
  mixins: [Utils],
  mounted() {
    // console.log(this);
    this.getInitialData();
    this.itemsPerPageOptions = itemsPerPageOptions;
  },
  watch: {
    // keyword(nextProps, prevProps) {
    //   /* istanbul ignore else */
    //   if (nextProps !== prevProps) {
    //     this.getInitialData();
    //   }
    // },
    itemPerPage(nextProps, prevProps) {
      /* istanbul ignore else */
      if (nextProps !== prevProps) {
        this.getInitialData();
      }
    },
    page(nextProps, prevProps) {
      /* istanbul ignore else */
      if (nextProps !== prevProps) {
        this.getInitialData();
      }
    },
    /* istanbul ignore next */
    rewardList(nextProps, prevProps) {
      if (nextProps !== prevProps) {
        forEach(this.rewardList, (i) => {
          this.total = i.total;
          // this.itemPerPage = i.perPage;
        });
      }
    },
  },
})
export default class Reward extends Vue {
  editId = "";
  deleteId = -1;
  confirmDialog = false;
  page = 1;
  total = 0;
  itemsPerPageOptions = [];
  itemPerPage = 10;
  keyword = "";
  dialog = false;
  headers = [
    { text: "Title", value: "title", align: "start", sortable: false },
    {
      text: "Detail",
      value: "detail",
      align: "start",
      width: "400px",
      sortable: false,
    },
    {
      text: "Redeem Point",
      value: "redeemPoint",
      align: "center",
      width: "140px",
      sortable: false,
    },
    {
      text: "Poster Image",
      value: "posterUrl",
      align: "center",
      width: "100px",
      sortable: false,
    },
    {
      text: "Category",
      value: "categoryId",
      align: "center",
      width: "150px",
      sortable: false,
    },
    {
      text: "Redeem Able",
      value: "redeemAble",
      align: "center",
      sortable: false,
    },
    {
      text: "Limited Quantity",
      value: "limitedQty",
      align: "center",
      sortable: false,
    },
    { text: "Actions", value: "actions", sortable: false, width: "100px" },
  ];
  rewardCategoryList!: Array<RewardCategory>;
  imgArr: Array<string> = [];
  imgShow = false;

  getReward!: (searchForm?: object) => void;
  removeReward!: (paramId: number) => void;
  // eslint-disable-next-line
  onKeyDown(e: any) {
    const { keyCode } = e;
    if (keyCode === 13) {
      this.getInitialData();
    }
  }
  // eslint-disable-next-line
  showImg(image: any) {
    this.imgShow = true;
    if (typeof image === "string") {
      this.imgArr = [image];
    } else {
      this.imgArr = image;
    }
  }

  getInitialData() {
    const { page, itemPerPage, keyword } = this;
    const searchForm = {
      page,
      perPage: itemPerPage,
      keyword,
      type: "shop",
    };
    this.getReward(searchForm);
  }

  categoryMapping(paramId: number) {
    const { rewardCategoryList } = this;
    const index = findIndex(
      rewardCategoryList,
      (i: RewardCategory) => i.id === paramId
    );
    return rewardCategoryList[index]?.title || "";
  }

  setInitialDialog() {
    this.editId = "";
  }

  toggleDialog() {
    this.dialog = !this.dialog;
  }

  toggleConfirmModal(paramId: number) {
    this.deleteId = paramId;
    this.confirmDialog = !this.confirmDialog;
  }

  editItem(paramId: string) {
    this.editId = paramId;
    this.toggleDialog();
  }

  cancel() {
    this.deleteId = -1;
    this.confirmDialog = !this.confirmDialog;
  }

  actionItem() {
    const { deleteId } = this;
    this.removeReward(deleteId);
    this.cancel();
  }
}
