import { get } from 'lodash'

import { Error } from '@/type'

export const errorMessage = (e: object) => {
  const errorMessage = new Error(e)
  // console.log(errorMessage);
  switch (get(errorMessage, 'response.status')) {
    case 400: {
      const message = get(errorMessage, 'response.data')
      return message
    }
    case 401: {
      const message = get(errorMessage, 'response.data.message') || get(errorMessage, 'response.data')
      if (message === 'invalid or expired jwt') {
        localStorage.removeItem('token')
        location.reload()
        return message
      } else {
        return message
      }
    }
    case 405: {
      const message = get(errorMessage, 'response.data.message') || get(errorMessage, 'response.data')
      return message
    }
    case 422: {
      const message = get(errorMessage, 'response.data.message') || get(errorMessage, 'response.data')
      return message
    }
    default: break
  }
  return
}

export const itemsPerPageOptions = [5, 10, 15, 25, 50, 100, -1]