import { Module } from 'vuex'
import { forEach, get, size } from 'lodash'
import moment from "moment";
import fileDownload from "js-file-download";

import { RootState, HistoryState, History } from '@/type'
import { REQUEST_GET, REQUEST_DOWNLOAD } from './API_Request'
import { HistoryList } from '@/type/History'
import { errorMessage } from '@/plugins/common'
// import { GLOBAL_MESSAGE, USER_MEESAGE } from '@/plugins/message'

export const state = {
  historyList: Array<History>(),
}

export const mutations = {
  async GET_HISTORY(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    const searchForm = {
      page: data.searchForm.page,
      perPage: data.searchForm.itemPerPage,
      keyword: data.searchForm.keyword,
      'shop_type': data.searchForm.shopType,
      'start_date': data.searchForm.startDate,
      'stop_date': data.searchForm.stopDate,
    }
    const userType = data.searchForm.type
    const mode = data.searchForm.mode
    /* istanbul ignore next */
    try {
      const res = await REQUEST_GET(`/redemptions/userType/${userType}/${mode}`, searchForm)
      // console.log(res);
      const historyList = get(res, 'data.data')
      if (size(historyList) > 0) {
        const arrayList: Array<History> = []
        forEach(historyList, i => {
          arrayList.push({ ...i, total: get(res, 'data.total', historyList.length), perPage: get(res, 'data.perPage') })
        })
        state.historyList = arrayList
      } else {
        state.historyList = []
      }
      // console.log(state);
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async EXPORT_HISTORY(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    const userType = data.formData.type
    const mode = data.formData.mode
    const paramId = data.formData.id
    /* istanbul ignore next */
    try {
      // eslint-disable-next-line
      const res: any = await REQUEST_DOWNLOAD(`/redemptions/userType/${userType}/${mode}/export/${paramId}`)
      const { status } = res;
      let filename = "Redemption.pdf";
      if (status === 200) {
        data.toggleLoading()
        filename = `Redemption_${paramId}_${moment().format("YYMMDD_HHmmss")}.pdf`;
        fileDownload(res?.data, filename);
      }
      return res
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async EXPORT_ALL_HISTORY(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    const formData = {
      'keyword': data.formData.keyword,
      'shop_type': data.formData.shopType,
      'start_date': data.formData.startDate,
      'stop_date': data.formData.stopDate,
    }
    const userType = data.formData.type
    const mode = data.formData.mode
    /* istanbul ignore next */
    try {
      // eslint-disable-next-line
      const res: any = await REQUEST_DOWNLOAD(`/redemptions/userType/${userType}/${mode}/export`, formData)
      const { status } = res;
      let filename = "Redemption.pdf";
      if (status === 200) {
        data.toggleLoading()
        filename = `Redemption_${moment().format("YYMMDD_HHmmss")}.pdf`;
        fileDownload(res?.data, filename);
      }
      return res
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
}

export const actions = {
  getHistory(context: any, searchForm: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    context.commit('GET_HISTORY', { toggleLoading, searchForm })
  },
  exportHistory(context: any, formData: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    context.commit('EXPORT_HISTORY', { toggleLoading, formData })
  },
  exportAllHistory(context: any, formData: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    context.commit('EXPORT_ALL_HISTORY', { toggleLoading, formData })
  },
}

export const getters = {
  // Add Logic Before Computed
  historyList(state: any): HistoryList | undefined { // eslint-disable-line
    return state.historyList
  },
}

export const histories: Module<HistoryState, RootState> = {
  state,
  mutations,
  actions,
  getters
}