
import { Component, Vue } from "vue-property-decorator";
import { forEach, isEmpty, size } from "lodash";
import { Content, LuckyDraw } from "@/type";
import Utils from "@/mixin";

import CreateContent from "./LuckyDraw/Content.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import LightBox from "@/components/LightBox.vue";
import { itemsPerPageOptions } from "@/plugins/common";

@Component({
  components: {
    ConfirmDialog,
    CreateContent,
    LightBox,
  },
  mixins: [Utils],
  computed: {
    generateTable() {
      if (this.luckyDraw !== undefined) {
        const { luckydraws, total, perPage } = this.luckyDraw;
        const tableList: Content[] = [];
        this.itemPerPage = perPage;
        this.total = total;
        forEach(luckydraws, (i) => {
          const content = new Content();
          content.id = i.id;
          content.title = i.title;
          content.description = i.description;
          content.startAt = this.$generateDateTime(i.startAt);
          content.stopAt = this.$generateDateTime(i.stopAt);
          content.announceAt = this.$generateDateTime(i.announceAt);
          content.type = i.type;
          content.redeemPoint = i.redeemPoint;
          content.imageUrl = i.imageUrl;
          content.listBannerImageUrl = i.listBannerImageUrl;
          content.isActive = i.isActive;
          content.rewards = i.rewards;
          tableList.push(content);
        });
        return tableList;
      } else {
        return [];
      }
    },
  },
  mounted() {
    this.getInitialData();
    this.itemsPerPageOptions = itemsPerPageOptions;
  },
  watch: {
    // keyword(nextProps, prevProps) {
    //   /* istanbul ignore else */
    //   if (nextProps !== prevProps) {
    //     this.getInitialData();
    //   }
    // },
    itemPerPage(nextProps, prevProps) {
      /* istanbul ignore else */
      if (nextProps !== prevProps) {
        this.getInitialData();
      }
    },
    page(nextProps, prevProps) {
      /* istanbul ignore else */
      if (nextProps !== prevProps) {
        this.getInitialData();
      }
    },
  },
})
export default class LuckyDrawContent extends Vue {
  editId = "";
  announceId = -1;
  deleteId = -1;
  dialog = false;
  confirmDialog = false;
  page = 1;
  total = 0;
  itemsPerPageOptions = [];
  itemPerPage = 10;
  keyword = "";
  headers = [
    { text: "Title", value: "title", align: "center", sortable: false },
    {
      text: "Redeem Point",
      value: "redeemPoint",
      align: "center",
      width: "130px",
      sortable: false,
    },
    {
      text: "Type",
      value: "type",
      align: "center",
      width: "80px",
      sortable: false,
    },
    { text: "Start Date", value: "startAt", align: "center", sortable: false },
    { text: "Stop Date", value: "stopAt", align: "center", sortable: false },
    {
      text: "Announce Date",
      value: "announceAt",
      align: "center",
      sortable: false,
    },
    { text: "Status", value: "isActive", align: "center", sortable: false },
    { text: "Image", value: "imageUrl", align: "center", sortable: false },
    {
      text: "Banner Image",
      value: "listBannerImageUrl",
      align: "center",
      sortable: false,
    },
    { text: "Actions", value: "actions", width: "120px", sortable: false },
  ];
  imgArr: Array<string> = [];
  imgShow = false;

  getLuckyDraw!: (searchForm?: object) => void;
  requestAnnouncement!: (paramId?: number) => void;
  removeLuckyDraw!: (paramId?: number) => void;

  luckyDraw!: LuckyDraw;
  // eslint-disable-next-line
  onKeyDown(e: any) {
    const { keyCode } = e;
    if (keyCode === 13) {
      this.getInitialData();
    }
  }
  // eslint-disable-next-line
  showImg(image: any) {
    this.imgShow = true;
    if (typeof image === "string") {
      this.imgArr = [image];
    } else {
      this.imgArr = image;
    }
  }

  getInitialData() {
    const { page, itemPerPage, keyword } = this;
    const searchForm = {
      page,
      perPage: itemPerPage,
      keyword,
    };
    this.getLuckyDraw(searchForm);
  }

  /* istanbul ignore next */
  validateAnnouncement(id: number) {
    const { luckydraws } = this.luckyDraw;
    let flag = true;
    forEach(luckydraws, (i) => {
      if (i.id === id && i.isActive === false) {
        if (size(i.rewards) > 0) {
          forEach(i.rewards, (j) => {
            if (!isEmpty(j.winners)) {
              flag = false;
            }
          });
        }
      }
    });
    return flag;
  }

  toggleDialog() {
    this.dialog = !this.dialog;
  }

  setInitialDialog() {
    this.editId = "";
  }

  selectItem(luckyDrawId: string) {
    this.$router.push({ name: "LuckyDrawReward", params: { luckyDrawId } });
  }

  editItem(paramId: string) {
    this.editId = paramId;
    this.toggleDialog();
  }

  toggleConfirmModal(paramId: number, action: string) {
    switch (action) {
      case "announcement": {
        this.announceId = paramId;
        this.confirmDialog = !this.confirmDialog;
        break;
      }
      case "delete": {
        this.deleteId = paramId;
        this.confirmDialog = !this.confirmDialog;
        break;
      }
      default:
        break;
    }
  }

  cancel() {
    this.announceId = -1;
    this.deleteId = -1;
    this.confirmDialog = !this.confirmDialog;
  }

  actionItem() {
    const { announceId, deleteId } = this;
    if (announceId !== -1) this.requestAnnouncement(announceId);
    else if (deleteId !== -1) this.removeLuckyDraw(deleteId);
    this.cancel();
  }
}
