import { Module } from 'vuex'
import { cloneDeep, get, findIndex } from 'lodash'
import moment from 'moment'

import { RootState, BrandState, AlertMessage, Brand } from '@/type'
import { REQUEST_GET, REQUEST_DELETE, REQUEST_POST, REQUEST_PUT } from './API_Request'
import router from '@/router'
import { BrandList } from '@/type/Brand'
import { errorMessage } from '@/plugins/common'
import { GLOBAL_MESSAGE } from '@/plugins/message'

export const state = {
  brandList: Array<Brand>()
}

export const mutations = {
  async GET_BRAND(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      const res = await REQUEST_GET('/brands', data.searchForm)
      state.brandList = res?.data?.data || []
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async GET_BRAND_CAMP(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      const res = await REQUEST_GET('/employee/promotions/brands', data.searchForm)
      state.brandList = res.data || []
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async CREATE_FAMILY_BRAND(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      const formData = new FormData()
      formData.append('name', get(data, 'formData.name'))
      formData.append('image', data.formData?.image)
      formData.append('order', get(data, 'formData.order'))

      const res = await REQUEST_POST('/brands', formData)
      switch (get(res, 'status')) {
        case 201: {
          state.brandList?.push(get(res, 'data'))
          data.toggleLoading()
          const alert = new AlertMessage(GLOBAL_MESSAGE.CREATE.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
      /* istanbul ignore else */
      if (error) alert = new AlertMessage(GLOBAL_MESSAGE.CREATE.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  },
  async CREATE_BRAND_CAMP(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      const formData = new FormData()
      formData.append('name', get(data, 'formData.name'))
      formData.append('image', data.formData?.image)
      formData.append('order', get(data, 'formData.order'))

      const res = await REQUEST_POST('/employee/promotions/brand', formData)
      switch (get(res, 'status')) {
        case 201: {
          state.brandList?.push(get(res, 'data'))
          data.toggleLoading()
          const alert = new AlertMessage(GLOBAL_MESSAGE.CREATE.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
      /* istanbul ignore else */
      if (error) alert = new AlertMessage(GLOBAL_MESSAGE.CREATE.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  },
  async EDIT_FAMILY_BRAND(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      const formData = new FormData()
      formData.append('name', get(data, 'formData.name'))
      formData.append('order', get(data, 'formData.order'))
      if (data?.formData?.image) {
        formData.append('image', data?.formData?.image)
      }

      const res = await REQUEST_PUT(`/brands/${data?.formData?.id}`, formData)
      switch (get(res, 'status')) {
        case 201: {
          const item = get(res, 'data')
          const brandList = cloneDeep(state.brandList)
          const index = findIndex(brandList, { id: item?.id })

          brandList[index] = item
          state.brandList = brandList

          data.toggleLoading()
          const alert = new AlertMessage(GLOBAL_MESSAGE.EDIT.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
      /* istanbul ignore else */
      if (error) alert = new AlertMessage(GLOBAL_MESSAGE.CREATE.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  },
  async EDIT_BRAND_CAMP(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      const formData = new FormData()
      formData.append('name', get(data, 'formData.name'))
      formData.append('order', get(data, 'formData.order'))
      if (data?.formData?.image) {
        formData.append('image', data?.formData?.image)
      }

      const res = await REQUEST_PUT(`/employee/promotions/brand/${data?.formData?.id}`, formData)
      switch (get(res, 'status')) {
        case 201: {
          const item = get(res, 'data')
          const brandList = cloneDeep(state.brandList)
          const index = findIndex(brandList, { id: item?.id })

          brandList[index] = item
          state.brandList = brandList

          data.toggleLoading()
          const alert = new AlertMessage(GLOBAL_MESSAGE.EDIT.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
      /* istanbul ignore else */
      if (error) alert = new AlertMessage(GLOBAL_MESSAGE.CREATE.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  },
  async REMOVE_FAMILY_BRAND(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      /* istanbul ignore next */
      if (get(data, 'paramId') === undefined) router.go(0)
      const res = await REQUEST_DELETE(`/brands/${data.paramId}`)
      switch (get(res, 'status')) {
        case 204: {
          const brandList = cloneDeep(state.brandList)
          const index = findIndex(brandList, { id: data.paramId })
          brandList.splice(index, 1)
          state.brandList = brandList

          data.toggleLoading()
          const alert = new AlertMessage(GLOBAL_MESSAGE.DELETE.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
      /* istanbul ignore else */
      if (error) alert = new AlertMessage(GLOBAL_MESSAGE.DELETE.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  },
  async REMOVE_BRAND_CAMP(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      /* istanbul ignore next */
      if (get(data, 'paramId') === undefined) router.go(0)
      const res = await REQUEST_DELETE(`/employee/promotions/brand/${data.paramId}`)
      switch (get(res, 'status')) {
        case 204: {
          const brandList = cloneDeep(state.brandList)
          const index = findIndex(brandList, { id: data.paramId })
          brandList.splice(index, 1)
          state.brandList = brandList

          data.toggleLoading()
          const alert = new AlertMessage(GLOBAL_MESSAGE.DELETE.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
      /* istanbul ignore else */
      if (error) alert = new AlertMessage(GLOBAL_MESSAGE.DELETE.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  },
  async CREATE_PROMOTION_CAMP(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      let formData = new FormData()
      const brand = data?.formData?.brand
      const images = data.formData?.images
      const start = moment(get(data, 'formData.startTime'), "YYYY-MM-DD").startOf("day").format("YYYY-MM-DD HH:mm:ss")
      const end = moment(get(data, 'formData.endTime'), "YYYY-MM-DD").endOf("day").format("YYYY-MM-DD HH:mm:ss")

      formData.append('name', get(data, 'formData.name'))
      formData.append('title', get(data, 'formData.title'))
      formData.append('description', get(data, 'formData.description'))
      formData.append('startTime', start)
      formData.append('endTime', end)
      formData.append('order', get(data, 'formData.order'))
      formData.append('banner', data.formData?.banner)
      if (images && Array.isArray(images)) {
        formData = images.reduce((o, e) => {
          o.append('images', e)
          return o
        }, formData)
      }

      const res = await REQUEST_POST(`/employee/promotions/brand/${brand?.value}`, formData)
      switch (get(res, 'status')) {
        case 201: {
          state.brandList?.[brand?.index]?.promotions?.push(get(res, 'data'))
          data.toggleLoading()
          const alert = new AlertMessage(GLOBAL_MESSAGE.CREATE.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
      /* istanbul ignore else */
      if (error) alert = new AlertMessage(GLOBAL_MESSAGE.CREATE.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  },
  async EDIT_PROMOTION_CAMP(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      const formData = new FormData()
      const brand = data?.formData?.brand
      const start = moment(get(data, 'formData.startTime'), "YYYY-MM-DD").startOf("day").format("YYYY-MM-DD HH:mm:ss")
      const end = moment(get(data, 'formData.endTime'), "YYYY-MM-DD").endOf("day").format("YYYY-MM-DD HH:mm:ss")

      formData.append('name', get(data, 'formData.name'))
      formData.append('title', get(data, 'formData.title'))
      formData.append('description', get(data, 'formData.description'))
      formData.append('startTime', start)
      formData.append('endTime', end)
      formData.append('order', get(data, 'formData.order'))
      /* istanbul ignore next */
      if (data.formData?.banner) {
        formData.append('banner', data.formData?.banner)
      }

      const res = await REQUEST_PUT(`/employee/promotions/${data?.formData?.id}`, formData)
      switch (get(res, 'status')) {
        case 200: {
          const item = get(data, 'formData')
          const brandList = cloneDeep(state.brandList)
          const promotionList = brandList?.[brand?.index]?.promotions
          const index = findIndex(promotionList, { id: item?.id })

          promotionList[index] = {
            ...item,
            brandID: brand?.value,
            banner: item?.banner && URL.createObjectURL(item?.banner) || promotionList?.[index]?.banner,
            images: promotionList?.[index]?.images,
            isPublic: false,
          }
          delete promotionList[index].brand
          state.brandList = brandList

          data.toggleLoading()
          const alert = new AlertMessage(GLOBAL_MESSAGE.CREATE.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
      /* istanbul ignore else */
      if (error) alert = new AlertMessage(GLOBAL_MESSAGE.CREATE.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  },
  async REMOVE_PROMOTION_CAMP(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      const form = get(data, 'formData')
      /* istanbul ignore next */
      if (!form?.id) router.go(0)
      const brand = data?.formData?.brand

      const res = await REQUEST_DELETE(`/employee/promotions/${form?.id}`)
      switch (get(res, 'status')) {
        case 204: {
          const brandList = cloneDeep(state.brandList)
          const promotionList = brandList?.[brand?.index]?.promotions
          const index = findIndex(promotionList, { id: form?.id })

          promotionList.splice(index, 1)
          state.brandList = brandList

          data.toggleLoading()
          const alert = new AlertMessage(GLOBAL_MESSAGE.DELETE.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
      /* istanbul ignore else */
      if (error) alert = new AlertMessage(GLOBAL_MESSAGE.DELETE.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  }
}

export const actions = {
  getBrand(context: any, searchForm: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    context.commit('GET_BRAND', { toggleLoading, searchForm })
  },
  getBrandCamp(context: any, searchForm: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    context.commit('GET_BRAND_CAMP', { toggleLoading, searchForm })
  },
  createFamilyBrand(context: any, formData: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm: AlertMessage) => context.dispatch('toggleAlertMessage', alertForm)
    context.commit('CREATE_FAMILY_BRAND', { toggleLoading, formData, toggleAlert })
  },
  createBrandCamp(context: any, formData: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm: AlertMessage) => context.dispatch('toggleAlertMessage', alertForm)
    context.commit('CREATE_BRAND_CAMP', { toggleLoading, formData, toggleAlert })
  },
  editFamilyBrand(context: any, formData: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm: AlertMessage) => context.dispatch('toggleAlertMessage', alertForm)
    context.commit('EDIT_FAMILY_BRAND', { toggleLoading, formData, toggleAlert })
  },
  editBrandCamp(context: any, formData: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm: AlertMessage) => context.dispatch('toggleAlertMessage', alertForm)
    context.commit('EDIT_BRAND_CAMP', { toggleLoading, formData, toggleAlert })
  },
  removeFamilyBrand(context: any, paramId: number) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm: AlertMessage) => context.dispatch('toggleAlertMessage', alertForm)
    context.commit('REMOVE_FAMILY_BRAND', { toggleLoading, paramId, toggleAlert })
  },
  removeBrandCamp(context: any, paramId: number) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm: AlertMessage) => context.dispatch('toggleAlertMessage', alertForm)
    context.commit('REMOVE_BRAND_CAMP', { toggleLoading, paramId, toggleAlert })
  },
  createPromotionCamp(context: any, formData: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm: AlertMessage) => context.dispatch('toggleAlertMessage', alertForm)
    context.commit('CREATE_PROMOTION_CAMP', { toggleLoading, formData, toggleAlert })
  },
  editPromotionCamp(context: any, formData: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm: AlertMessage) => context.dispatch('toggleAlertMessage', alertForm)
    context.commit('EDIT_PROMOTION_CAMP', { toggleLoading, formData, toggleAlert })
  },
  removePromotionCamp(context: any, formData: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm: AlertMessage) => context.dispatch('toggleAlertMessage', alertForm)
    context.commit('REMOVE_PROMOTION_CAMP', { toggleLoading, formData, toggleAlert })
  }
}

export const getters = {
  // Add Logic Before Computed
  brandList(state: any): BrandList | undefined { // eslint-disable-line
    return state.brandList
  },
}

export const brands: Module<BrandState, RootState> = {
  state,
  mutations,
  actions,
  getters
}