import { get, toString } from 'lodash'
import jwtDecode from "jwt-decode"

export const getToken = () => {
  const token = localStorage.getItem('token')
  return toString(token)
}

/* istanbul ignore next */
export const getJWTToken = (token = getToken()) => {
  const jwtToken = jwtDecode(token)
  switch (get(jwtToken, 'u.type')) {
    case 'cms': return get(jwtToken, 'u.id')
    default: return
  }
}