import { Module } from 'vuex'
import { findIndex, forEach, get, size } from 'lodash'

import { RootState, PromotionState, AlertMessage, Promotion, PromotionShopType } from '@/type'
import { REQUEST_GET, REQUEST_DELETE, REQUEST_POST, REQUEST_PUT, REQUEST_PATCH } from './API_Request'
import router from '@/router'
import { PromotionList, PromotionShopTypeList } from '@/type/Promotion'
import { errorMessage } from '@/plugins/common'
import { PROMOTION_MESSAGE, GLOBAL_MESSAGE } from '@/plugins/message'

export const state = {
  promotionList: Array<Promotion>(),
  promotionShopTypeList: Array<PromotionShopType>()
}

export const mutations = {
  async GET_PROMOTION(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      const res = await REQUEST_GET('/promotions', data.searchForm)
      // console.log(res);
      const promotionList = get(res, 'data.data')
      if (size(promotionList) > 0) {
        const arrayList: Array<Promotion> = []
        forEach(promotionList, i => {
          arrayList.push({ ...i, total: get(res, 'data.total', promotionList.length), perPage: get(res, 'data.perPage') })
        })
        state.promotionList = arrayList
      } else {
        state.promotionList = []
      }
      // console.log(state);
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async GET_PROMOTION_SHOP_TYPE(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      const res = await REQUEST_GET('/shop_types')
      // console.log(res);
      const promotionShopTypeList = get(res, 'data')
      if (size(promotionShopTypeList) > 0) {
        const arrayList: Array<PromotionShopType> = []
        forEach(promotionShopTypeList, i => arrayList.push(i))
        state.promotionShopTypeList = arrayList
      } else {
        state.promotionShopTypeList = []
      }
      // console.log(state);
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async CREATE_PROMOTION(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      const formData = new FormData()
      formData.append('name', get(data, 'formData.name'))
      formData.append('title', get(data, 'formData.title'))
      formData.append('description', get(data, 'formData.description'))
      formData.append('image', data.formData?.image)
      formData.append('isPublic', get(data, 'formData.isPublic'))
      formData.append('order', get(data, 'formData.order'))
      formData.append('startTime', get(data, 'formData.startTime'))
      formData.append('endTime', get(data, 'formData.endTime'))

      const res = await REQUEST_POST('/promotions', formData)
      // console.log(res);
      switch (get(res, 'status')) {
        case 201: {
          state.promotionList?.push(get(res, 'data'))
          const { id } = get(res, 'data')
          const createFormData = {
            promotionId: id,
            shopTypeId: get(data, 'formData.shopType')
          }
          data.createPromotionShopType(createFormData)
          const arrayList: Array<Promotion> = []
          forEach(state.promotionList, (i) => {
            arrayList.push({ ...i, total: get(i, 'total', 0) + 1 })
          })
          state.promotionList = arrayList
          data.toggleLoading()
          const alert = new AlertMessage(PROMOTION_MESSAGE.CREATE.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
      /* istanbul ignore else */
      if (error) alert = new AlertMessage(PROMOTION_MESSAGE.CREATE.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  },
  async CREATE_PROMOTION_SHOP_TYPE(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      const promotionId = get(data, 'formData.promotionId')
      const shopTypeId = get(data, 'formData.shopTypeId')
      const res = await REQUEST_PUT(`/promotions/${promotionId}/shop_type/${shopTypeId}`)
      // console.log(res);
      switch (get(res, 'status')) {
        case 200: {
          data.toggleLoading()
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async EDIT_PROMOTION(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      // Generate Form
      const formData = new FormData()
      formData.append('name', get(data, 'formData.name'))
      formData.append('title', get(data, 'formData.title'))
      formData.append('description', get(data, 'formData.description'))
      formData.append('isPublic', get(data, 'formData.isPublic'))
      formData.append('order', get(data, 'formData.order'))
      formData.append('startTime', get(data, 'formData.startTime'))
      formData.append('endTime', get(data, 'formData.endTime'))
      /* istanbul ignore else */
      if (data.formData?.image !== undefined) formData.append('image', data.formData?.image)

      const res = await REQUEST_PUT('/promotions/' + get(data, 'formData.editId'), formData)
      // console.log(res);
      switch (get(res, 'status')) {
        case 200: {
          data.toggleLoading()
          const { id } = get(res, 'data')
          const shopTypes = get(data, 'formData.shopType')
          const createFormData = {
            promotionId: id,
            shopTypes,
          }
          /* istanbul ignore next */
          data.editPromotionShopType(createFormData)
          forEach(state.promotionList, i => {
            /* istanbul ignore else */
            if (i.id === res.data?.id) {
              i.name = res.data?.name
              i.title = res.data?.title
              i.description = res.data?.description
              i.isPublic = res.data?.isPublic
              i.order = res.data?.order
              i.startTime = res.data?.startTime
              i.endTime = res.data?.endTime
              i.image = res.data?.image
            }
          })
          const alert = new AlertMessage(PROMOTION_MESSAGE.EDIT.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
      /* istanbul ignore else */
      if (error) alert = new AlertMessage(PROMOTION_MESSAGE.EDIT.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  },
  async EDIT_PROMOTION_SHOP_TYPE(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      const promotionId = get(data, 'formData.promotionId')
      const shopTypes = get(data, 'formData.shopTypes')
      const formData = { shopTypes }
      const res = await REQUEST_PATCH(`/promotions/${promotionId}/shop_types`, formData)
      // console.log(res);
      switch (get(res, 'status')) {
        case 200: {
          data.toggleLoading()
          const shopTypeList: any = [] // eslint-disable-line
          forEach(shopTypes, i => {
            shopTypeList.push({
              promotionId,
              shopTypeId: i
            })
          })
          forEach(state.promotionList, i => {
            /* istanbul ignore else */
            if (i.id === promotionId) {
              i.shopType = shopTypeList
            }
          })
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async REMOVE_PROMOTION(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      // console.log(data.paramId);
      /* istanbul ignore next */
      if (get(data, 'paramId') === undefined) router.go(0)
      const res = await REQUEST_DELETE('/promotions/' + get(data, 'paramId'))
      // console.log(res);
      switch (get(res, 'status')) {
        case 200: {
          const promotionList = state.promotionList
          const arrayList: Array<Promotion> = []
          const deleteIndex = findIndex(promotionList, { id: data.paramId })
          forEach(promotionList, (i, index) => {
            /* istanbul ignore else */
            if (parseInt(index) !== deleteIndex) {
              arrayList.push({ ...i, total: (get(i, 'total', 0) - 1) < 0 ? 0 : get(i, 'total', 0) - 1 })
            }
          })
          state.promotionList = arrayList
          data.toggleLoading()
          const alert = new AlertMessage(PROMOTION_MESSAGE.DELETE.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
      /* istanbul ignore else */
      if (error) alert = new AlertMessage(PROMOTION_MESSAGE.DELETE.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  }
}

export const actions = {
  getPromotion(context: any, searchForm: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    context.commit('GET_PROMOTION', { toggleLoading, searchForm })
  },
  getPromotionShopType(context: any) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    context.commit('GET_PROMOTION_SHOP_TYPE', { toggleLoading })
  },
  createPromotion(context: any, formData: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    const createPromotionShopType = (formData: object) => context.dispatch('createPromotionShopType', formData)
    const toggleAlert = (alertForm: AlertMessage) => context.dispatch('toggleAlertMessage', alertForm)
    context.commit('CREATE_PROMOTION', { toggleLoading, formData, toggleAlert, createPromotionShopType })
  },
  createPromotionShopType(context: any, formData: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    context.commit('CREATE_PROMOTION_SHOP_TYPE', { toggleLoading, formData })
  },
  editPromotion(context: any, formData: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    const editPromotionShopType = (formData: object) => context.dispatch('editPromotionShopType', formData)
    const toggleAlert = (alertForm: AlertMessage) => context.dispatch('toggleAlertMessage', alertForm)
    context.commit('EDIT_PROMOTION', { toggleLoading, formData, toggleAlert, editPromotionShopType })
  },
  editPromotionShopType(context: any, formData: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    context.commit('EDIT_PROMOTION_SHOP_TYPE', { toggleLoading, formData })
  },
  removePromotion(context: any, paramId: number) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm: AlertMessage) => context.dispatch('toggleAlertMessage', alertForm)
    context.commit('REMOVE_PROMOTION', { toggleLoading, paramId, toggleAlert })
  }
}

export const getters = {
  // Add Logic Before Computed
  promotionList(state: any): PromotionList | undefined { // eslint-disable-line
    return state.promotionList
  },
  promotionShopTypeList(state: any): PromotionShopTypeList | undefined { // eslint-disable-line
    return state.promotionShopTypeList
  }
}

export const promotions: Module<PromotionState, RootState> = {
  state,
  mutations,
  actions,
  getters
}
