
import Vue from "vue";

export default Vue.extend({
  name: "App",
  computed: {
    layout() {
      return this.$route.meta.layout || 'defaultLayout';
    },
  },
  created() {
    if (
      this.$browserDetect.isChrome ||
      this.$browserDetect.isChromeIOS ||
      this.$browserDetect.isFirefox ||
      this.$browserDetect.isSafari
    ) {
      // this.$swalNoBtn('เว็บไซต์นี้ไม่สามารถใช้งานได้ด้วย browser นี้. กรุณใช้ Chrome, Firefox, หรือ Safari ในการใช้งาน');
    } else {
      this.$swalNoBtn(
        "เว็บไซต์นี้ไม่สามารถใช้งานได้ด้วย browser นี้. กรุณใช้ Chrome, Firefox, หรือ Safari ในการใช้งาน"
      );
      this.navigate("/");
    }
  },
});
