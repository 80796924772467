
import { Component, Ref, Vue } from "vue-property-decorator";
import Utils from "@/mixin";
import { VForm } from "@/type";

@Component({
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    toggleDialog: {
      type: Function,
      // eslint-disable-next-line
      default: /* istanbul ignore next */ () => {},
    },
    data: {
      type: Object,
      default: () => ({ name: "" }),
    },
    brands: {
      type: Array,
      default: () => ([]),
    },
    brand: {
      type: Object,
      default: () => ({ value: "", text: "", index: 0 }),
    }
  },
  mixins: [Utils],
  watch: {
    data: {
      handler(val) {
        this.formData = {
          ...val
        };
      },
      deep: false,
    },
  },
})
export default class PromotionForm extends Vue {
  valid = false;
  initialForm = {
    id: "",
    startTime: "",
    endTime: ""
  };
  formData = this.initialForm;
  /* istanbul ignore next */
  rules = {
    required: (value: any) => !!value || "Required.", // eslint-disable-line
    empty: (value: any) => !!value && value.length > 0 || "Required.", // eslint-disable-line
  };
  startMenu = false;
  endMenu = false;

  @Ref("form") readonly form!: VForm;
  createPromotionCamp!: (formData: object) => void;
  editPromotionCamp!: (formData: object) => void;
  toggleDialog!: () => void;

  clearData() {
    this.toggleDialog();
    this.$nextTick(() => {
      this.formData = Object.assign({}, this.initialForm);
      /* istanbul ignore next */
      if (this.form) this.form.reset();
    });
  }

  submitForm() {
    const { formData } = this;

    if (formData?.id) {
      this.editPromotionCamp(formData);
    } else {
      this.createPromotionCamp(formData);
    }
    this.clearData();
  }
}
