
import { Component, Vue } from "vue-property-decorator";
import { forEach } from "lodash";
import Utils from "@/mixin";

import CreateBanner from "./Banner/Form.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import LightBox from "@/components/LightBox.vue";
import { itemsPerPageOptions } from "@/plugins/common";

@Component({
  components: {
    ConfirmDialog,
    CreateBanner,
    LightBox,
  },
  mixins: [Utils],
  mounted() {
    this.getInitialData();
    this.itemsPerPageOptions = itemsPerPageOptions;
  },
  watch: {
    // keyword(nextProps, prevProps) {
    //   /* istanbul ignore else */
    //   if (nextProps !== prevProps) {
    //     this.getInitialData();
    //   }
    // },
    itemPerPage(nextProps, prevProps) {
      /* istanbul ignore else */
      if (nextProps !== prevProps) {
        this.getInitialData();
      }
    },
    page(nextProps, prevProps) {
      /* istanbul ignore else */
      if (nextProps !== prevProps) {
        this.getInitialData();
      }
    },
    /* istanbul ignore next */
    bannerList(nextProps, prevProps) {
      if (nextProps !== prevProps) {
        forEach(this.bannerList, (i) => {
          this.total = i.total;
          // this.itemPerPage = i.perPage;
        });
      }
    },
  },
})
export default class Banner extends Vue {
  deleteId = -1;
  dialog = false;
  confirmDialog = false;
  page = 1;
  total = 0;
  itemsPerPageOptions = [];
  itemPerPage = 10;
  keyword = "";
  headers = [
    { text: "Name", value: "name", align: "center", sortable: false },
    { text: "Image", value: "image", align: "center", sortable: false },
    { text: "Public", value: "isPublic", align: "center", sortable: false },
    { text: "Actions", value: "actions", sortable: false },
  ];
  imgArr: Array<string> = [];
  imgShow = false;

  getBanner!: (searchForm?: object) => void;
  removeBanner!: (paramId?: number) => void;
  // eslint-disable-next-line
  onKeyDown(e: any) {
    const { keyCode } = e;
    if (keyCode === 13) {
      this.getInitialData();
    }
  }
  // eslint-disable-next-line
  showImg(image: any) {
    this.imgShow = true;
    if (typeof image === "string") {
      this.imgArr = [image];
    } else {
      this.imgArr = image;
    }
  }

  getInitialData() {
    const { page, itemPerPage, keyword } = this;
    const searchForm = {
      page,
      perPage: itemPerPage,
      keyword,
    };
    this.getBanner(searchForm);
  }

  toggleDialog() {
    this.dialog = !this.dialog;
  }

  toggleConfirmModal(paramId: number) {
    this.deleteId = paramId;
    this.confirmDialog = !this.confirmDialog;
  }

  cancel() {
    this.deleteId = -1;
    this.confirmDialog = !this.confirmDialog;
  }

  actionItem() {
    const { deleteId } = this;
    this.removeBanner(deleteId);
    this.cancel();
  }
}
