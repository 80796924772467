import { LuckyReward } from './Reward'

export class Content {
  id?: number
  title: string
  description: string
  startAt: string
  stopAt: string
  announceAt: string
  redeemPoint: number
  type: string
  image?: File
  imageBannerUrl?: File
  deleteAt?: Date
  imageUrl?: string
  listBannerImageUrl?: string
  isActive?: boolean
  rewards?: Array<LuckyReward>

  constructor() {
    this.title = ''
    this.description = ''
    this.startAt = ''
    this.stopAt = ''
    this.announceAt = ''
    this.redeemPoint = 0
    this.type = ''
    this.image = new File([], '')
    this.imageBannerUrl = new File([], '')
  }
}

export type ContentList = Content[]