
import { Component, Vue } from "vue-property-decorator";
import { forEach } from "lodash";
import Utils from "@/mixin";

import { itemsPerPageOptions } from "@/plugins/common";

import CreateBrand from "./PointRule/Form.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import LightBox from "@/components/LightBox.vue";

@Component({
  components: {
    CreateBrand,
    ConfirmDialog,
    LightBox,
  },
  mixins: [Utils],
  mounted() {
    this.getInitialData();
    this.itemsPerPageOptions = itemsPerPageOptions;
  },
  watch: {
    // keyword(nextProps, prevProps) {
    //   /* istanbul ignore else */
    //   if (nextProps !== prevProps) {
    //     this.getInitialData();
    //   }
    // },
    itemPerPage(nextProps, prevProps) {
      /* istanbul ignore else */
      if (nextProps !== prevProps) {
        this.getInitialData();
      }
    },
    page(nextProps, prevProps) {
      /* istanbul ignore else */
      if (nextProps !== prevProps) {
        this.getInitialData();
      }
    },
    /* istanbul ignore next */
    pointList(nextProps, prevProps) {
      if (nextProps !== prevProps) {
        forEach(this.pointList, (i) => {
          this.total = i.total;
          // this.itemPerPage = i.perPage;
        });
      }
    },
  },
})
export default class Point extends Vue {
  editId = "";
  deleteId = -1;
  dialog = false;
  confirmDialog = false;
  page = 1;
  total = 0;
  itemsPerPageOptions = [];
  itemPerPage = 10;
  keyword = "";
  headers = [
    { text: "Eng Name", value: "NameEN", align: "center", sortable: false },
    { text: "Thai Name", value: "nameTH", align: "center", sortable: false },
    { text: "Brand", value: "brand", align: "center", sortable: false },
    {
      text: "Image",
      value: "image",
      align: "center",
      width: "100px",
      sortable: false,
    },
    { text: "Shop Type", value: "shopType", align: "center", sortable: false },
    { text: "Actions", value: "actions", sortable: false, width: "120px" },
  ];
  imgArr: Array<string> = [];
  imgShow = false;

  getPointRule!: (searchForm?: object) => void;
  removeBrand!: (paramId: number) => void;
  // eslint-disable-next-line
  onKeyDown(e: any) {
    const { keyCode } = e;
    if (keyCode === 13) {
      this.getInitialData();
    }
  }
  // eslint-disable-next-line
  showImg(image: any) {
    this.imgShow = true;
    if (typeof image === "string") {
      this.imgArr = [image];
    } else {
      this.imgArr = image;
    }
  }

  getInitialData() {
    const { page, itemPerPage, keyword } = this;
    const searchForm = {
      page,
      perPage: itemPerPage,
      keyword,
    };
    this.getPointRule(searchForm);
  }

  selectItem(pointRuleId: string) {
    this.$router.push({ name: "Product", params: { pointRuleId } });
  }

  setInitialDialog() {
    this.editId = "";
  }

  editItem(paramId: string) {
    this.editId = paramId;
    this.toggleDialog();
  }

  toggleDialog() {
    this.dialog = !this.dialog;
  }

  toggleConfirmModal(paramId: number) {
    this.deleteId = paramId;
    this.confirmDialog = !this.confirmDialog;
  }

  cancel() {
    this.deleteId = -1;
    this.confirmDialog = !this.confirmDialog;
  }

  deleteItem() {
    const { deleteId } = this;
    this.removeBrand(deleteId);
    this.cancel();
  }
}
