import { Module } from 'vuex'
import { findIndex, forEach, get, size } from 'lodash'

import { RootState, AlertMessage, ProductState, Product } from '@/type'
import { ProductList } from '@/type/Product'
import { REQUEST_GET, REQUEST_POST, REQUEST_PUT, REQUEST_DELETE } from './API_Request'
import { errorMessage } from '@/plugins/common'
import { GLOBAL_MESSAGE, PRODUCT_MEESAGE } from '@/plugins/message'

export const state = {
  productList: Array<Product>()
}

export const mutations = {
  async GET_CALCULATOR_PRODUCT(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      const res = await REQUEST_GET('/products', data.searchForm)
      // console.log(res);
      const productList = get(res, 'data.data', [])
      if (size(productList) > 0) {
        const arrayList: Array<Product> = []
        forEach(productList, i => {
          arrayList.push({ ...i, total: get(res, 'data.total', productList.length), perPage: get(res, 'data.perPage') })
        })
        state.productList = arrayList
      } else {
        state.productList = []
      }
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async CREATE_CALCULATOR_PRODUCT(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      // Generate Form
      const formData = new FormData()
      formData.append('sku', get(data, 'formData.sku'))
      formData.append('brandId', get(data, 'formData.brandId'))
      formData.append('name', get(data, 'formData.name'))
      formData.append('size', get(data, 'formData.size'))
      formData.append('unit', get(data, 'formData.unit'))
      formData.append('price', get(data, 'formData.price'))
      formData.append('pack', get(data, 'formData.pack'))
      formData.append('point', get(data, 'formData.point'))
      formData.append('isRecommend', get(data, 'formData.isRecommend'))
      const res = await REQUEST_POST('/products', formData)
      // console.log(res);
      switch (get(res, 'status')) {
        case 201: {
          state.productList?.push(get(res, 'data'))
          data.toggleLoading()
          const alert = new AlertMessage(PRODUCT_MEESAGE.CREATE.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          // location.reload()
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
      /* istanbul ignore else */
      if (error) alert = new AlertMessage(PRODUCT_MEESAGE.CREATE.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  },
  async EDIT_CALCULATOR_PRODUCT(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      // Generate Form
      const formData = new FormData()
      formData.append('sku', get(data, 'formData.sku'))
      formData.append('brandId', get(data, 'formData.brandId'))
      formData.append('name', get(data, 'formData.name'))
      formData.append('size', get(data, 'formData.size'))
      formData.append('unit', get(data, 'formData.unit'))
      formData.append('price', get(data, 'formData.price'))
      formData.append('pack', get(data, 'formData.pack'))
      formData.append('point', get(data, 'formData.point'))
      formData.append('isRecommend', get(data, 'formData.isRecommend'))
      const editId = get(data, 'formData.editId')

      const res = await REQUEST_PUT('/products/' + editId, formData)
      // console.log(res);
      switch (get(res, 'status')) {
        case 200: {
          const products = state.productList
          forEach(products, i => {
            /* istanbul ignore else */
            if (i.id === res.data?.id) {
              i.name = res.data?.name
              i.sku = res.data?.sku
              i.brandId = res.data?.brandId
              i.size = res.data?.size
              i.unit = res.data?.unit
              i.price = res.data?.price
              i.pack = res.data?.pack
              i.point = res.data?.point
              i.isRecommend = res.data?.isRecommend
            }
          })
          data.toggleLoading()
          const alert = new AlertMessage(PRODUCT_MEESAGE.EDIT.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
      /* istanbul ignore else */
      if (error) alert = new AlertMessage(PRODUCT_MEESAGE.EDIT.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  },
  async REMOVE_CALCULATOR_PRODUCT(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      const deleteId: number = get(data, 'paramId')

      const res = await REQUEST_DELETE('/products/' + deleteId)
      // console.log(res);
      switch (get(res, 'status')) {
        case 204: {
          const products = state.productList
          const productList: Array<Product> = []
          const deleteIndex = findIndex(products, { id: deleteId })
          // console.log(deleteIndex);
          forEach(products, (i, index) => {
            /* istanbul ignore else */
            if (parseInt(index) !== deleteIndex) {
              productList.push(i)
            }
          })
          state.productList = productList
          data.toggleLoading()
          const alert = new AlertMessage(PRODUCT_MEESAGE.DELETE.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
      /* istanbul ignore else */
      if (error) alert = new AlertMessage(PRODUCT_MEESAGE.DELETE.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  },
  async IMPORT_CALCULATOR_PRODUCT(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      // Generate Form
      const formData = new FormData()
      formData.append('file', data?.formData?.file)
      const res = await REQUEST_POST('/products/bulkInsert', formData)
      // console.log(res);
      switch (get(res, 'status')) {
        case 200: {
          data.toggleLoading()
          const alert = new AlertMessage(PRODUCT_MEESAGE.IMPORT.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          location.reload()
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
      /* istanbul ignore else */
      if (error) alert = new AlertMessage(PRODUCT_MEESAGE.IMPORT.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  },
}

export const actions = {
  getCalculatorProduct(context: any, searchForm: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    context.commit('GET_CALCULATOR_PRODUCT', { toggleLoading, searchForm })
  },
  createCalculatorProduct(context: any, formData: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm: AlertMessage) => context.dispatch('toggleAlertMessage', alertForm)
    context.commit('CREATE_CALCULATOR_PRODUCT', { toggleLoading, formData, toggleAlert })
  },
  editCalculatorProduct(context: any, formData: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm: AlertMessage) => context.dispatch('toggleAlertMessage', alertForm)
    context.commit('EDIT_CALCULATOR_PRODUCT', { toggleLoading, formData, toggleAlert })
  },
  removeCalculatorProduct(context: any, paramId: number | undefined) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm: AlertMessage) => context.dispatch('toggleAlertMessage', alertForm)
    context.commit('REMOVE_CALCULATOR_PRODUCT', { toggleLoading, paramId, toggleAlert })
  },
  importCalculatorProduct(context: any, formData: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm: AlertMessage) => context.dispatch('toggleAlertMessage', alertForm)
    context.commit('IMPORT_CALCULATOR_PRODUCT', { toggleLoading, formData, toggleAlert })
  }
}

export const getters = {
  // Add Logic Before Computed
  productList(state: any): ProductList | undefined { // eslint-disable-line
    return state.productList
  },
}

export const calculatorProduct: Module<ProductState, RootState> = {
  state,
  mutations,
  actions,
  getters
}