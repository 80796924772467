
import { Component, Vue } from "vue-property-decorator";
import moment from "moment";
import { get } from "lodash";
import Utils from "@/mixin";

import { Brand } from "@/type/Brand";
import { itemsPerPageOptions } from "@/plugins/common";
import CreatePromotion from "./Promotion/Form.vue";
import LightBox from "@/components/LightBox.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";

@Component({
  components: {
    CreatePromotion,
    LightBox,
    ConfirmDialog,
  },
  mixins: [Utils],
  mounted() {
    this.getInitialData();
    this.itemsPerPageOptions = itemsPerPageOptions;
  },
  computed: {
    promotionList() {
      return this.brandList?.[this.brandIndex]?.promotions || [];
    },
    brands() {
      const brands = this.brandList?.map((e: Brand, k: number) => {
        return {
          value: e.id,
          text: e.name,
          index: k,
        };
      });
      this.brandData = brands;
      this.brand = brands[this.brandIndex];
      return brands;
    },
  },
})
export default class Promotion extends Vue {
  brandData = [];
  brand = null;
  brandIndex = 0;
  deleteId = -1;
  confirmDialog = false;
  item = {};
  itemsPerPageOptions = [];
  keyword = "";
  dialog = false;
  headers = [
    { text: "Name", value: "name", sortable: false },
    { text: "Title", value: "title", sortable: false },
    { text: "Start", value: "startTime", align: "end", sortable: false },
    { text: "End", value: "endTime", align: "end", sortable: false },
    { text: "Order", value: "order", align: "end", sortable: false },
    { text: "Banner", value: "banner", align: "center", sortable: false },
    { text: "Images", value: "images", align: "center", sortable: false },
    { text: "Actions", value: "actions", sortable: false, width: "100px" },
  ];
  imgArr: Array<string> = [];
  imgShow = false;

  getBrandCamp!: (searchForm?: object) => void;
  removePromotionCamp!: (paramId: object) => void;

  getInitialData() {
    this.getBrandCamp({});
  }

  dateFormat(date: string) {
    return moment(date, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY");
  }

  handlerBrand() {
    const index = get(this, "brand.index");
    if (index >= 0) {
      this.brandIndex = index || 0;
    }
  }
  // eslint-disable-next-line
  toggleDialog(data: any = {}) {
    if (data?.id) {
      this.item = {
        ...data,
        brand: this.brandData.find((e: any) => e.value === data.brandId), // eslint-disable-line
        startTime: moment(get(data, "startTime"), "YYYY-MM-DD").format(
          "YYYY-MM-DD"
        ),
        endTime: moment(get(data, "endTime"), "YYYY-MM-DD").format(
          "YYYY-MM-DD"
        ),
        banner: undefined,
        images: undefined,
      };
    } else {
      this.item = {
        brand: this.brand,
      };
    }
    this.dialog = !this.dialog;
  }

  toggleConfirmModal(paramId: number) {
    this.deleteId = paramId;
    this.confirmDialog = !this.confirmDialog;
  }

  editItem(data: object = {}) {
    this.toggleDialog(data);
  }
  // eslint-disable-next-line
  showImg(image: any) {
    this.imgShow = true;

    if (typeof image === "string") {
      this.imgArr = [image];
    } else {
      this.imgArr = image;
    }
  }

  cancel() {
    this.deleteId = -1;
    this.confirmDialog = !this.confirmDialog;
  }

  deleteItem() {
    const { deleteId, brand } = this;
    this.removePromotionCamp({ id: deleteId, brand });
    this.cancel();
  }
}
