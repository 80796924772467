import { Component, Vue } from 'vue-property-decorator'
import { mapActions, mapGetters } from 'vuex'

//Global computed and methods
@Component({
  //Store getters
  computed: mapGetters(['isLoading', 'isParticipantLoading', 'auth', 'alert'
    , 'userList', 'lineUserList'
    , 'luckyDraw', 'luckyDrawParticipant'
    , 'bannerList', 'brandList', 'promotionList', 'rewardCategoryList', 'shopList', 'highlightList'
    , 'rewardList', 'pointList', 'employeeList', 'historyList', 'promotionShopTypeList', 'productList'
    , 'heroRewardList', 'heroLeaderList', 'heroWinnerList']),
  //Store actions
  methods: {
    ...mapActions(['toggleLoading', 'toggleAlertMessage', 'login', 'logout'
      , 'getUser', 'getLineUser', 'createUser', 'removeUser'
      , 'createContent', 'editContent'
      , 'createLuckyDrawReward', 'editLuckyDrawReward'
      , 'getLuckyDraw', 'requestAnnouncement', 'removeLuckyDraw', 'removeLuckyDrawReward'
      , 'getParticipant', 'createWinner'
      , 'getBanner', 'createBanner', 'removeBanner'
      , 'getPromotion', 'createPromotion', 'editPromotion', 'removePromotion'
      , 'getRewardCategory', 'createRewardCategory', 'editRewardCategory', 'removeRewardCategory'
      , 'getShop', 'getShopInfo', 'removeShop'
      , 'getRewardCategoryCamp', 'createRewardCategoryCamp', 'editRewardCategoryCamp', 'removeRewardCategoryCamp'
      , 'getBrandCamp', 'createBrandCamp', 'editBrandCamp', 'removeBrandCamp'
      , "createPromotionCamp", "editPromotionCamp", "removePromotionCamp"
      , 'getHighlightCamp', 'createHighlightCamp', 'removeHighlightCamp'
      , 'getPointRule', 'createBrand', 'editBrand', 'removeBrand', 'createProduct', 'editProduct', 'removeProduct'
      , 'getReward', 'createReward', 'editReward', 'removeReward'
      , 'getHeroReward', 'createHeroReward', 'editHeroReward', 'removeHeroReward'
      , 'getHeroWinner', 'createHeroWinner', 'editHeroWinner', 'removeHeroWinner'
      , 'getHeroWinnerDetail', 'createHeroWinnerDetail', 'editHeroWinnerDetail', 'removeHeroWinnerDetail'
      , 'getEmployee', 'syncEmployee', 'unlinkEmployee', 'syncShop', 'unlinkShop','uploadUserAttachmentShop'
      , 'getHistory', 'exportHistory', 'exportAllHistory'
      , 'getPromotionShopType'
      , 'getCalculatorProduct', 'createCalculatorProduct', 'editCalculatorProduct', 'removeCalculatorProduct'
      , 'getBrand', 'createFamilyBrand', 'editFamilyBrand', 'removeFamilyBrand', 'importCalculatorProduct'])
  }
})
export default class Utils extends Vue { }