export class Product {
  id?: number
  name?: string
  code?: string
  sku?: string
  description?: string
  order?: number
  image?: string
  createdAt?: string
  updatedAt?: string
  point?: number | string
  brand?: string
  brandId?: number
  size?: number
  pack?: number
  price?: number
  isRecommend?: boolean
  unit?: string

  constructor() {
    this.name = ''
    this.code = ''
    this.description = ''
    this.order = 0
    this.image = ''
    this.point = 0
  }
}

export type ProductList = Product[]