
import { Component, Vue } from "vue-property-decorator";
import Utils from "@/mixin";
import { itemsPerPageOptions } from "@/plugins/common";

import CreateHighlight from "./Highlight/Form.vue";
import LightBox from "@/components/LightBox.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";

@Component({
  components: {
    CreateHighlight,
    LightBox,
    ConfirmDialog,
  },
  mixins: [Utils],
  mounted() {
    this.getInitialData();
    this.itemsPerPageOptions = itemsPerPageOptions;
  },
})
export default class Highlight extends Vue {
  deleteId = -1;
  confirmDialog = false;
  itemsPerPageOptions = [];
  keyword = "";
  dialog = false;
  headers = [
    { text: "Header", value: "header", align: "start", sortable: false },
    { text: "Banner", value: "image", align: "center", sortable: false },
    { text: "Images", value: "order", align: "center", sortable: false },
    { text: "Actions", value: "actions", sortable: false, width: "100px" },
  ];
  imgArr: Array<string> = [];
  imgShow = false;

  getHighlightCamp!: (searchForm?: object) => void;
  removeHighlightCamp!: (paramId: number) => void;

  getInitialData() {
    this.getHighlightCamp({});
  }

  toggleDialog() {
    this.dialog = !this.dialog;
  }

  toggleConfirmModal(paramId: number) {
    this.deleteId = paramId;
    this.confirmDialog = !this.confirmDialog;
  }
  // eslint-disable-next-line
  showImg(image: any) {
    this.imgShow = true;

    if (typeof image === "string") {
      this.imgArr = [image];
    } else {
      this.imgArr = image;
    }
  }

  cancel() {
    this.deleteId = -1;
    this.confirmDialog = !this.confirmDialog;
  }

  deleteItem() {
    const { deleteId } = this;
    this.removeHighlightCamp(deleteId);
    this.cancel();
  }
}
