import { Module } from 'vuex'
import { cloneDeep, findIndex, forEach, get, size } from 'lodash'

import { RootState, AlertMessage, EmployeeState, Employee } from '@/type'
import { EmployeeList } from '@/type/Employee'
import { REQUEST_GET, REQUEST_POST, REQUEST_DELETE } from './API_Request'
import { errorMessage } from '@/plugins/common'
import { GLOBAL_MESSAGE, EMPLOYEE_MEESAGE } from '@/plugins/message'

export const state = {
  employeeList: Array<Employee>()
}

export const mutations = {
  async GET_EMPLOYEE(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      const res = await REQUEST_GET('/employees', data.searchForm)
      // console.log(res);
      const employeeList = get(res, 'data.data', [])
      if (size(employeeList) > 0) {
        const arrayList: Array<Employee> = []
        forEach(employeeList, i => {
          arrayList.push({ ...i, total: get(res, 'data.total') !== 0 ? get(res, 'data.total') : employeeList.length, perPage: get(res, 'data.perPage') })
        })
        state.employeeList = arrayList
      } else {
        state.employeeList = []
      }
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async SYNC_EMPLOYEE(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      const code: string = get(data, 'code')

      const res = await REQUEST_POST('/employees/' + code + '/sync')
      // console.log(res);
      switch (get(res, 'status')) {
        case 200: {
          data.toggleLoading()
          const employeeList = cloneDeep(state.employeeList)
          const updateIndex = findIndex(employeeList, { code })
          /* istanbul ignore next */
          if (code === res.data?.code) {
            employeeList[updateIndex].code = res.data?.code
            employeeList[updateIndex].fullName = res.data?.fullName
            employeeList[updateIndex].roleId = res.data?.roleId
            employeeList[updateIndex].roleTitle = res.data?.roleTitle
            employeeList[updateIndex].regions = res.data?.regions
            employeeList[updateIndex].territoryCode = res.data?.territoryCode
            employeeList[updateIndex].territoryDesc = res.data?.territoryDesc
            employeeList[updateIndex].status = res.data?.status
            employeeList[updateIndex].registeredDate = res.data?.registeredDate
            employeeList[updateIndex].userId = res.data?.userId
            employeeList[updateIndex].phone = res.data?.phone
            employeeList[updateIndex].totalPoint = res.data?.totalPoint
            employeeList[updateIndex].organizationChart = res.data?.organizationChart
            employeeList[updateIndex].saleType = res.data?.saleType
            employeeList[updateIndex].level = res.data?.level
            employeeList[updateIndex].levels = res.data?.levels
            employeeList[updateIndex].shops = res.data?.shops
            employeeList[updateIndex].points = res.data?.points
            state.employeeList = employeeList
          }
          const alert = new AlertMessage(EMPLOYEE_MEESAGE.SYNC.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
      /* istanbul ignore else */
      if (error) alert = new AlertMessage(EMPLOYEE_MEESAGE.SYNC.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  },
  async UNLINK_EMPLOYEE(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      const code: string = get(data, 'code')

      const res = await REQUEST_DELETE('/employees/' + code + '/unlink')
      // console.log(res);
      switch (get(res, 'status')) {
        case 200: {
          data.toggleLoading()
          const employeeList = cloneDeep(state.employeeList)
          const deleteIndex = findIndex(employeeList, { code })
          /* istanbul ignore next */
          if (code === res.data?.code) {
            employeeList[deleteIndex].userId = 0
            state.employeeList = employeeList
          }
          const alert = new AlertMessage(EMPLOYEE_MEESAGE.UNLINK.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
      /* istanbul ignore else */
      if (error) alert = new AlertMessage(EMPLOYEE_MEESAGE.UNLINK.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  },
}

export const actions = {
  getEmployee(context: any, searchForm: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    context.commit('GET_EMPLOYEE', { toggleLoading, searchForm })
  },
  syncEmployee(context: any, code: string | undefined) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm: AlertMessage) => context.dispatch('toggleAlertMessage', alertForm)
    context.commit('SYNC_EMPLOYEE', { toggleLoading, code, toggleAlert })
  },
  unlinkEmployee(context: any, code: string | undefined) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm: AlertMessage) => context.dispatch('toggleAlertMessage', alertForm)
    context.commit('UNLINK_EMPLOYEE', { toggleLoading, code, toggleAlert })
  },
}

export const getters = {
  // Add Logic Before Computed
  employeeList(state: any): EmployeeList | undefined { // eslint-disable-line
    return state.employeeList
  },
}

export const employees: Module<EmployeeState, RootState> = {
  state,
  mutations,
  actions,
  getters
}