
import { Component, Ref, Vue } from "vue-property-decorator";
import { findIndex, isEmpty } from "lodash";
import Utils from "@/mixin";
import { RewardCategory, VForm } from "@/type";

@Component({
  props: {
    editId: {
      type: [Number, String],
      default: "",
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    toggleDialog: {
      type: Function,
      // eslint-disable-next-line
      default: /* istanbul ignore next */ () => {},
    },
    setInitialDialog: {
      type: Function,
      // eslint-disable-next-line
      default: /* istanbul ignore next */ () => {},
    },
  },
  mixins: [Utils],
  computed: {
    validate() {
      const { title } = this.formData;
      if (isEmpty(title)) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    editId(nextProps, prevProps) {
      /* istanbul ignore else */
      if (prevProps !== nextProps && nextProps !== "") {
        this.modalTitle = "Edit";
        const { rewardCategoryList } = this;
        const index = findIndex(
          rewardCategoryList,
          (i: RewardCategory) => i.id === nextProps
        );
        const data = rewardCategoryList[index];
        this.formData = {
          title: data.title,
        };
      }
    },
  },
})
export default class RewardCategoryForm extends Vue {
  modalTitle = "Create";
  formData = {
    title: "",
  };
  initialForm = {
    title: "",
  };
  /* istanbul ignore next */
  rules = {
    required: (value: [number, string]) => !!value || "Required.",
  };

  @Ref("form") readonly form!: VForm;
  createRewardCategory!: (formData: object) => void;
  editRewardCategory!: (formData: object) => void;
  toggleDialog!: () => void;

  clearData() {
    if (this.$props.editId !== "") this.$props.setInitialDialog();
    this.toggleDialog();
    this.$nextTick(() => {
      this.modalTitle = "Create";
      this.formData = Object.assign({}, this.initialForm);
      /* istanbul ignore next */
      if (this.form) this.form.resetValidation();
    });
  }

  submitForm() {
    const { formData } = this;
    if (this.$props.editId !== "") {
      const form = {
        ...formData,
        type: "employee",
        editId: this.$props.editId,
      };
      this.editRewardCategory(form);
    } else {
      const form = {
        ...formData,
        type: "employee",
      };
      this.createRewardCategory(form);
    }
    this.clearData();
  }
}
