
import { Component, Ref, Vue } from "vue-property-decorator";
import { forEach } from "lodash";
import Utils from "@/mixin";
import { VForm } from "@/type";
import { itemsPerPageOptions } from "@/plugins/common";

import ConfirmDialog from "@/components/ConfirmDialog.vue";

@Component({
  components: {
    ConfirmDialog,
  },
  mixins: [Utils],
  mounted() {
    // console.log(this);
    this.getInitialData();
    this.itemsPerPageOptions = itemsPerPageOptions;
  },
  watch: {
    // keyword(nextProps, prevProps) {
    //   /* istanbul ignore else */
    //   if (nextProps !== prevProps) {
    //     this.getInitialData();
    //   }
    // },
    itemPerPage(nextProps, prevProps) {
      /* istanbul ignore else */
      if (nextProps !== prevProps) {
        this.getInitialData();
      }
    },
    page(nextProps, prevProps) {
      /* istanbul ignore else */
      if (nextProps !== prevProps) {
        this.getInitialData();
      }
    },
    /* istanbul ignore next */
    shopList(nextProps, prevProps) {
      if (nextProps !== prevProps) {
        forEach(this.shopList, (i) => {
          this.total = i.total;
          // this.itemPerPage = i.perPage;
        });
      }
    },
  },
})
export default class Shop extends Vue {
  selectId = -1;
  actionId = -1;
  page = 1;
  total = 0;
  itemsPerPageOptions = [];
  itemPerPage = 10;
  keyword = "";
  dialog = false;
  uploadedFile = null;
  uploadModal = false;
  deleteModal = false;
  syncModal = false;
  unlinkModal = false;
  headers = [
    {
      text: "Shop Code",
      value: "code",
      align: "start",
      sortable: false,
    },
    { text: "Name", value: "name", width: "400px", sortable: false },
    { text: "Region Code", value: "regionCode", sortable: false },
    { text: "Area Code", value: "areaCode", sortable: false },
    { text: "Privilege Type", value: "privilegeType" },
    { text: "Customer Sale Type", value: "customerSaleType", sortable: false },
    { text: "Territory Code", value: "territoryCode", sortable: false },
    { text: "Actions", value: "actions", sortable: false },
  ];

  @Ref("form") readonly form!: VForm;
  getShop!: (searchForm?: object) => void;
  getShopInfo!: (paramId?: number) => void;
  removeShop!: (paramId?: number) => void;
  syncShop!: (paramId?: number) => void;
  unlinkShop!: (paramId?: number) => void;
  uploadUserAttachmentShop!: (uploadData?: object) => void;
  // eslint-disable-next-line
  onKeyDown(e: any) {
    const { keyCode } = e;
    if (keyCode === 13) {
      this.getInitialData();
    }
  }

  getInitialData() {
    const { page, itemPerPage, keyword } = this;
    const searchForm = {
      page,
      perPage: itemPerPage,
      keyword,
    };
    this.getShop(searchForm);
  }

  selectItem(paramId: number) {
    this.selectId = paramId;
    this.getShopInfo(paramId);
    this.dialog = !this.dialog;
  }

  toggleDeleteModal(paramId: number) {
    this.actionId = paramId;
    this.deleteModal = !this.deleteModal;
  }

  toggleSyncModal(paramId: number) {
    this.actionId = paramId;
    this.syncModal = !this.syncModal;
  }

  toggleUnlinkModal(paramId: number) {
    this.actionId = paramId;
    this.unlinkModal = !this.unlinkModal;
  }

  toggleUploadModal(paramId: number) {
    this.actionId = paramId;
    this.uploadedFile = null;
    this.uploadModal = !this.uploadModal;
  }

  cancel() {
    this.selectId = -1;
    this.actionId = -1;
    this.deleteModal = false;
    this.syncModal = false;
    this.unlinkModal = false;
    this.uploadModal = false;
    this.uploadedFile = null;
    this.dialog = false;
  }

  deleteItem() {
    const { actionId } = this;
    this.removeShop(actionId);
    this.cancel();
  }

  syncItem() {
    const { actionId } = this;
    this.syncShop(actionId);
    this.cancel();
  }

  unlinkItem() {
    const { actionId } = this;
    this.unlinkShop(actionId);
    this.cancel();
  }

  uploadAttachment(){
    if(this.uploadedFile == null) return;
    const { actionId } = this;
    this.uploadUserAttachmentShop({
      paramId:actionId,
      file:this.uploadedFile
    });
    this.cancel();
  }
}
