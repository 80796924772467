
import { Component, Vue } from "vue-property-decorator";
import { includes, isEmpty } from "lodash";
import Utils from "@/mixin";

import Alert from "@/components/Alert.vue";

@Component({
  components: {
    Alert,
  },
  computed: {
    pathTitle() {
      const { path } = this.$route;
      if (includes(path, "camp")) {
        return "Sermsuk Camp";
      } else {
        return "Sermsuk Family";
      }
    },
    pathName() {
      const { path } = this.$route;
      if (includes(path, "camp")) {
        return "Sermsuk Family";
      } else {
        return "Sermsuk Camp";
      }
    },
    generateMenu() {
      const { path } = this.$route;
      if (includes(path, "camp")) {
        return this.campMenu;
      } else {
        return this.menu;
      }
    },
    snackbar() {
      if (!isEmpty(this.alert?.message)) return true;
      return false;
    },
    alertText() {
      if (!isEmpty(this.alert?.message)) return this.alert?.message;
      return "";
    },
    alertType() {
      if (!isEmpty(this.alert?.message)) return this.alert?.type;
      return "";
    },
  },
  watch: {
    snackbar(newValue, oldValue) {
      if (oldValue === false && newValue === true) {
        setTimeout(() => {
          this.alert.message = "";
        }, 3000);
      }
    },
  },
  mixins: [Utils],
  mounted() {
    const token = this.auth.token;
    // console.log("auth", this.auth);
    if (isEmpty(token)) {
      this.$router.push({ name: "Login" });
    }
  },
})
export default class AuthLayout extends Vue {
  drawer = null;
  logout!: () => void;
  menu = [
    { id: "home", iconName: "mdi-home", pathName: "/", name: "Home" },
    {
      id: "all-user",
      iconName: "mdi-account-group",
      name: "User",
      subGroup: true,
      active: false,
      subItems: [
        { id: "user", name: "CMS User", pathName: "/users" },
        { id: "line-user", name: "Line User", pathName: "/line-users" },
      ],
    },
    {
      id: "lucky-draw",
      iconName: "mdi-cards",
      pathName: "/lucky-draws",
      name: "Lucky Draw",
    },
    {
      id: "banner",
      iconName: "mdi-image",
      pathName: "/banners",
      name: "Banner",
    },
    {
      id: "brand",
      iconName: "mdi-tag",
      pathName: "/brand",
      name: "Brand",
    },
    {
      id: "promotion",
      iconName: "mdi-sale",
      pathName: "/promotions",
      name: "Promotion",
    },
    {
      id: "point-rule",
      iconName: "mdi-alpha-p-circle",
      pathName: "/point-rule",
      name: "Point Rule",
    },
    {
      id: "all-reward",
      iconName: "mdi-trophy",
      name: "Reward",
      subGroup: true,
      active: false,
      subItems: [
        { id: "reward", name: "All Reward", pathName: "/reward" },
        {
          id: "reward-category",
          name: "Reward Category",
          pathName: "/reward-category",
        },
      ],
    },
    { id: "shop", iconName: "mdi-store", pathName: "/shop", name: "Shop" },
    {
      id: "history",
      iconName: "mdi-history",
      pathName: "/history",
      name: "Redemption",
    },
    {
      id: "calculator",
      iconName: "mdi-calculator",
      pathName: "/calculator",
      name: "Calculator",
    },
  ];
  campMenu = [
    { id: "home-camp", iconName: "mdi-home", pathName: "/camp", name: "Home" },
    {
      id: "all-user",
      iconName: "mdi-account-group",
      name: "User",
      subGroup: true,
      active: false,
      subItems: [
        // { id: "user", name: "CMS User", pathName: "/users" },
        {
          id: "line-user-camp",
          name: "Line User",
          pathName: "/line-users-camp",
        },
      ],
    },
    {
      id: "brand-camp",
      iconName: "mdi-tag",
      pathName: "/brand-camp",
      name: "Brand",
    },
    {
      id: "promotion-camp",
      iconName: "mdi-sale",
      pathName: "/promotion-camp",
      name: "Promotion",
    },
    {
      id: "highlight-camp",
      iconName: "mdi-marker",
      pathName: "/highlight-camp",
      name: "Highlight",
    },
    {
      id: "all-reward-camp",
      iconName: "mdi-trophy",
      name: "Reward",
      subGroup: true,
      active: false,
      subItems: [
        { id: "reward-camp", name: "All Reward", pathName: "/reward-camp" },
        {
          id: "reward-category-camp",
          name: "Reward Category",
          pathName: "/reward-category-camp",
        },
      ],
    },
    {
      id: "employee-camp",
      iconName: "mdi-account-tie",
      pathName: "/employee-camp",
      name: "Employee",
    },
    {
      id: "history-camp",
      iconName: "mdi-history",
      pathName: "/history-camp",
      name: "History",
    },
    {
      id: "all-hero-camp",
      iconName: "mdi-trophy",
      name: "Hero",
      subGroup: true,
      active: false,
      subItems: [
        { id: "hero-reward-camp", name: "Reward", pathName: "/hero-reward-camp" },
        {
          id: "hero-announcement-camp",
          name: "Announcement",
          pathName: "/hero-announcement-camp",
        },
      ],
    },
  ];

  signOut() {
    this.logout();
  }

  switchRoute() {
    const { path } = this.$route;
    if (includes(path, "camp")) {
      this.$router.push({ name: "Home" });
    } else {
      this.$router.push({ name: "Home-Camp" });
    }
  }

  switchTheme() {
    this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
  }
}
