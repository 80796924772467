import { Module } from 'vuex'
import { forEach, get, size, findIndex } from 'lodash'

import { RootState, RewardState, AlertMessage, Reward, RewardCategory } from '@/type'
import { REQUEST_GET, REQUEST_DELETE, REQUEST_POST, REQUEST_PUT } from './API_Request'
import router from '@/router'
import { RewardCategoryList, RewardList } from '@/type/Reward'
import { errorMessage } from '@/plugins/common'
import { GLOBAL_MESSAGE, REWARD_MESSAGE } from '@/plugins/message'

export const state = {
  rewardList: Array<Reward>(),
  rewardCategoryList: Array<RewardCategory>()
}

export const mutations = {
  async GET_REWARD(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      const res = await REQUEST_GET('/rewards', data.searchForm)
      // console.log(res);
      const rewardList = get(res, 'data.data')
      if (size(rewardList) > 0) {
        const arrayList: Array<Reward> = []
        forEach(rewardList, i => {
          arrayList.push({ ...i, total: get(res, 'data.total', rewardList.length), perPage: get(res, 'data.perPage') })
        })
        state.rewardList = arrayList
      } else {
        state.rewardList = []
      }
      // console.log(state);
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async GET_REWARD_CATEGORY(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      const res = await REQUEST_GET('/reward_categories', data.searchForm)
      // console.log(res);
      const rewardCategoryList = get(res, 'data.data')
      if (size(rewardCategoryList) > 0) {
        const arrayList: Array<RewardCategory> = []
        forEach(rewardCategoryList, i => {
          arrayList.push({ ...i, total: get(res, 'data.total', rewardCategoryList.length), perPage: get(res, 'data.perPage') })
        })
        state.rewardCategoryList = arrayList
      } else {
        state.rewardCategoryList = []
      }
      // console.log(state);
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async CREATE_REWARD(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      const formData = new FormData()
      formData.append('title', get(data, 'formData.title'))
      formData.append('image', data.formData?.image)
      formData.append('detail', get(data, 'formData.detail'))
      formData.append('categoryId', get(data, 'formData.categoryId'))
      formData.append('redeemPoint', get(data, 'formData.redeemPoint'))
      formData.append('redeemAble', get(data, 'formData.redeemAble'))
      formData.append('isLimitQty', get(data, 'formData.isLimitQty'))
      formData.append('limitedQty', get(data, 'formData.limitedQty'))
      formData.append('type', get(data, 'formData.type'))
      const res = await REQUEST_POST('/rewards', formData)
      // console.log(res);
      switch (get(res, 'status')) {
        case 201: {
          state.rewardList?.push(get(res, 'data'))
          const arrayList: Array<Reward> = []
          forEach(state.rewardList, (i) => {
            arrayList.push({ ...i, total: get(i, 'total', 0) + 1 })
          })
          state.rewardList = arrayList
          data.toggleLoading()
          const alert = new AlertMessage(REWARD_MESSAGE.REWARD.CREATE.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
      /* istanbul ignore else */
      if (error) alert = new AlertMessage(REWARD_MESSAGE.REWARD.CREATE.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  },
  async CREATE_REWARD_CATEGORY(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      const formData = data.formData
      const res = await REQUEST_POST('/reward_categories', formData)
      // console.log(res);
      switch (get(res, 'status')) {
        case 201: {
          state.rewardCategoryList?.push(get(res, 'data'))
          const arrayList: Array<RewardCategory> = []
          forEach(state.rewardCategoryList, (i) => {
            arrayList.push({ ...i, total: get(i, 'total', 0) + 1 })
          })
          state.rewardCategoryList = arrayList
          data.toggleLoading()
          const alert = new AlertMessage(REWARD_MESSAGE.CATEGORY.CREATE.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
      /* istanbul ignore else */
      if (error) alert = new AlertMessage(REWARD_MESSAGE.CATEGORY.CREATE.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  },
  async EDIT_REWARD(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      // Generate Form
      const formData = new FormData()
      formData.append('title', get(data, 'formData.title'))
      formData.append('detail', get(data, 'formData.detail'))
      formData.append('categoryId', get(data, 'formData.categoryId'))
      formData.append('redeemPoint', get(data, 'formData.redeemPoint'))
      formData.append('redeemAble', get(data, 'formData.redeemAble'))
      formData.append('type', get(data, 'formData.type'))
      formData.append('isLimitQty', get(data, 'formData.isLimitQty'))
      formData.append('limitedQty', get(data, 'formData.limitedQty'))
      /* istanbul ignore else */
      if (data.formData?.image !== undefined) formData.append('image', data.formData?.image)

      const res = await REQUEST_PUT('/rewards/' + get(data, 'formData.editId'), formData)
      // console.log(res);
      switch (get(res, 'status')) {
        case 200: {
          data.toggleLoading()
          forEach(state.rewardList, i => {
            /* istanbul ignore else */
            if (i.id === res.data?.id) {
              i.title = res.data?.title
              i.detail = res.data?.detail
              i.categoryId = res.data?.categoryId
              i.redeemPoint = res.data?.redeemPoint
              i.shopType = res.data?.redeemAble
              i.isLimitQty = res.data?.isLimitQty
              i.limitedQty = res.data?.limitedQty
              i.image = res.data?.image
            }
          })
          const alert = new AlertMessage(REWARD_MESSAGE.REWARD.EDIT.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
      /* istanbul ignore else */
      if (error) alert = new AlertMessage(REWARD_MESSAGE.REWARD.EDIT.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  },
  async EDIT_REWARD_CATEGORY(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      // Generate Form
      const formData = new FormData()
      formData.append('title', get(data, 'formData.title'))
      formData.append('type', get(data, 'formData.type'))

      const res = await REQUEST_PUT('/reward_categories/' + get(data, 'formData.editId'), formData)
      // console.log(res);
      switch (get(res, 'status')) {
        case 201: {
          data.toggleLoading()
          forEach(state.rewardCategoryList, i => {
            /* istanbul ignore else */
            if (i.id === res.data?.id) {
              i.title = res.data?.title
            }
          })
          const alert = new AlertMessage(REWARD_MESSAGE.CATEGORY.EDIT.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
      /* istanbul ignore else */
      if (error) alert = new AlertMessage(REWARD_MESSAGE.CATEGORY.EDIT.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  },
  async REMOVE_REWARD(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      /* istanbul ignore next */
      if (get(data, 'paramId') === undefined) router.go(0)
      const res = await REQUEST_DELETE('/rewards/' + get(data, 'paramId'))
      // console.log(res);
      switch (get(res, 'status')) {
        case 200: {
          const rewardList = state.rewardList
          const arrayList: Array<RewardCategory> = []
          const deleteIndex = findIndex(rewardList, { id: data.paramId })
          /* istanbul ignore next */
          forEach(rewardList, (i, index) => {
            if (parseInt(index) !== deleteIndex) {
              arrayList.push({ ...i, total: (get(i, 'total', 0) - 1) < 0 ? 0 : get(i, 'total', 0) - 1 })
            }
          })
          state.rewardList = arrayList
          data.toggleLoading()
          const alert = new AlertMessage(REWARD_MESSAGE.CATEGORY.DELETE.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
      /* istanbul ignore else */
      if (error) alert = new AlertMessage(REWARD_MESSAGE.CATEGORY.DELETE.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  },
  async REMOVE_REWARD_CATEGORY(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      /* istanbul ignore next */
      if (get(data, 'paramId') === undefined) router.go(0)
      const res = await REQUEST_DELETE('/reward_categories/' + get(data, 'paramId'))
      // console.log(res);
      switch (get(res, 'status')) {
        case 200: {
          const rewardCategoryList = state.rewardCategoryList
          const arrayList: Array<RewardCategory> = []
          const deleteIndex = findIndex(rewardCategoryList, { id: data.paramId })
          /* istanbul ignore next */
          forEach(rewardCategoryList, (i, index) => {
            if (parseInt(index) !== deleteIndex) {
              arrayList.push({ ...i, total: (get(i, 'total', 0) - 1) < 0 ? 0 : get(i, 'total', 0) - 1 })
            }
          })
          state.rewardCategoryList = arrayList
          data.toggleLoading()
          const alert = new AlertMessage(REWARD_MESSAGE.CATEGORY.DELETE.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
      /* istanbul ignore else */
      if (error) alert = new AlertMessage(REWARD_MESSAGE.CATEGORY.DELETE.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  }
}

export const actions = {
  getReward(context: any, searchForm: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    context.commit('GET_REWARD', { toggleLoading, searchForm })
  },
  getRewardCategory(context: any, searchForm: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    context.commit('GET_REWARD_CATEGORY', { toggleLoading, searchForm })
  },
  createReward(context: any, formData: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm: AlertMessage) => context.dispatch('toggleAlertMessage', alertForm)
    context.commit('CREATE_REWARD', { toggleLoading, formData, toggleAlert })
  },
  createRewardCategory(context: any, formData: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm: AlertMessage) => context.dispatch('toggleAlertMessage', alertForm)
    context.commit('CREATE_REWARD_CATEGORY', { toggleLoading, formData, toggleAlert })
  },
  editReward(context: any, formData: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm: AlertMessage) => context.dispatch('toggleAlertMessage', alertForm)
    context.commit('EDIT_REWARD', { toggleLoading, formData, toggleAlert })
  },
  editRewardCategory(context: any, formData: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm: AlertMessage) => context.dispatch('toggleAlertMessage', alertForm)
    context.commit('EDIT_REWARD_CATEGORY', { toggleLoading, formData, toggleAlert })
  },
  removeReward(context: any, paramId: number) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm: AlertMessage) => context.dispatch('toggleAlertMessage', alertForm)
    context.commit('REMOVE_REWARD', { toggleLoading, paramId, toggleAlert })
  },
  removeRewardCategory(context: any, paramId: number) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm: AlertMessage) => context.dispatch('toggleAlertMessage', alertForm)
    context.commit('REMOVE_REWARD_CATEGORY', { toggleLoading, paramId, toggleAlert })
  }
}

export const getters = {
  // Add Logic Before Computed
  rewardList(state: any): RewardList | undefined { // eslint-disable-line
    return state.rewardList
  },
  rewardCategoryList(state: any): RewardCategoryList | undefined { // eslint-disable-line
    return state.rewardCategoryList
  },
}

export const reward: Module<RewardState, RootState> = {
  state,
  mutations,
  actions,
  getters
}