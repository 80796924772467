import { Module } from 'vuex'
import { findIndex, forEach, get, size } from 'lodash'

import { RootState, BannerState, AlertMessage, Banner } from '@/type'
import { REQUEST_GET, REQUEST_DELETE, REQUEST_POST } from './API_Request'
import router from '@/router'
import { BannerList } from '@/type/Banner'
import { errorMessage } from '@/plugins/common'
import { BANNER_MESSAGE, GLOBAL_MESSAGE } from '@/plugins/message'

export const state = {
  bannerList: Array<Banner>()
}

export const mutations = {
  async GET_BANNER(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      const res = await REQUEST_GET('/banners', data.searchForm)
      // console.log(res);
      const bannerList = get(res, 'data.data')
      if (size(bannerList) > 0) {
        const arrayList: Array<Banner> = []
        forEach(bannerList, i => {
          arrayList.push({ ...i, total: get(res, 'data.total', bannerList.length), perPage: get(res, 'data.perPage') })
        })
        state.bannerList = arrayList
      } else {
        state.bannerList = []
      }
      // console.log(state);
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async CREATE_BANNER(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      const formData = new FormData()
      formData.append('name', get(data, 'formData.name'))
      formData.append('image', data.formData?.image)
      formData.append('isPublic', get(data, 'formData.isPublic'))
      formData.append('order', get(data, 'formData.order'))

      const res = await REQUEST_POST('/banners', formData)
      // console.log(res);
      switch (get(res, 'status')) {
        case 201: {
          state.bannerList?.push(get(res, 'data'))
          const arrayList: Array<Banner> = []
          forEach(state.bannerList, (i) => {
            arrayList.push({ ...i, total: get(i, 'total', 0) + 1 })
          })
          state.bannerList = arrayList
          data.toggleLoading()
          const alert = new AlertMessage(BANNER_MESSAGE.CREATE.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
      /* istanbul ignore else */
      if (error) alert = new AlertMessage(BANNER_MESSAGE.CREATE.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  },
  async REMOVE_BANNER(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      // console.log(data.paramId);
      /* istanbul ignore next */
      if (get(data, 'paramId') === undefined) router.go(0)
      const res = await REQUEST_DELETE('/banners/' + get(data, 'paramId'))
      // console.log(res);
      switch (get(res, 'status')) {
        case 200: {
          const bannerList = state.bannerList
          const arrayList: Array<Banner> = []
          const deleteIndex = findIndex(bannerList, { id: data.paramId })
          forEach(bannerList, (i, index) => {
            /* istanbul ignore else */
            if (parseInt(index) !== deleteIndex) {
              arrayList.push({ ...i, total: (get(i, 'total', 0) - 1) < 0 ? 0 : get(i, 'total', 0) - 1 })
            }
          })
          state.bannerList = arrayList
          data.toggleLoading()
          const alert = new AlertMessage(BANNER_MESSAGE.DELETE.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
      /* istanbul ignore else */
      if (error) alert = new AlertMessage(BANNER_MESSAGE.DELETE.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  }
}

export const actions = {
  getBanner(context: any, searchForm: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    context.commit('GET_BANNER', { toggleLoading, searchForm })
  },
  createBanner(context: any, formData: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm: AlertMessage) => context.dispatch('toggleAlertMessage', alertForm)
    context.commit('CREATE_BANNER', { toggleLoading, formData, toggleAlert })
  },
  removeBanner(context: any, paramId: number) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm: AlertMessage) => context.dispatch('toggleAlertMessage', alertForm)
    context.commit('REMOVE_BANNER', { toggleLoading, paramId, toggleAlert })
  }
}

export const getters = {
  // Add Logic Before Computed
  bannerList(state: any): BannerList | undefined { // eslint-disable-line
    return state.bannerList
  },
}

export const banners: Module<BannerState, RootState> = {
  state,
  mutations,
  actions,
  getters
}