import { Module } from 'vuex'
import moment from 'moment'
import { forEach, get, size, findIndex } from 'lodash'

import { RootState, LeaderBoardState, AlertMessage, HeroReward, HeroLeader, HeroWinner } from '@/type'
import { REQUEST_GET, REQUEST_DELETE, REQUEST_POST, REQUEST_PUT } from './API_Request'
import router from '@/router'
import { HeroRewardList, HeroLeaderList } from '@/type/LeaderBoard'
import { errorMessage } from '@/plugins/common'
import { GLOBAL_MESSAGE, HERO_MESSAGE } from '@/plugins/message'
import { HeroWinnerList } from '@/type/Winner'

export const state = {
  heroRewardList: Array<HeroReward>(),
  heroLeaderList: Array<HeroLeader>(),
  heroWinnerList: Array<HeroWinner>()
}

export const mutations = {
  async GET_HERO_REWARD(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      const res = await REQUEST_GET('/leaderboard/rewards', data.searchForm)
      // console.log(res);
      const heroRewardList = get(res, 'data.data')
      if (size(heroRewardList) > 0) {
        const arrayList: Array<HeroReward> = []
        forEach(heroRewardList, i => {
          arrayList.push({ ...i, total: get(res, 'data.total', heroRewardList.length), perPage: get(res, 'data.perPage') })
        })
        state.heroRewardList = arrayList
      } else {
        state.heroRewardList = []
      }
      // console.log(state);
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async GET_HERO_WINNER(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      const res = await REQUEST_GET('/leaderboard/winners', data.searchForm)
      // console.log(res);
      const heroLeaderList = get(res, 'data.data')
      if (size(heroLeaderList) > 0) {
        const arrayList: Array<HeroLeader> = []
        forEach(heroLeaderList, i => {
          arrayList.push({ ...i, total: get(res, 'data.total', heroLeaderList.length), perPage: get(res, 'data.perPage') })
        })
        state.heroLeaderList = arrayList
      } else {
        state.heroLeaderList = []
      }
      // console.log(state);
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async GET_HERO_WINNER_DETAIL(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      const res = await REQUEST_GET(`/leaderboard/winners/${data.paramId}/detail`, data.searchForm)
      const heroWinnerList = get(res, 'data.data')
      if (size(heroWinnerList) > 0) {
        const arrayList: Array<HeroWinner> = []
        forEach(heroWinnerList, i => {
          arrayList.push({ ...i, total: get(res, 'data.total', heroWinnerList.length), perPage: get(res, 'data.perPage') })
        })
        state.heroWinnerList = arrayList
      } else {
        state.heroWinnerList = []
      }
      // console.log(state);
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async CREATE_HERO_REWARD(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      const formData = new FormData()
      formData.append('startDate', data.formData?.startDate)
      formData.append('endDate', data.formData?.endDate)
      formData.append('description', get(data, 'formData.description'))
      formData.append('level', data.formData?.level)
      const res = await REQUEST_POST('/leaderboard/rewards', formData)
      // console.log(res);
      switch (get(res, 'status')) {
        case 201: {
          state.heroRewardList?.push(get(res, 'data'))
          const arrayList: Array<HeroReward> = []
          forEach(state.heroRewardList, (i) => {
            arrayList.push({ ...i, total: get(i, 'total', 0) + 1 })
          })
          state.heroRewardList = arrayList
          data.toggleLoading()
          const alert = new AlertMessage(HERO_MESSAGE.REWARD.CREATE.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
      /* istanbul ignore else */
      if (error) alert = new AlertMessage(HERO_MESSAGE.REWARD.CREATE.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  },
  async CREATE_HERO_WINNER(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      const formData = new FormData()
      formData.append('startDate', data.formData?.startDate)
      formData.append('endDate', data.formData?.endDate)
      formData.append('title', get(data, 'formData.title'))
      formData.append('level', data.formData?.level)
      const res = await REQUEST_POST('/leaderboard/winners', formData)
      // console.log(res);
      switch (get(res, 'status')) {
        case 201: {
          state.heroLeaderList?.push(get(res, 'data'))
          const arrayList: Array<HeroLeader> = []
          forEach(state.heroLeaderList, (i) => {
            arrayList.push({ ...i, total: get(i, 'total', 0) + 1 })
          })
          state.heroLeaderList = arrayList
          data.toggleLoading()
          const alert = new AlertMessage(HERO_MESSAGE.WINNER.CREATE.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
      /* istanbul ignore else */
      if (error) alert = new AlertMessage(HERO_MESSAGE.WINNER.CREATE.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  },
  async CREATE_HERO_WINNER_DETAIL(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      const formData = new FormData()
      formData.append('order', data.formData?.order)
      formData.append('employeeCode', get(data, 'formData.employeeCode'))
      formData.append('employeeName', get(data, 'formData.employeeName'))
      formData.append('point', data.formData?.point)
      formData.append('territoryCode', get(data, 'formData.territoryCode'))
      formData.append('percentage', data.formData?.percentage)
      const res = await REQUEST_POST(`/leaderboard/winners/${data.formData?.winnerId}/detail`, formData)
      // console.log(res);
      switch (get(res, 'status')) {
        case 201: {
          state.heroWinnerList?.push(get(res, 'data'))
          const arrayList: Array<HeroWinner> = []
          forEach(state.heroWinnerList, (i) => {
            arrayList.push({ ...i, total: get(i, 'total', 0) + 1 })
          })
          state.heroWinnerList = arrayList
          data.toggleLoading()
          const alert = new AlertMessage(HERO_MESSAGE.WINNER.CREATE.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
      /* istanbul ignore else */
      if (error) alert = new AlertMessage(HERO_MESSAGE.WINNER.CREATE.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  },
  async EDIT_HERO_REWARD(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      // Generate Form
      const formData = new FormData()
      formData.append('startDate', data.formData?.startDate)
      formData.append('endDate', data.formData?.endDate)
      formData.append('description', get(data, 'formData.description'))
      formData.append('level', data.formData?.level)

      const res = await REQUEST_PUT('/leaderboard/rewards/' + get(data, 'formData.editId'), formData)
      // console.log(res);
      switch (get(res, 'status')) {
        case 201: {
          data.toggleLoading()
          forEach(state.heroRewardList, i => {
            /* istanbul ignore else */
            if (i.id === res.data?.id) {
              i.startDate = res.data?.startDate
              i.endDate = res.data?.endDate
              i.description = res.data?.description
              i.level = res.data?.level
            }
          })
          const alert = new AlertMessage(HERO_MESSAGE.REWARD.EDIT.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
      /* istanbul ignore else */
      if (error) alert = new AlertMessage(HERO_MESSAGE.REWARD.EDIT.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  },
  async EDIT_HERO_WINNER(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      // Generate Form
      const formData = new FormData()
      formData.append('startDate', data.formData?.startDate)
      formData.append('endDate', data.formData?.endDate)
      formData.append('title', get(data, 'formData.title'))
      formData.append('level', data.formData?.level)

      const res = await REQUEST_PUT('/leaderboard/winners/' + get(data, 'formData.editId'), formData)
      // console.log(res);
      switch (get(res, 'status')) {
        case 201: {
          data.toggleLoading()
          forEach(state.heroLeaderList, i => {
            /* istanbul ignore else */
            if (i.id === res.data?.id) {
              i.startDate = res.data?.startDate
              i.endDate = res.data?.endDate
              i.title = res.data?.title
              i.level = res.data?.level
            }
          })
          const alert = new AlertMessage(HERO_MESSAGE.WINNER.EDIT.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
      /* istanbul ignore else */
      if (error) alert = new AlertMessage(HERO_MESSAGE.WINNER.EDIT.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  },
  async EDIT_HERO_WINNER_DETAIL(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      // Generate Form
      const formData = new FormData()
      formData.append('order', data.formData?.order)
      formData.append('employeeCode', get(data, 'formData.employeeCode'))
      formData.append('employeeName', get(data, 'formData.employeeName'))
      formData.append('point', data.formData?.point)
      formData.append('territoryCode', get(data, 'formData.territoryCode'))
      formData.append('percentage', data.formData?.percentage)
      const res = await REQUEST_PUT(`/leaderboard/winners/${data.formData?.winnerId}/detail/${get(data, 'formData.editId')}`, formData)
      // console.log(res);
      switch (get(res, 'status')) {
        case 201: {
          data.toggleLoading()
          forEach(state.heroWinnerList, i => {
            /* istanbul ignore else */
            if (i.id === res.data?.id) {
              i.order = res.data?.order
              i.employeeCode = res.data?.employeeCode
              i.employeeName = res.data?.employeeName
              i.point = res.data?.point
              i.territoryCode = res.data?.territoryCode
              i.percentage = res.data?.percentage
            }
          })
          const alert = new AlertMessage(HERO_MESSAGE.WINNER.EDIT.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
      /* istanbul ignore else */
      if (error) alert = new AlertMessage(HERO_MESSAGE.WINNER.EDIT.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  },
  async REMOVE_HERO_REWARD(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      /* istanbul ignore next */
      if (get(data, 'paramId') === undefined) router.go(0)
      const res = await REQUEST_DELETE('/leaderboard/rewards/' + get(data, 'paramId'))
      // console.log(res);
      switch (get(res, 'status')) {
        case 204: {
          const heroRewardList = state.heroRewardList
          const arrayList: Array<HeroReward> = []
          const deleteIndex = findIndex(heroRewardList, { id: data.paramId })
          /* istanbul ignore next */
          forEach(heroRewardList, (i, index) => {
            if (parseInt(index) !== deleteIndex) {
              arrayList.push({ ...i, total: (get(i, 'total', 0) - 1) < 0 ? 0 : get(i, 'total', 0) - 1 })
            }
          })
          state.heroRewardList = arrayList
          data.toggleLoading()
          const alert = new AlertMessage(HERO_MESSAGE.REWARD.DELETE.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
      /* istanbul ignore else */
      if (error) alert = new AlertMessage(HERO_MESSAGE.REWARD.DELETE.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  },
  async REMOVE_HERO_WINNER(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      /* istanbul ignore next */
      if (get(data, 'paramId') === undefined) router.go(0)
      const res = await REQUEST_DELETE('/leaderboard/winners/' + get(data, 'paramId'))
      // console.log(res);
      switch (get(res, 'status')) {
        case 204: {
          const heroLeaderList = state.heroLeaderList
          const arrayList: Array<HeroReward> = []
          const deleteIndex = findIndex(heroLeaderList, { id: data.paramId })
          /* istanbul ignore next */
          forEach(heroLeaderList, (i, index) => {
            if (parseInt(index) !== deleteIndex) {
              arrayList.push({ ...i, total: (get(i, 'total', 0) - 1) < 0 ? 0 : get(i, 'total', 0) - 1 })
            }
          })
          state.heroLeaderList = arrayList
          data.toggleLoading()
          const alert = new AlertMessage(HERO_MESSAGE.WINNER.DELETE.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
      /* istanbul ignore else */
      if (error) alert = new AlertMessage(HERO_MESSAGE.WINNER.DELETE.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  },
  async REMOVE_HERO_WINNER_DETAIL(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      /* istanbul ignore next */
      if (get(data, 'formData.deleteId') === undefined) router.go(0)
      const res = await REQUEST_DELETE(`/leaderboard/winners/${data.formData?.winnerId}/detail/${get(data, 'formData.deleteId')}`)
      // console.log(res);
      switch (get(res, 'status')) {
        case 204: {
          const heroWinnerList = state.heroWinnerList
          const arrayList: Array<HeroWinner> = []
          const deleteIndex = findIndex(heroWinnerList, { id: data.formData?.deleteId })
          /* istanbul ignore next */
          forEach(heroWinnerList, (i, index) => {
            if (parseInt(index) !== deleteIndex) {
              arrayList.push({ ...i, total: (get(i, 'total', 0) - 1) < 0 ? 0 : get(i, 'total', 0) - 1 })
            }
          })
          state.heroWinnerList = arrayList
          data.toggleLoading()
          const alert = new AlertMessage(HERO_MESSAGE.WINNER.DELETE.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
      /* istanbul ignore else */
      if (error) alert = new AlertMessage(HERO_MESSAGE.WINNER.DELETE.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  },
}

export const actions = {
  getHeroReward(context: any, searchForm: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    context.commit('GET_HERO_REWARD', { toggleLoading, searchForm })
  },
  getHeroWinner(context: any, searchForm: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    context.commit('GET_HERO_WINNER', { toggleLoading, searchForm })
  },
  getHeroWinnerDetail(context: any, paramId: number) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    context.commit('GET_HERO_WINNER_DETAIL', { toggleLoading, paramId })
  },
  createHeroReward(context: any, formData: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm: AlertMessage) => context.dispatch('toggleAlertMessage', alertForm)
    context.commit('CREATE_HERO_REWARD', { toggleLoading, formData, toggleAlert })
  },
  createHeroWinner(context: any, formData: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm: AlertMessage) => context.dispatch('toggleAlertMessage', alertForm)
    context.commit('CREATE_HERO_WINNER', { toggleLoading, formData, toggleAlert })
  },
  createHeroWinnerDetail(context: any, formData: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm: AlertMessage) => context.dispatch('toggleAlertMessage', alertForm)
    context.commit('CREATE_HERO_WINNER_DETAIL', { toggleLoading, formData, toggleAlert })
  },
  editHeroReward(context: any, formData: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm: AlertMessage) => context.dispatch('toggleAlertMessage', alertForm)
    context.commit('EDIT_HERO_REWARD', { toggleLoading, formData, toggleAlert })
  },
  editHeroWinner(context: any, formData: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm: AlertMessage) => context.dispatch('toggleAlertMessage', alertForm)
    context.commit('EDIT_HERO_WINNER', { toggleLoading, formData, toggleAlert })
  },
  editHeroWinnerDetail(context: any, formData: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm: AlertMessage) => context.dispatch('toggleAlertMessage', alertForm)
    context.commit('EDIT_HERO_WINNER_DETAIL', { toggleLoading, formData, toggleAlert })
  },
  removeHeroReward(context: any, paramId: number) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm: AlertMessage) => context.dispatch('toggleAlertMessage', alertForm)
    context.commit('REMOVE_HERO_REWARD', { toggleLoading, paramId, toggleAlert })
  },
  removeHeroWinner(context: any, paramId: number) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm: AlertMessage) => context.dispatch('toggleAlertMessage', alertForm)
    context.commit('REMOVE_HERO_WINNER', { toggleLoading, paramId, toggleAlert })
  },
  removeHeroWinnerDetail(context: any, formData: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm: AlertMessage) => context.dispatch('toggleAlertMessage', alertForm)
    context.commit('REMOVE_HERO_WINNER_DETAIL', { toggleLoading, formData, toggleAlert })
  },
}

export const getters = {
  // Add Logic Before Computed
  heroRewardList(state: any): HeroRewardList | undefined { // eslint-disable-line
    return state.heroRewardList
  },
  heroLeaderList(state: any): HeroLeaderList | undefined { // eslint-disable-line
    const { heroLeaderList } = state
    forEach(heroLeaderList, i => {
      const start = moment(i.startDate).diff(moment(), 'days')
      const end = moment(i.endDate).diff(moment(), 'days')
      i.status = start <= 0 && end >= 0 ? true : false
    })
    return heroLeaderList
  },
  heroWinnerList(state: any): HeroWinnerList | undefined { // eslint-disable-line
    return state.heroWinnerList
  },
}

export const leaderBoard: Module<LeaderBoardState, RootState> = {
  state,
  mutations,
  actions,
  getters
}