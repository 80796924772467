
import moment from "moment";
import { Component, Vue } from "vue-property-decorator";
import { get, findIndex, forEach, size } from "lodash";
import Utils from "@/mixin";
import { itemsPerPageOptions } from "@/plugins/common";

import CreateEmployee from "./HeroAnnouncement/Employee.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import { HeroLeader } from "@/type";

@Component({
  components: {
    CreateEmployee,
    ConfirmDialog,
  },
  computed: {
    announceDate() {
      const { heroLeaderList } = this;
      const employeeId = get(this, "$route.params.employeeId");
      const index = findIndex(
        heroLeaderList,
        (i: HeroLeader) => i.id === employeeId
      );
      return moment(heroLeaderList[index]?.startDate).format("DD/MM/YYYY");
    },
    level() {
      const { heroLeaderList } = this;
      const employeeId = get(this, "$route.params.employeeId");
      const index = findIndex(
        heroLeaderList,
        (i: HeroLeader) => i.id === employeeId
      );
      return heroLeaderList[index]?.level;
    },
    winnerId() {
      return get(this, "$route.params.employeeId");
    },
  },
  mixins: [Utils],
  mounted() {
    const { heroLeaderList } = this;
    const employeeId = get(this, "$route.params.employeeId");
    if (employeeId === undefined || size(heroLeaderList) === 0) {
      this.$router.push({ name: "Hero-Announcement-Camp" });
    } else {
      this.breadcrumbItems.push({
        text: "Employee",
        disabled: true,
        href: "/hero-announcement-camp/" + employeeId,
      });
      this.getInitialData();
      this.itemsPerPageOptions = itemsPerPageOptions;
    }
  },
  watch: {
    // keyword(nextProps, prevProps) {
    //   /* istanbul ignore else */
    //   if (nextProps !== prevProps) {
    //     this.getInitialData();
    //   }
    // },
    itemPerPage(nextProps, prevProps) {
      /* istanbul ignore else */
      if (nextProps !== prevProps) {
        this.getInitialData();
      }
    },
    page(nextProps, prevProps) {
      /* istanbul ignore else */
      if (nextProps !== prevProps) {
        this.getInitialData();
      }
    },
    /* istanbul ignore next */
    heroWinnerList(nextProps, prevProps) {
      if (nextProps !== prevProps) {
        forEach(this.heroWinnerList, (i) => {
          this.total = i.total;
          // this.itemPerPage = i.perPage;
        });
      }
    },
  },
})
export default class HeroAnnouncementEmployee extends Vue {
  editId = "";
  deleteId = -1;
  confirmDialog = false;
  page = 1;
  total = 0;
  itemsPerPageOptions = [];
  itemPerPage = 10;
  keyword = "";
  dialog = false;
  breadcrumbItems = [
    {
      text: "Announcement",
      disabled: false,
      href: "/hero-announcement-camp",
    },
  ];
  headers = [
    { text: "Order", value: "order", align: "start", sortable: false },
    {
      text: "Code",
      value: "employeeCode",
      align: "start",
      sortable: false,
    },
    {
      text: "Full Name",
      value: "employeeName",
      sortable: false,
    },
    {
      text: "Territory Code",
      value: "territoryCode",
      sortable: false,
    },
    {
      text: "Point",
      value: "point",
      align: "center",
      sortable: false,
    },
    {
      text: "% AOP",
      value: "percentage",
      align: "center",
      sortable: false,
    },
    { text: "Actions", value: "actions", sortable: false, width: "100px" },
  ];

  getHeroWinnerDetail!: (paramId: number) => void;
  removeHeroWinnerDetail!: (formData: object) => void;
  // eslint-disable-next-line
  onKeyDown(e: any) {
    const { keyCode } = e;
    if (keyCode === 13) {
      this.getInitialData();
    }
  }

  getInitialData() {
    const employeeId = get(this, "$route.params.employeeId");
    this.getHeroWinnerDetail(employeeId);
  }

  setInitialDialog() {
    this.editId = "";
  }

  toggleDialog() {
    this.dialog = !this.dialog;
  }

  toggleConfirmModal(paramId: number) {
    this.deleteId = paramId;
    this.confirmDialog = !this.confirmDialog;
  }

  editItem(paramId: string) {
    this.editId = paramId;
    this.toggleDialog();
  }

  cancel() {
    this.deleteId = -1;
    this.confirmDialog = !this.confirmDialog;
  }

  actionItem() {
    const { deleteId } = this;
    const employeeId = get(this, "$route.params.employeeId");
    this.removeHeroWinnerDetail({ winnerId: employeeId, deleteId });
    this.cancel();
  }
}
