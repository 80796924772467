
import { Component, Ref, Vue } from "vue-property-decorator";
import { findIndex, forEach, get, isEmpty } from "lodash";
import Utils from "@/mixin";
import { Reward, VForm } from "@/type";

@Component({
  props: {
    editId: {
      type: [Number, String],
      default: "",
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    toggleDialog: {
      type: Function,
      // eslint-disable-next-line
      default: /* istanbul ignore next */ () => {},
    },
    setInitialDialog: {
      type: Function,
      // eslint-disable-next-line
      default: /* istanbul ignore next */ () => {},
    },
  },
  mixins: [Utils],
  computed: {
    validate() {
      const { title, detail, categoryId, redeemPoint, image } = this.formData;
      if (
        (this.editId === "" &&
          (isEmpty(title) ||
            get(detail, "length") === 0 ||
            categoryId === "" ||
            redeemPoint === 0 ||
            image === undefined)) ||
        (this.editId !== "" &&
          (isEmpty(title) ||
            get(detail, "length") === 0 ||
            categoryId === "" ||
            redeemPoint === 0))
      ) {
        return true;
      } else {
        return false;
      }
    },
    categoryItems() {
      const arrayList: any = []; // eslint-disable-line
      forEach(this.rewardCategoryList, (i) => {
        arrayList.push({ text: i.title, value: i.id });
      });
      return arrayList;
    },
  },
  mounted() {
    this.getInitialData();
  },
  watch: {
    editId(nextProps, prevProps) {
      /* istanbul ignore else */
      if (prevProps !== nextProps && nextProps !== "") {
        this.modalTitle = "Edit";
        const { rewardList } = this;
        const index = findIndex(rewardList, (i: Reward) => i.id === nextProps);
        const data = rewardList[index];
        this.formData = {
          title: data.title,
          detail: data.detail,
          categoryId: data.categoryId,
          redeemPoint: data.redeemPoint,
          redeemAble: data.redeemAble,
          isLimitQty: data.isLimitQty,
          limitedQty: data.limitedQty,
        };
      }
    },
  },
})
export default class RewardForm extends Vue {
  modalTitle = "Create";
  formData = {
    title: "",
    detail: "",
    categoryId: "",
    redeemPoint: 0,
    redeemAble: false,
    isLimitQty: false,
    limitedQty: 0,
    image: undefined,
  };
  initialForm = {
    title: "",
    detail: "",
    categoryId: "",
    redeemPoint: 0,
    redeemAble: false,
    isLimitQty: false,
    limitedQty: 0,
    image: undefined,
  };
  radioItems = [
    { text: "Yes", value: true },
    { text: "No", value: false },
  ];
  /* istanbul ignore next */
  rules = {
    required: (value: [number, string]) => !!value || "Required.",
    min: (value: number) => value > 0 || "Point must more than 0",
  };

  @Ref("form") readonly form!: VForm;
  getRewardCategory!: (searchForm?: object) => void;
  createReward!: (formData: object) => void;
  editReward!: (formData: object) => void;
  toggleDialog!: () => void;

  getInitialData() {
    const searchForm = {
      type: "employee",
    };
    this.getRewardCategory(searchForm);
  }

  clearData() {
    if (this.$props.editId !== "") this.$props.setInitialDialog();
    this.toggleDialog();
    this.$nextTick(() => {
      this.modalTitle = "Create";
      this.formData = Object.assign({}, this.initialForm);
      /* istanbul ignore next */
      if (this.form) this.form.resetValidation();
    });
  }

  submitForm() {
    const { formData } = this;
    if (this.$props.editId !== "") {
      const form = {
        ...formData,
        type: "employee",
        editId: this.$props.editId,
      };
      this.editReward(form);
    } else {
      const form = {
        ...formData,
        type: "employee",
      };
      this.createReward(form);
    }
    this.clearData();
  }
}
