
import { Component, Vue } from "vue-property-decorator";
import { forEach } from "lodash";
import Utils from "@/mixin";
import { Employee as EmployeeType } from "@/type";
import { itemsPerPageOptions } from "@/plugins/common";

import ConfirmDialog from "@/components/ConfirmDialog.vue";

@Component({
  components: {
    ConfirmDialog,
  },
  mixins: [Utils],
  mounted() {
    // console.log(this);
    this.getInitialData();
    this.itemsPerPageOptions = itemsPerPageOptions;
  },
  computed: {
    generateTable() {
      if (this.employeeList !== undefined) {
        const { employeeList } = this;
        const tableList: EmployeeType[] = [];
        forEach(employeeList, (i) => {
          const employee = new EmployeeType();
          employee.id = i.id;
          employee.userId = i.userId;
          employee.code = i.code;
          employee.fullName = i.fullName;
          employee.roleTitle = i.roleTitle;
          employee.territoryCode = i.territoryCode;
          employee.phone = i.phone;
          // employee.organizationChart = i.organizationChart;
          employee.saleType = i.saleType;
          employee.status = i.status;
          employee.level = i.level;
          employee.points = i.points;
          employee.totalPoint = i.totalPoint;
          this.total = i.total;
          // this.itemPerPage = i.perPage;
          tableList.push(employee);
        });
        return tableList;
      } else {
        return [];
      }
    },
  },
  watch: {
    // keyword(nextProps, prevProps) {
    //   /* istanbul ignore else */
    //   if (nextProps !== prevProps) {
    //     this.getInitialData();
    //   }
    // },
    itemPerPage(nextProps, prevProps) {
      /* istanbul ignore else */
      if (nextProps !== prevProps) {
        this.getInitialData();
      }
    },
    page(nextProps, prevProps) {
      /* istanbul ignore else */
      if (nextProps !== prevProps) {
        this.getInitialData();
      }
    },
  },
})
export default class LineUser extends Vue {
  actionCode = '';
  syncModal = false;
  unlinkModal = false;
  page = 1;
  total = 0;
  itemsPerPageOptions = [];
  itemPerPage = 10;
  keyword = "";
  headers = [
    { text: "Code", value: "code", sortable: false },
    {
      text: "Full Name",
      value: "fullName",
      align: "start",
      sortable: false,
      width: "250px",
    },
    { text: "Role Title", value: "roleTitle", width: "150px", sortable: false },
    { text: "Territory Code", value: "territoryCode", sortable: false },
    { text: "Phone Number", value: "phone", sortable: false },
    // { text: "Organization Chart", value: "organizationChart" },
    { text: "Sale Type", value: "saleType", sortable: false },
    { text: "Status", value: "status", sortable: false },
    { text: "Level", value: "level", align: "center", sortable: false },
    { text: "Points", value: "points", align: "center", sortable: false },
    {
      text: "Total Point",
      value: "totalPoint",
      align: "center",
      sortable: false,
    },
    { text: "Actions", value: "actions", sortable: false },
  ];

  getEmployee!: (searchForm?: object) => void;
  syncEmployee!: (code?: string) => void;
  unlinkEmployee!: (code?: string) => void;
  // eslint-disable-next-line
  onKeyDown(e: any) {
    const { keyCode } = e;
    if (keyCode === 13) {
      this.getInitialData();
    }
  }

  getInitialData() {
    const { page, itemPerPage, keyword } = this;
    const searchForm = {
      page,
      perPage: itemPerPage,
      keyword,
    };
    this.getEmployee(searchForm);
  }

  toggleSyncModal(code: string) {
    this.actionCode = code;
    this.syncModal = !this.syncModal;
  }

  toggleUnlinkModal(code: string) {
    this.actionCode = code;
    this.unlinkModal = !this.unlinkModal;
  }

  cancel() {
    this.actionCode = '';
    this.syncModal = false;
    this.unlinkModal = false;
  }

  syncItem() {
    const { actionCode } = this;
    this.syncEmployee(actionCode);
    this.cancel();
  }

  unlinkItem() {
    const { actionCode } = this;
    this.unlinkEmployee(actionCode);
    this.cancel();
  }
}
