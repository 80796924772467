import { Module } from 'vuex'
import { forEach, get, size, findIndex } from 'lodash'

import { RootState, UserState, AlertMessage, User, LineUser } from '@/type'
import { REQUEST_GET, REQUEST_DELETE, REQUEST_POST } from './API_Request'
import router from '@/router'
import { UserList } from '@/type/User'
import { errorMessage } from '@/plugins/common'
import { GLOBAL_MESSAGE, USER_MEESAGE } from '@/plugins/message'

export const state = {
  userList: Array<User>(),
  lineUserList: Array<LineUser>()
}

export const mutations = {
  async GET_USER(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      const res = await REQUEST_GET('/users/admin', data.searchForm)
      // console.log(res);
      const userList = get(res, 'data.data')
      if (size(userList) > 0) {
        const arrayList: Array<User> = []
        forEach(userList, i => {
          arrayList.push({ ...i, total: get(res, 'data.total', userList.length), perPage: get(res, 'data.perPage') })
        })
        state.userList = arrayList
      } else {
        state.userList = []
      }
      // console.log(state);
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async GET_LINE_USER(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      const res = await REQUEST_GET('/users', data.searchForm)
      // console.log(res);
      const lineUserList = get(res, 'data.data')
      if (size(lineUserList) > 0) {
        const arrayList: Array<LineUser> = []
        forEach(lineUserList, i => {
          arrayList.push({ ...i, total: get(res, 'data.total', lineUserList.length), perPage: get(res, 'data.perPage') })
        })
        state.lineUserList = arrayList
      } else {
        state.lineUserList = []
      }
      // console.log(state);
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async CREATE_USER(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      const res = await REQUEST_POST('/users', data.formData)
      // console.log(res);
      switch (get(res, 'status')) {
        case 201: {
          state.userList?.push(get(res, 'data.data'))
          const arrayList: Array<User> = []
          /* istanbul ignore next */
          forEach(state.userList, (i) => {
            arrayList.push({ ...i, total: get(i, 'total', 0) + 1 })
          })
          state.userList = arrayList
          data.toggleLoading()
          const alert = new AlertMessage(USER_MEESAGE.CREATE.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
      /* istanbul ignore else */
      if (error) alert = new AlertMessage(USER_MEESAGE.CREATE.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  },
  async REMOVE_USER(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      // console.log(data.paramId);
      /* istanbul ignore next */
      if (get(data, 'paramId') === undefined) router.go(0)
      const res = await REQUEST_DELETE('/users/' + get(data, 'paramId'))
      // console.log(res);
      switch (get(res, 'status')) {
        case 200: {
          const userList = state.userList
          const arrayList: Array<User> = []
          const deleteIndex = findIndex(userList, { id: data.paramId })
          /* istanbul ignore next */
          forEach(userList, (i, index) => {
            if (parseInt(index) !== deleteIndex) {
              arrayList.push({ ...i, total: (get(i, 'total', 0) - 1) < 0 ? 0 : get(i, 'total', 0) - 1 })
            }
          })
          state.userList = arrayList
          data.toggleLoading()
          const alert = new AlertMessage(USER_MEESAGE.DELETE.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
      /* istanbul ignore else */
      if (error) alert = new AlertMessage(USER_MEESAGE.DELETE.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  }
}

export const actions = {
  getUser(context: any, searchForm: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    context.commit('GET_USER', { toggleLoading, searchForm })
  },
  getLineUser(context: any, searchForm: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    context.commit('GET_LINE_USER', { toggleLoading, searchForm })
  },
  createUser(context: any, formData: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm: AlertMessage) => context.dispatch('toggleAlertMessage', alertForm)
    context.commit('CREATE_USER', { toggleLoading, formData, toggleAlert })
  },
  removeUser(context: any, paramId: number | undefined) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm: AlertMessage) => context.dispatch('toggleAlertMessage', alertForm)
    context.commit('REMOVE_USER', { toggleLoading, paramId, toggleAlert })
  }
}

export const getters = {
  // Add Logic Before Computed
  userList(state: any): UserList | undefined { // eslint-disable-line
    return state.userList
  },
  lineUserList(state: any): UserList | undefined { // eslint-disable-line
    return state.lineUserList
  },
}

export const users: Module<UserState, RootState> = {
  state,
  mutations,
  actions,
  getters
}