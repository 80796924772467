
import { Component, Ref, Vue } from "vue-property-decorator";
import { findIndex, isEmpty, get } from "lodash";
import Utils from "@/mixin";
import { Product, Point, VForm } from "@/type";

@Component({
  props: {
    editId: {
      type: [Number, String],
      default: "",
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    toggleDialog: {
      type: Function,
      // eslint-disable-next-line
      default: /* istanbul ignore next */ () => {},
    },
    setInitialDialog: {
      type: Function,
      // eslint-disable-next-line
      default: /* istanbul ignore next */ () => {},
    },
    pointRuleId: {
      type: [Number, String],
      default: "",
    },
  },
  mixins: [Utils],
  computed: {
    validate() {
      const { name, code, description, point, order, image } = this.formData;
      if (
        (this.editId === "" &&
          (isEmpty(name) ||
            isEmpty(code) ||
            get(description, "length") === 0 ||
            parseInt(point) === 0 ||
            parseInt(order) === 0 ||
            image === undefined)) ||
        (this.editId !== "" &&
          (isEmpty(name) ||
            isEmpty(code) ||
            get(description, "length") === 0 ||
            parseInt(point) === 0 ||
            parseInt(order) === 0))
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    editId(nextProps, prevProps) {
      /* istanbul ignore else */
      if (prevProps !== nextProps && nextProps !== "") {
        this.modalTitle = "Edit";
        const { pointList } = this;
        const index = findIndex(
          pointList,
          (i: Point) => i.id === this.pointRuleId
        );
        /* istanbul ignore else */
        if (index !== -1) {
          const { products } = pointList[index];
          const productIndex = findIndex(
            products,
            (i: Product) => i.id === nextProps
          );
          const data = pointList[index].products[productIndex];
          this.formData = {
            name: data.name,
            code: data.code,
            description: data.description,
            order: data.order,
            point: data.point,
          };
        }
      }
    },
  },
})
export default class ProductForm extends Vue {
  modalTitle = "Create";
  initialForm = {
    name: "",
    code: "",
    description: "",
    image: undefined,
    order: 0,
    point: 0,
  };
  formData = {
    name: "",
    code: "",
    description: "",
    image: undefined,
    order: 0,
    point: 0,
  };
  /* istanbul ignore next */
  rules = {
    required: (value: [number, string]) => !!value || "Required.",
    min: (value: number) => value > 0 || "Must more than 0",
  };

  @Ref("form") readonly form!: VForm;
  createProduct!: (formData: object) => void;
  editProduct!: (formData: object) => void;
  toggleDialog!: () => void;

  clearData() {
    if (this.$props.editId !== "") this.$props.setInitialDialog();
    this.toggleDialog();
    this.$nextTick(() => {
      this.modalTitle = "Create";
      this.formData = Object.assign({}, this.initialForm);
      /* istanbul ignore next */
      if (this.form) this.form.resetValidation();
    });
  }

  submitForm() {
    const { formData } = this;
    if (this.$props.editId !== "") {
      const submitForm = {
        name: get(formData, "name"),
        code: get(formData, "code"),
        description: get(formData, "description"),
        order: get(formData, "order").toString(),
        point: get(formData, "point").toString(),
        image: formData.image,
        id: this.$props.pointRuleId,
        editId: this.$props.editId,
      };
      this.editProduct(submitForm);
    } else {
      const submitForm = {
        name: get(formData, "name"),
        code: get(formData, "code"),
        description: get(formData, "description"),
        order: get(formData, "order").toString(),
        point: get(formData, "point").toString(),
        id: this.$props.pointRuleId,
        image: formData.image,
      };
      this.createProduct(submitForm);
    }
    this.clearData();
  }
}
