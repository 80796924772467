import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import { get } from 'lodash'

/** Sermsuk Family **/
import Home from '@/views/Auth/Home.vue'
import Login from '@/views/Login.vue'
import Banner from '@/views/Auth/Banner.vue'
import Promotion from '@/views/Auth/Promotion.vue'
import Product from '@/views/Auth/Product.vue'
import PointRule from '@/views/Auth/Point.vue'
import User from '@/views/Auth/User.vue'
import LineUser from '@/views/Auth/LineUser.vue'
import LuckyDrawContent from '@/views/Auth/LuckyDrawContent.vue'
import LuckyDrawReward from '@/views/Auth/LuckyDrawReward.vue'
import LuckyDrawWinner from '@/views/Auth/LuckyDrawWinner.vue'
import Reward from '@/views/Auth/Reward.vue'
import RewardCategory from '@/views/Auth/RewardCategory.vue'
import Shop from '@/views/Auth/Shop.vue'
import History from '@/views/Auth/History.vue'
import Calculator from '@/views/Auth/Calculator.vue'
import Brand from '@/views/Auth/Brand.vue'
import NotFound from '@/views/NotFound.vue'

/** Sermsuk Camp **/
import BrandCamp from '@/views/AuthCamp/Brand.vue'
import EmployeeCamp from '@/views/AuthCamp/Employee.vue'
import PromotionCamp from '@/views/AuthCamp/Promotion.vue'
import RewardCamp from '@/views/AuthCamp/Reward.vue'
import RewardCategoryCamp from '@/views/AuthCamp/RewardCategory.vue'
import HeroRewardCamp from '@/views/AuthCamp/HeroReward.vue'
import HeroAnnouncementCamp from '@/views/AuthCamp/HeroAnnouncement.vue'
import HeroAnnouncementEmployeeCamp from '@/views/AuthCamp/HeroAnnouncementEmployee.vue'
import HighlightCamp from '@/views/AuthCamp/Highlight.vue'
import HistoryCamp from '@/views/AuthCamp/History.vue'
import LineUserCamp from '@/views/AuthCamp/LineUser.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    meta: {
      layout: 'authLayout'
    },
    component: Home
  },
  {
    path: '/camp',
    name: 'Home-Camp',
    meta: {
      layout: 'authLayout'
    },
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      layout: 'homeLayout'
    },
    component: Login
  },
  {
    path: '/banners',
    name: 'Banner',
    meta: {
      layout: 'authLayout'
    },
    component: Banner
  },
  {
    path: '/brand',
    name: 'Brand',
    meta: {
      layout: 'authLayout'
    },
    component: Brand
  },
  {
    path: '/brand-camp',
    name: 'Brand-Camp',
    meta: {
      layout: 'authLayout'
    },
    component: BrandCamp
  },
  {
    path: '/employee-camp',
    name: 'Employee-Camp',
    meta: {
      layout: 'authLayout',
    },
    component: EmployeeCamp
  },
  {
    path: '/point-rule',
    name: 'PointRule',
    meta: {
      layout: 'authLayout'
    },
    component: PointRule
  },
  {
    path: '/point-rule/:pointRuleId',
    name: 'Product',
    meta: {
      layout: 'authLayout'
    },
    component: Product
  },
  {
    path: '/promotions',
    name: 'Promotion',
    meta: {
      layout: 'authLayout'
    },
    component: Promotion
  },
  {
    path: '/users',
    name: 'User',
    meta: {
      layout: 'authLayout'
    },
    component: User
  },
  {
    path: '/line-users',
    name: 'LineUser',
    meta: {
      layout: 'authLayout'
    },
    component: LineUser
  },
  {
    path: '/line-users-camp',
    name: 'LineUser-Camp',
    meta: {
      layout: 'authLayout'
    },
    component: LineUserCamp
  },
  {
    path: '/lucky-draws',
    name: 'LuckyDrawContent',
    meta: {
      layout: 'authLayout'
    },
    component: LuckyDrawContent
  },
  {
    path: '/lucky-draws/:luckyDrawId',
    name: 'LuckyDrawReward',
    meta: {
      layout: 'authLayout',
    },
    component: LuckyDrawReward
  },
  {
    path: '/lucky-draws/:luckyDrawId/:rewardId',
    name: 'LuckyDrawWinner',
    meta: {
      layout: 'authLayout'
    },
    component: LuckyDrawWinner
  },
  {
    path: '/reward',
    name: 'Reward',
    meta: {
      layout: 'authLayout'
    },
    component: Reward
  },
  {
    path: '/reward-camp',
    name: 'Reward-Camp',
    meta: {
      layout: 'authLayout'
    },
    component: RewardCamp
  },
  {
    path: '/reward-category',
    name: 'RewardCategory',
    meta: {
      layout: 'authLayout'
    },
    component: RewardCategory
  },
  {
    path: '/reward-category-camp',
    name: 'RewardCategory-Camp',
    meta: {
      layout: 'authLayout'
    },
    component: RewardCategoryCamp
  },
  {
    path: '/promotion-camp',
    name: 'Promotion-Camp',
    meta: {
      layout: 'authLayout'
    },
    component: PromotionCamp
  },
  {
    path: '/highlight-camp',
    name: 'Highlight-Camp',
    meta: {
      layout: 'authLayout'
    },
    component: HighlightCamp
  },
  {
    path: '/shop',
    name: 'Shop',
    meta: {
      layout: 'authLayout'
    },
    component: Shop
  },
  {
    path: '/history',
    name: 'History',
    meta: {
      layout: 'authLayout'
    },
    component: History
  },
  {
    path: '/history-camp',
    name: 'History-Camp',
    meta: {
      layout: 'authLayout'
    },
    component: HistoryCamp
  },
  {
    path: '/calculator',
    name: 'Calculator',
    meta: {
      layout: 'authLayout'
    },
    component: Calculator
  },
  {
    path: '/hero-reward-camp',
    name: 'Hero-Reward-Camp',
    meta: {
      layout: 'authLayout'
    },
    component: HeroRewardCamp
  },
  {
    path: '/hero-announcement-camp',
    name: 'Hero-Announcement-Camp',
    meta: {
      layout: 'authLayout'
    },
    component: HeroAnnouncementCamp
  },
  {
    path: '/hero-announcement-camp/:employeeId',
    name: 'Hero-Announcement-Employee-Camp',
    meta: {
      layout: 'authLayout',
    },
    component: HeroAnnouncementEmployeeCamp
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: get(process, 'env.BASE_URL'),
  routes
})

export default router
