export class AlertMessage {
  message: string
  status: string
  type: string

  constructor(message = '', status = '', type = '') {
    this.message = message
    this.status = status
    this.type = type
  }
}