
import Vue from "vue";

export default Vue.extend({
  props: {
    snackbar: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "error",
    },
  },
});
