
import { Component, Ref, Vue } from "vue-property-decorator";
import Utils from "@/mixin";
import { VForm } from "@/type";

@Component({
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    toggleDialog: {
      type: Function,
      // eslint-disable-next-line
      default: /* istanbul ignore next */ () => {},
    },
    data: {
      type: Object,
      default: () => ({ name: "" }),
    },
  },
  mixins: [Utils],
  watch: {
    data: {
      handler(val) {
        this.formData = {
          ...val,
          image: undefined,
        };
      },
      deep: false,
    },
  },
})
export default class BrandForm extends Vue {
  valid = false;
  initialForm = {
    id: "",
    name: "",
    order: "",
  };
  formData = this.initialForm;
  /* istanbul ignore next */
  rules = {
    required: (value: any) => !!value || "Required.", // eslint-disable-line
  };

  @Ref("form") readonly form!: VForm;
  createFamilyBrand!: (formData: object) => void;
  editFamilyBrand!: (formData: object) => void;
  toggleDialog!: () => void;

  clearData() {
    this.toggleDialog();
    this.$nextTick(() => {
      this.formData = Object.assign({}, this.initialForm);
      /* istanbul ignore next */
      if (this.form) this.form.reset();
    });
  }

  submitForm() {
    const { formData } = this;

    if (formData?.id) {
      this.editFamilyBrand(formData);
    } else {
      this.createFamilyBrand(formData);
    }
    this.clearData();
  }
}
