
import { Component, Ref, Vue } from "vue-property-decorator";
import { forEach, get, isEmpty } from "lodash";
import Utils from "@/mixin";
import { Participant, VForm, SelectItem } from "@/type";

@Component({
  mixins: [Utils],
  props: {
    rewardId: {
      type: Number,
    },
  },
  computed: {
    validate() {
      const { selectParticipant } = this;
      if (isEmpty(selectParticipant)) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.getInitialData();
  },
})
export default class WinnerForm extends Vue {
  dialog = false;
  page = 1;
  keyword = "";
  selectParticipant = "";
  /* istanbul ignore next */
  rules = {
    required: (value: [number, string]) => !!value || "Required.",
  };

  @Ref("form") readonly form!: VForm;
  toggleDialog!: () => void;
  getParticipant!: (searchForm?: object) => void;
  createWinner!: (formData: object) => void;

  getInitialData() {
    const luckyDrawId = get(this, "$route.params.luckyDrawId");
    /* istanbul ignore next */
    if (luckyDrawId !== undefined) {
      const { page, keyword } = this;
      const searchForm = {
        page,
        search: keyword,
        luckyDrawId,
      };
      this.getParticipant(searchForm);
    }
  }

  generateList(participant: Participant) {
    const newArray: SelectItem[] = [];
    forEach(participant, (i) => {
      const selectItem = {
        text: get(i, "Name", ""),
        value: get(i, "TransactionID"),
      };
      newArray.push(selectItem);
    });
    return newArray;
  }

  clearData() {
    this.dialog = false;
    this.$nextTick(() => {
      this.selectParticipant = "";
      /* istanbul ignore next */
      if (this.form) this.form.resetValidation();
    });
  }

  searchKeyword(e: any) { // eslint-disable-line
    this.keyword = e.srcElement.value;
    /* istanbul ignore else */
    if (this.keyword !== "") this.getInitialData();
  }

  submitForm() {
    const formData = {
      luckyDrawRewardId: get(this, "$route.params.rewardId"),
      luckyDrawTransactionId: get(this.selectParticipant, "value"),
      luckyDrawId: get(this, "$route.params.luckyDrawId"),
    };
    // console.log(formData);
    this.createWinner(formData);
    this.clearData();
  }
}
