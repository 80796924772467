
import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component({
  computed: {
    confirmDialog: {
      get() {
        return this.dialog;
      },
      set() {
        return this.dialog;
      },
    },
  },
})
export default class ConfirmDialog extends Vue {
  @Prop({ default: "Header" }) header!: string;
  @Prop({ default: "Content" }) content!: string;
  @Prop({ default: "Cancel" }) cancelText!: string;
  @Prop({ default: "Confirm" }) confirmText!: string;
  @Prop({ default: false }) dialog!: boolean;

  @Emit()
  cancelDialog() {
    return;
  }

  @Emit()
  submitDialog() {
    return;
  }
}
