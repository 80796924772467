
import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component({
  computed: {
    timeValue: {
      get() {
        return this.value;
      },
      set() {
        return this.value;
      },
    },
  },
})
export default class TimeInput extends Vue {
  @Prop({ default: "Time Input" }) label!: string;
  @Prop(String) value!: string;

  dialog = false;

  handleDialog() {
    this.dialog = !this.dialog;
  }

  @Emit()
  updateTime(e: string) {
    return e
  }
}
