
import { Component, Ref, Vue } from "vue-property-decorator";
import { findIndex, isEmpty, get } from "lodash";
import Utils from "@/mixin";
import { Content as ContentType, LuckyReward as RewardType, VForm } from "@/type";

@Component({
  mixins: [Utils],
  props: {
    luckyDrawId: {
      type: Number,
    },
    editId: {
      type: [Number, String],
      default: "",
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    toggleDialog: {
      type: Function,
      // eslint-disable-next-line
      default: /* istanbul ignore next */ () => {},
    },
    setInitialDialog: {
      type: Function,
      // eslint-disable-next-line
      default: /* istanbul ignore next */ () => {},
    },
  },
  computed: {
    validate() {
      const { title, description, quantity, image } = this.formData;
      if (
        (this.editId === "" &&
          (isEmpty(title) ||
            get(description, "length") === 0 ||
            quantity === 0 ||
            image === undefined)) ||
        (this.editId !== "" &&
          (isEmpty(title) ||
            get(description, "length") === 0 ||
            quantity === 0))
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    editId(nextProps, prevProps) {
      /* istanbul ignore else */
      if (prevProps !== nextProps && nextProps !== "") {
        this.modalTitle = "Edit";
        const { luckydraws } = this.luckyDraw;
        const contentIndex = findIndex(
          luckydraws,
          (i: ContentType) => i.id === this.luckyDrawId
        );
        const content = luckydraws[contentIndex].rewards;
        const rewardIndex = findIndex(
          content,
          (i: RewardType) => i.id === nextProps
        );
        const data = content[rewardIndex];
        // console.log(data);
        this.formData = {
          title: data.title,
          description: data.description,
          quantity: data.quantity,
        };
      }
    },
  },
})
export default class RewardForm extends Vue {
  modalTitle = "Create";
  formData = {
    title: "",
    description: "",
    quantity: 0,
    image: undefined,
  };
  initialForm = {
    title: "",
    description: "",
    quantity: 0,
    image: undefined,
  };
  /* istanbul ignore next */
  rules = {
    required: (value: [number, string]) => !!value || "Required.",
    min: (value: number) => value > 0 || "Qty must more than 0",
  };

  @Ref("form") readonly form!: VForm;
  createLuckyDrawReward!: (formData: object) => void;
  editLuckyDrawReward!: (formData: object) => void;
  toggleDialog!: () => void;

  clearData() {
    if (this.$props.editId !== "") this.$props.setInitialDialog();
    this.toggleDialog();
    this.$nextTick(() => {
      this.modalTitle = "Create";
      this.formData = Object.assign({}, this.initialForm);
      /* istanbul ignore next */
      if (this.form) this.form.resetValidation();
    });
  }

  submitForm() {
    const { formData } = this;
    if (this.$props.editId !== "") {
      const submitForm = {
        title: get(formData, "title"),
        description: get(formData, "description"),
        quantity: get(formData, "quantity").toString(),
        luckyDrawId: get(this, "luckyDrawId"),
        image: formData.image,
        editId: this.$props.editId,
      };
      this.editLuckyDrawReward(submitForm);
    } else {
      const submitForm = {
        title: get(formData, "title"),
        description: get(formData, "description"),
        quantity: get(formData, "quantity").toString(),
        luckyDrawId: get(this, "luckyDrawId"),
        image: formData.image,
      };
      this.createLuckyDrawReward(submitForm);
    }
    this.clearData();
  }
}
