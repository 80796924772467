
import { Component, Ref, Vue } from "vue-property-decorator";
import { isEmpty } from "lodash";
import Utils from "@/mixin";
import { VForm } from "@/type";

@Component({
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    toggleDialog: {
      type: Function,
      // eslint-disable-next-line
      default: /* istanbul ignore next */ () => {},
    },
  },
  mixins: [Utils],
  computed: {
    validate() {
      const { name, email, password, confirmPassword } = this.formData;
      if (
        isEmpty(name) ||
        isEmpty(email) ||
        isEmpty(password) ||
        password !== confirmPassword
      ) {
        return true;
      } else {
        return false;
      }
    },
    validatePassword() {
      const { password, confirmPassword } = this.formData;
      return password === confirmPassword || "Password must match";
    },
  },
})
export default class UserForm extends Vue {
  show = false;
  confirmShow = false;
  formData = {
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    remark: "",
  };
  initialForm = {
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    remark: "",
  };
  /* istanbul ignore next */
  rules = {
    required: (value: [number, string]) => !!value || "Required.",
    min: (v: [number, string]) => v?.length >= 8 || "Min 8 characters",
    emailValidate: (email: [string]) => {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
      if (re.test(String(email).toLowerCase())) {
        return true;
      }
      return "Invalid email format";
    },
  };

  @Ref("form") readonly form!: VForm;
  createUser!: (formData: object) => void;
  toggleDialog!: () => void;

  clearData() {
    this.toggleDialog();
    this.$nextTick(() => {
      this.formData = Object.assign({}, this.initialForm);
      this.show = false;
      this.confirmShow = false;
      /* istanbul ignore next */
      if (this.form) this.form.resetValidation();
    });
  }

  submitForm() {
    const { formData } = this;
    this.createUser(formData);
    this.clearData();
  }
}
