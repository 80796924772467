
import { Component, Vue } from "vue-property-decorator";
import { capitalize, findIndex, forEach } from "lodash";
import Utils from "@/mixin";
import { Promotion as PromotionType, PromotionShopType } from "@/type";

import CreatePromotion from "./Promotion/Form.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import LightBox from "@/components/LightBox.vue";
import { itemsPerPageOptions } from "@/plugins/common";

@Component({
  components: {
    ConfirmDialog,
    CreatePromotion,
    LightBox,
  },
  mixins: [Utils],
  computed: {
    generateTable() {
      if (this.promotionList !== undefined) {
        const { promotionList, promotionShopTypeList } = this;
        const tableList: PromotionType[] = [];
        forEach(promotionList, (i) => {
          const promotion = new PromotionType();
          const shopTypeList: any = []; // eslint-disable-line
          forEach(i.shopType, (j) => {
            const index = findIndex(
              promotionShopTypeList,
              (e: PromotionShopType) => e.id === j.shopTypeId
            );
            shopTypeList.push(capitalize(promotionShopTypeList[index]?.type));
          });
          promotion.id = i.id;
          promotion.name = i.name;
          promotion.title = i.title;
          promotion.description = i.description;
          promotion.startTime = this.$generateDateTime(i.startTime);
          promotion.endTime = this.$generateDateTime(i.endTime);
          promotion.image = i.image;
          promotion.isPublic = i.isPublic;
          promotion.order = i.order;
          promotion.shopType = shopTypeList;
          this.total = i.total;
          // this.itemPerPage = i.perPage;
          tableList.push(promotion);
        });
        return tableList;
      } else {
        return [];
      }
    },
  },
  mounted() {
    this.getInitialData();
    this.getShopType();
    this.itemsPerPageOptions = itemsPerPageOptions;
  },
  watch: {
    // keyword(nextProps, prevProps) {
    //   /* istanbul ignore else */
    //   if (nextProps !== prevProps) {
    //     this.getInitialData();
    //   }
    // },
    itemPerPage(nextProps, prevProps) {
      /* istanbul ignore else */
      if (nextProps !== prevProps) {
        this.getInitialData();
      }
    },
    page(nextProps, prevProps) {
      /* istanbul ignore else */
      if (nextProps !== prevProps) {
        this.getInitialData();
      }
    },
  },
})
export default class Promotion extends Vue {
  editId = "";
  deleteId = -1;
  dialog = false;
  confirmDialog = false;
  page = 1;
  total = 0;
  itemsPerPageOptions = [];
  itemPerPage = 10;
  keyword = "";
  headers = [
    { text: "Title", value: "title", align: "center", sortable: false },
    { text: "Name", value: "name", align: "center", sortable: false },
    {
      text: "Description",
      value: "description",
      align: "center",
      width: "350px",
      sortable: false,
    },
    { text: "Shop Type", value: "shopType", align: "center", sortable: false },
    { text: "Public", value: "isPublic", align: "center", sortable: false },
    { text: "Order", value: "order", align: "center", sortable: false },
    {
      text: "Start Date",
      value: "startTime",
      align: "center",
      sortable: false,
    },
    { text: "End Date", value: "endTime", align: "center", sortable: false },
    { text: "Image", value: "image", align: "center", sortable: false },
    { text: "Actions", value: "actions", width: "120px", sortable: false },
  ];
  imgArr: Array<string> = [];
  imgShow = false;

  getPromotion!: (searchForm: object) => void;
  getPromotionShopType!: () => void;
  removePromotion!: (paramId?: number) => void;
  // eslint-disable-next-line
  onKeyDown(e: any) {
    const { keyCode } = e;
    if (keyCode === 13) {
      this.getInitialData();
    }
  }
  // eslint-disable-next-line
  showImg(image: any) {
    this.imgShow = true;
    if (typeof image === "string") {
      this.imgArr = [image];
    } else {
      this.imgArr = image;
    }
  }

  getInitialData() {
    const { page, itemPerPage, keyword } = this;
    const searchForm = {
      page,
      perPage: itemPerPage,
      keyword,
    };
    this.getPromotion(searchForm);
  }

  getShopType() {
    this.getPromotionShopType();
  }

  toggleDialog() {
    this.dialog = !this.dialog;
  }

  setInitialDialog() {
    this.editId = "";
  }

  editItem(paramId: string) {
    this.editId = paramId;
    this.toggleDialog();
  }

  toggleConfirmModal(paramId: number) {
    this.deleteId = paramId;
    this.confirmDialog = !this.confirmDialog;
  }

  cancel() {
    this.deleteId = -1;
    this.confirmDialog = !this.confirmDialog;
  }

  actionItem() {
    const { deleteId } = this;
    this.removePromotion(deleteId);
    this.cancel();
  }
}
