import { Module } from 'vuex'
import { cloneDeep, forEach, get, size, findIndex } from 'lodash'

import { RootState, ShopState, AlertMessage, Shop, } from '@/type'
import { REQUEST_GET, REQUEST_DELETE, REQUEST_POST, REQUEST_PATCH } from './API_Request'
import router from '@/router'
import { ShopList } from '@/type/Shop'
import { errorMessage } from '@/plugins/common'
import { GLOBAL_MESSAGE, SHOP_MESSAGE } from '@/plugins/message'

export const state = {
  shopList: Array<Shop>(),
}

export const mutations = {
  async GET_SHOP(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      const res = await REQUEST_GET('/shops', data.searchForm)
      // console.log(res);
      const shopList = get(res, 'data.data')
      if (size(shopList) > 0) {
        const arrayList: Array<Shop> = []
        forEach(shopList, i => {
          arrayList.push({ ...i, total: get(res, 'data.total', shopList.length), perPage: get(res, 'data.perPage') })
        })
        state.shopList = arrayList
      } else {
        state.shopList = []
      }
      // console.log(state);
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async GET_SHOP_INFO(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      const res = await REQUEST_GET('/shops/' + data.paramId)
      // console.log(res);
      const shopData = get(res, 'data')
      const shopList = state.shopList
      const currentIndex = findIndex(shopList, { id: data.paramId })
      forEach(shopList, (i, index) => {
        /* istanbul ignore next */
        if (parseInt(index) === currentIndex) {
          i.user = shopData.user
        }
      })
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async REMOVE_SHOP(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      /* istanbul ignore next */
      if (get(data, 'paramId') === undefined) router.go(0)
      const res = await REQUEST_DELETE('/shops/' + get(data, 'paramId') + '/user')
      // console.log(res);
      switch (get(res, 'status')) {
        case 200: {
          const shopList = state.shopList
          const arrayList: Array<Shop> = []
          const deleteIndex = findIndex(shopList, { id: data.paramId })
          /* istanbul ignore next */
          forEach(shopList, (i, index) => {
            if (parseInt(index) !== deleteIndex) {
              arrayList.push({ ...i, total: i.total - 1 })
            }
          })
          state.shopList = arrayList
          data.toggleLoading()
          const alert = new AlertMessage(SHOP_MESSAGE.DELETE.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
      /* istanbul ignore else */
      if (error) alert = new AlertMessage(SHOP_MESSAGE.DELETE.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  },
  async SYNC_SHOP(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      const paramId: string = get(data, 'paramId')

      const res = await REQUEST_POST('/shops/' + paramId + '/sync')
      // console.log(res);
      switch (get(res, 'status')) {
        case 200: {
          data.toggleLoading()
          const shopList = cloneDeep(state.shopList)
          const updateIndex = findIndex(shopList, { id: paramId })
          /* istanbul ignore next */
          if (paramId === res.data?.id) {
            shopList[updateIndex].shopCode = res.data?.shopCode
            shopList[updateIndex].regionCode = res.data?.regionCode
            shopList[updateIndex].customerType = res.data?.customerType
            shopList[updateIndex].typeCode = res.data?.typeCode
            shopList[updateIndex].privilegeType = res.data?.privilegeType
            shopList[updateIndex].shopTypeId = res.data?.shopTypeId
            shopList[updateIndex].userID = res.data?.userID
            shopList[updateIndex].territoryCode = res.data?.territoryCode
            shopList[updateIndex].invoices = res.data?.invoices
            shopList[updateIndex].areaCode = res.data?.areaCode
            shopList[updateIndex].customerSaleType = res.data?.customerSaleType
            shopList[updateIndex].user = res.data?.user
            shopList[updateIndex].category = res.data?.category
            shopList[updateIndex].name = res.data?.name
            state.shopList = shopList
          }
          const alert = new AlertMessage(SHOP_MESSAGE.SYNC.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
      /* istanbul ignore else */
      if (error) alert = new AlertMessage(SHOP_MESSAGE.SYNC.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  },
  async UNLINK_SHOP(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      const paramId: number = get(data, 'paramId')

      const res = await REQUEST_DELETE('/shops/' + paramId + '/user')
      // console.log(res);
      switch (get(res, 'status')) {
        case 200: {
          data.toggleLoading()
          const shopList = cloneDeep(state.shopList)
          const arrayList: Array<Shop> = []
          const deleteIndex = findIndex(shopList, { id: paramId })
          forEach(shopList, (i, index) => {
            /* istanbul ignore else */
            if (parseInt(index) !== deleteIndex) {
              arrayList.push({ ...i, total: (get(i, 'total', 0) - 1) < 0 ? 0 : get(i, 'total', 0) - 1 })
            }
          })
          state.shopList = arrayList
          const alert = new AlertMessage(SHOP_MESSAGE.UNLINK.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
      /* istanbul ignore else */
      if (error) alert = new AlertMessage(SHOP_MESSAGE.UNLINK.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  },
  async UPLOAD_ATTACHMENT_SHOP(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      const paramId: number = get(data, 'uploadData.paramId')

      const formData = new FormData()
      formData.append('cardImage', get(data, 'uploadData.file'))

      const res = await REQUEST_PATCH('/shops/' + paramId + '/attachment', formData)
      switch (get(res, 'status')) {
        case 200: {
          data.toggleLoading()
          const alert = new AlertMessage(SHOP_MESSAGE.UPLOAD.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
      /* istanbul ignore else */
      if (error) alert = new AlertMessage(SHOP_MESSAGE.UPLOAD.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  },
}

export const actions = {
  getShop(context: any, searchForm: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    context.commit('GET_SHOP', { toggleLoading, searchForm })
  },
  getShopInfo(context: any, paramId: number | undefined) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    context.commit('GET_SHOP_INFO', { toggleLoading, paramId })
  },
  removeShop(context: any, paramId: number | undefined) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm: AlertMessage) => context.dispatch('toggleAlertMessage', alertForm)
    context.commit('REMOVE_SHOP', { toggleLoading, paramId, toggleAlert })
  },
  syncShop(context: any, paramId: number | undefined) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm: AlertMessage) => context.dispatch('toggleAlertMessage', alertForm)
    context.commit('SYNC_SHOP', { toggleLoading, paramId, toggleAlert })
  },
  unlinkShop(context: any, paramId: number | undefined) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm: AlertMessage) => context.dispatch('toggleAlertMessage', alertForm)
    context.commit('UNLINK_SHOP', { toggleLoading, paramId, toggleAlert })
  },
  uploadUserAttachmentShop(context: any, uploadData:object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm: AlertMessage) => context.dispatch('toggleAlertMessage', alertForm)
    context.commit('UPLOAD_ATTACHMENT_SHOP', { toggleLoading, uploadData, toggleAlert })
  },
}

export const getters = {
  // Add Logic Before Computed
  shopList(state: any): ShopList | undefined { // eslint-disable-line
    return state.shopList
  },
}

export const shops: Module<ShopState, RootState> = {
  state,
  mutations,
  actions,
  getters
}