
import { Component, Vue } from "vue-property-decorator";
import { forEach } from "lodash";
import Utils from "@/mixin";
import { LineUser as LineUserType } from "@/type";
import { itemsPerPageOptions } from "@/plugins/common";

@Component({
  mixins: [Utils],
  mounted() {
    // console.log(this);
    this.getInitialData();
    this.itemsPerPageOptions = itemsPerPageOptions;
  },
  computed: {
    generateTable() {
      if (this.lineUserList !== undefined) {
        const { lineUserList } = this;
        const tableList: LineUserType[] = [];
        forEach(lineUserList, (i) => {
          const lineUser = new LineUserType();
          lineUser.id = i.id;
          lineUser.displayName =
            i.taxPrefix + " " + i.taxFirstName + " " + i.taxLastName;
          lineUser.phoneNumber = i.phoneNumber;
          lineUser.citizenID = i.citizenID;
          lineUser.Type = i.Type;
          lineUser.taxType = i.taxType;
          lineUser.taxCompanyName = i.taxCompanyName;
          this.total = i.total;
          // this.itemPerPage = i.perPage;
          tableList.push(lineUser);
        });
        return tableList;
      } else {
        return [];
      }
    },
  },
  watch: {
    // keyword(nextProps, prevProps) {
    //   /* istanbul ignore else */
    //   if (nextProps !== prevProps) {
    //     this.getInitialData();
    //   }
    // },
    itemPerPage(nextProps, prevProps) {
      /* istanbul ignore else */
      if (nextProps !== prevProps) {
        this.getInitialData();
      }
    },
    page(nextProps, prevProps) {
      /* istanbul ignore else */
      if (nextProps !== prevProps) {
        this.getInitialData();
      }
    },
  },
})
export default class LineUser extends Vue {
  page = 1;
  total = 0;
  itemsPerPageOptions = [];
  itemPerPage = 10;
  keyword = "";
  type = "shop";
  headers = [
    {
      text: "Name",
      value: "displayName",
      align: "start",
      sortable: false,
      width: "200px",
    },
    { text: "Phone Number", value: "phoneNumber", sortable: false },
    { text: "Citizen ID", value: "citizenID", sortable: false },
    { text: "Type", value: "Type", sortable: false },
    { text: "Tax Type", value: "taxType", sortable: false },
    { text: "Company Name", value: "taxCompanyName", sortable: false },
  ];

  getLineUser!: (searchForm?: object) => void;
  // eslint-disable-next-line
  onKeyDown(e: any) {
    const { keyCode } = e;
    if (keyCode === 13) {
      this.getInitialData();
    }
  }

  getInitialData() {
    const { page, itemPerPage, keyword, type } = this;
    const searchForm = {
      page,
      perPage: itemPerPage,
      keyword,
      type,
    };
    this.getLineUser(searchForm);
  }
}
