import { Module } from 'vuex'
import { cloneDeep, get, findIndex } from 'lodash'

import { RootState, HighlightState, AlertMessage, Highlight } from '@/type'
import { REQUEST_GET, REQUEST_DELETE, REQUEST_POST } from './API_Request'
import router from '@/router'
import { HighlightList } from '@/type/Highlight'
import { errorMessage } from '@/plugins/common'
import { GLOBAL_MESSAGE } from '@/plugins/message'

export const state = {
  highlightList: Array<Highlight>()
}

export const mutations = {
  async GET_HIGHLIGHT_CAMP(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      const res = await REQUEST_GET('/highlights', data.searchForm)
      state.highlightList = res.data || []
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async CREATE_HIGHLIGHT_CAMP(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      let formData = new FormData()
      const images = data.formData?.images

      formData.append('header', get(data, 'formData.header'))
      if(data.formData?.image) {
        formData.append('banner', data.formData?.image)
      }
      if(images && Array.isArray(images)) {
        formData = images.reduce((o, e) => {
          o.append('images', e)
          return o
        }, formData)
      }

      const res = await REQUEST_POST('/highlight', formData)
      switch (get(res, 'status')) {
        case 200: {
          data.toggleLoading()
          const alert = new AlertMessage(GLOBAL_MESSAGE.CREATE.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
      /* istanbul ignore else */
      if (error) alert = new AlertMessage(GLOBAL_MESSAGE.CREATE.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  },
  async REMOVE_HIGHLIGHT_CAMP(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      /* istanbul ignore next */
      if (get(data, 'paramId') === undefined) router.go(0)
      const res = await REQUEST_DELETE(`/highlight/${data.paramId}`)
      switch (get(res, 'status')) {
        case 204: {
          const highlightList = cloneDeep(state.highlightList)
          const index = findIndex(highlightList, { id: data.paramId })
          highlightList.splice(index, 1)
          state.highlightList = highlightList

          data.toggleLoading()
          const alert = new AlertMessage(GLOBAL_MESSAGE.DELETE.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
      /* istanbul ignore else */
      if (error) alert = new AlertMessage(GLOBAL_MESSAGE.DELETE.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  }
}

export const actions = {
  getHighlightCamp(context: any, searchForm: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    context.commit('GET_HIGHLIGHT_CAMP', { toggleLoading, searchForm })
  },
  createHighlightCamp(context: any, formData: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm: AlertMessage) => context.dispatch('toggleAlertMessage', alertForm)
    return mutations.CREATE_HIGHLIGHT_CAMP(state, { toggleLoading, formData, toggleAlert })
  },
  removeHighlightCamp(context: any, paramId: number) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm: AlertMessage) => context.dispatch('toggleAlertMessage', alertForm)
    context.commit('REMOVE_HIGHLIGHT_CAMP', { toggleLoading, paramId, toggleAlert })
  }
}

export const getters = {
  // Add Logic Before Computed
  highlightList(state: any): HighlightList | undefined { // eslint-disable-line
    return state.highlightList
  },
}

export const highlights: Module<HighlightState, RootState> = {
  state,
  mutations,
  actions,
  getters
}