import { Content } from './Content'
import { Participant } from './Participant'

export class LuckyDraw {
  total: number
  perPage: number
  lastPage: number
  currentPage: number
  fistPageUrl: string
  lastPageUrl: string
  nextPageUrl: string
  prevPageUrl: string
  from: number
  to: number
  luckydraws: Content[]

  constructor() {
    this.total = 0
    this.perPage = 0
    this.lastPage = 0
    this.currentPage = 0
    this.fistPageUrl = ''
    this.lastPageUrl = ''
    this.nextPageUrl = ''
    this.prevPageUrl = ''
    this.from = 0
    this.to = 0
    this.luckydraws = []
  }
}

export class LuckyDrawParticipant {
  total: number
  perPage: number
  lastPage: number
  currentPage: number
  fistPageUrl: string
  lastPageUrl: string
  nextPageUrl: string
  prevPageUrl: string
  from: number
  to: number
  participants: Participant[]

  constructor() {
    this.total = 0
    this.perPage = 0
    this.lastPage = 0
    this.currentPage = 0
    this.fistPageUrl = ''
    this.lastPageUrl = ''
    this.nextPageUrl = ''
    this.prevPageUrl = ''
    this.from = 0
    this.to = 0
    this.participants = []
  }
}