import Vue from 'vue'
import Swal, { SweetAlertOptions } from 'sweetalert2'
import browserDetect from 'vue-browser-detect-plugin'
import moment from 'moment'

import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';

import DefaultLayout from "@/layouts/default.vue";
import HomeLayout from "@/layouts/homeLayout.vue";
import AuthLayout from "@/layouts/authLayout.vue";

import LightBox from "@/components/LightBox.vue";

import '@/assets/global.scss'

Vue.component('defaultLayout', DefaultLayout)
Vue.component('homeLayout', HomeLayout)
Vue.component('authLayout', AuthLayout)
Vue.component('LightBox', LightBox)

Vue.use(browserDetect)

Vue.config.productionTip = false

Vue.prototype.$swal = function (text: string) {
  Swal.fire('Error...', text, 'error')
}

Vue.prototype.$swalNoBtn = function (text: string) {
  const options = {
    icon: 'error',
    text: text,
    showConfirmButton: false,
    allowOutsideClick: false,
  } as SweetAlertOptions
  Swal.fire(options)
}

Vue.prototype.$swalSuccess = function (text: string) {
  Swal.fire('Success', text, 'success')
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

Vue.prototype.$numberWithCommas = (number: number) => {
  return new Intl.NumberFormat().format(number)
  // return number.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
}

Vue.prototype.$generateDate = function (str: string) {
  const monthNamesThai = [
    'ม.ค.',
    'ก.พ.',
    'มี.ค.',
    'เม.ย.',
    'พ.ค.',
    'มิ.ย.',
    'ก.ค.',
    'ส.ค.',
    'ก.ย.',
    'ต.ค.',
    'พ.ย.',
    'ธ.ค.',
  ]

  const convertDate = new Date(str);
  const day = convertDate.getDate();
  const month = convertDate.getMonth();
  const year = convertDate.getFullYear();
  const fullDate = day + ' ' + monthNamesThai[month] + ' ' + year
  const hour = convertDate.getHours();
  const min = convertDate.getMinutes();
  const second = convertDate.getSeconds();
  if (hour > 9) {
    if (min > 9) {
      if (second > 9) {
        return fullDate + " " + hour + ":" + min + ":" + second;
      } else {
        return fullDate + " " + hour + ":" + min + ":0" + second;
      }
    } else {
      if (second > 9) {
        return fullDate + " " + hour + ":0" + min + ":" + second;
      } else {
        return fullDate + " " + hour + ":0" + min + ":0" + second;
      }
    }
  } else {
    if (min > 9) {
      if (second > 9) {
        return fullDate + " 0" + hour + ":" + min + ":" + second;
      } else {
        return fullDate + " 0" + hour + ":" + min + ":0" + second;
      }
    } else {
      if (second > 9) {
        return fullDate + " 0" + hour + ":0" + min + ":" + second;
      } else {
        return fullDate + " 0" + hour + ":0" + min + ":0" + second;
      }
    }
  }
}

Vue.prototype.$generateDateTime = function (date: string) {
  return moment(date).format("YYYY-MM-DD HH:mm:ss")
  // const fullDate = date.toISOString().slice(0, 10);
  // const hour = date.getHours();
  // const min = date.getMinutes();
  // const second = date.getSeconds();
  // if (hour > 9) {
  //   if (min > 9) {
  //     if (second > 9) {
  //       return fullDate + " " + hour + ":" + min + ":" + second;
  //     } else {
  //       return fullDate + " " + hour + ":" + min + ":0" + second;
  //     }
  //   } else {
  //     if (second > 9) {
  //       return fullDate + " " + hour + ":0" + min + ":" + second;
  //     } else {
  //       return fullDate + " " + hour + ":0" + min + ":0" + second;
  //     }
  //   }
  // } else {
  //   if (min > 9) {
  //     if (second > 9) {
  //       return fullDate + " 0" + hour + ":" + min + ":" + second;
  //     } else {
  //       return fullDate + " 0" + hour + ":" + min + ":0" + second;
  //     }
  //   } else {
  //     if (second > 9) {
  //       return fullDate + " 0" + hour + ":0" + min + ":" + second;
  //     } else {
  //       return fullDate + " 0" + hour + ":0" + min + ":0" + second;
  //     }
  //   }
  // }
}