
import { Component, Vue } from "vue-property-decorator";
import { forEach, findIndex, get, isEmpty } from "lodash";
import { Content, LuckyReward } from "@/type";
import Utils from "@/mixin";

import CreateReward from "./LuckyDraw/Reward.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import LightBox from "@/components/LightBox.vue";

@Component({
  components: {
    ConfirmDialog,
    CreateReward,
    LightBox,
  },
  mixins: [Utils],
  computed: {
    /* istanbul ignore next */
    contentId() {
      if (!isEmpty(this.$route.params) && !isEmpty(this.luckyDraw)) {
        return get(this, "$route.params.luckyDrawId");
      } else {
        this.$router.push({ name: "LuckyDrawContent" });
      }
      return;
    },
    /* istanbul ignore next */
    generateTable() {
      if (!isEmpty(this.luckyDraw)) {
        const { luckydraws } = this.luckyDraw;
        const tableList: LuckyReward[] = [];
        const index = findIndex(
          luckydraws,
          (i: Content) => i.id === this.contentId
        );
        const { rewards } = luckydraws[index];
        forEach(rewards, (i) => {
          const reward = new LuckyReward();
          reward.id = i.id;
          reward.createdAt = this.$generateDateTime(i.createdAt);
          reward.updatedAt = this.$generateDateTime(i.updatedAt);
          reward.deletedAt = this.$generateDateTime(i.deletedAt);
          reward.imageUrl = i.imageUrl;
          reward.title = i.title;
          reward.description = i.description;
          reward.quantity = i.quantity;
          reward.luckyDrawId = i.luckyDrawId;
          reward.winners = i.winners;
          tableList.push(reward);
        });
        return tableList;
      } else {
        return [];
      }
    },
  },
  mounted() {
    const luckyDrawId = get(this, "$route.params.luckyDrawId");
    this.breadcrumbItems.push({
      text: "Rewards",
      disabled: true,
      href: "/lucky-draws/" + luckyDrawId,
    });
  },
})
export default class LuckyDrawReward extends Vue {
  editId = "";
  deleteId = -1;
  dialog = false;
  confirmDialog = false;
  page = 1;
  itemPerPage = 10;
  breadcrumbItems = [
    {
      text: "LuckDraws",
      disabled: false,
      href: "/lucky-draws",
    },
  ];
  headers = [
    { text: "Title", value: "title", align: "center", sortable: false },
    { text: "Quantity", value: "quantity", align: "center", sortable: false },
    {
      text: "Created Date",
      value: "createdAt",
      align: "center",
      sortable: false,
    },
    {
      text: "Updated Date",
      value: "updatedAt",
      align: "center",
      sortable: false,
    },
    {
      text: "Deleted Date",
      value: "deletedAt",
      align: "center",
      sortable: false,
    },
    { text: "Image", value: "imageUrl", align: "center", sortable: false },
    { text: "Actions", value: "actions", width: "100px", sortable: false },
  ];
  imgArr: Array<string> = [];
  imgShow = false;

  removeLuckyDrawReward!: (formData?: object) => void;
  // eslint-disable-next-line
  showImg(image: any) {
    this.imgShow = true;
    if (typeof image === "string") {
      this.imgArr = [image];
    } else {
      this.imgArr = image;
    }
  }

  toggleDialog() {
    this.dialog = !this.dialog;
  }

  setInitialDialog() {
    this.editId = "";
  }

  selectItem(rewardId: string) {
    const luckyDrawId = get(this, "$route.params.luckyDrawId");
    this.$router.push({
      name: "LuckyDrawWinner",
      params: { luckyDrawId, rewardId },
    });
  }

  editItem(paramId: string) {
    this.editId = paramId;
    this.toggleDialog();
  }

  toggleConfirmModal(paramId: number) {
    this.deleteId = paramId;
    this.confirmDialog = !this.confirmDialog;
  }

  cancel() {
    this.deleteId = -1;
    this.confirmDialog = !this.confirmDialog;
  }

  actionItem() {
    const { deleteId } = this;
    const luckyDrawId = get(this, "$route.params.luckyDrawId");
    this.removeLuckyDrawReward({ luckyDrawId, deleteId });
    this.cancel();
  }
}
