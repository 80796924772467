export class Winner {
  luckyDrawRewardId?: number
  luckyDrawTransactionId?: number
  taxFirstName?: string
  taxLastName?: string
  taxType?: string
  displayName?: string

  constructor() {
    this.luckyDrawRewardId = 0
    this.luckyDrawTransactionId = 0
  }
}

export class HeroWinner {
  id?: number
  order?: number
  employeeCode?: string
  employeeName?: string
  percentage?: number
  point?: number
  territoryCode?: string
  winnerId?: number
}

export type WinnerList = Winner[]

export type HeroWinnerList = HeroWinner[]