
import { Component, Ref, Vue } from "vue-property-decorator";
import Utils from "@/mixin";
import { VForm } from "@/type";

@Component({
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    toggleDialog: {
      type: Function,
      // eslint-disable-next-line
      default: /* istanbul ignore next */ () => {},
    },
    data: {
      type: Object,
      default: () => ({ name: "" }),
    },
  },
  mixins: [Utils]
})
export default class HighlightForm extends Vue {
  valid = false;
  initialForm = {
    header: "",
  };
  formData = this.initialForm;
  /* istanbul ignore next */
  rules = {
    required: (value: any) => !!value || "Required.", // eslint-disable-line
    empty: (value: any) => !!value && value.length > 0 || "Required.", // eslint-disable-line
  };

  @Ref("form") readonly form!: VForm;
  createHighlightCamp!: (formData: object) => Promise<any>; // eslint-disable-line
  toggleDialog!: () => void;

  clearData() {
    this.toggleDialog();
    this.$nextTick(() => {
      this.formData = Object.assign({}, this.initialForm);
      /* istanbul ignore next */
      if (this.form) this.form.reset();
    });
  }

  async submitForm() {
    const { formData } = this;
    await this.createHighlightCamp(formData);
    this.clearData();
    this.$emit("submit", true);
  }
}
