import Vue from 'vue'
import Vuex from 'vuex'

import { RootState, AlertMessage } from '@/type'
import { authentication } from './Auth'
import { banners } from './Banner'
import { employees } from './Employee'
import { promotions } from './Promotion'
import { points } from './Point'
import { users } from './User'
import { shops } from './Shop'
import { leaderBoard } from './LeaderBoard'
import { luckyDraw } from './LuckyDraw'
import { reward } from './Reward'
import { brands } from './Brand'
import { highlights } from './Highlight'
import { histories } from './History'
import { calculatorProduct } from './Product'

Vue.use(Vuex)

export const state = {
  isLoading: false,
  alertMessage: undefined,
}

export const mutations = {
  TOGGLE_LOADING(state: any) { // eslint-disable-line
    state.isLoading = !state.isLoading
    return state
  },
  TOGGLE_ALERT_MESSAGE(state: any, alertForm: AlertMessage) { // eslint-disable-line
    state.alertMessage = alertForm
    return state
  },
}

export const actions = {
  toggleLoading(context: any) { // eslint-disable-line
    context.commit('TOGGLE_LOADING')
  },
  toggleAlertMessage(context: any, formData: object) { // eslint-disable-line
    context.commit('TOGGLE_ALERT_MESSAGE', formData)
  },
}

export const getters = {
  isLoading(state: any): boolean { // eslint-disable-line
    return state.isLoading
  },
  alert(state: any): AlertMessage | undefined { // eslint-disable-line
    return state.alertMessage
  },
}

export const modules = {
  authentication,
  banners,
  brands,
  calculatorProduct,
  employees,
  highlights,
  histories,
  points,
  promotions,
  leaderBoard,
  luckyDraw,
  reward,
  shops,
  users
}

export default new Vuex.Store<RootState>({
  state,
  mutations,
  actions,
  getters,
  modules
})
