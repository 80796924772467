export class Error {
  request: any //eslint-disable-line
  response: any //eslint-disable-line
  message: string

  constructor({ request, response, message }: any) { //eslint-disable-line
    this.request = request
    this.response = response
    this.message = message
  }
}