
import { Component, Vue } from "vue-property-decorator";
import { capitalize, forEach, toLower } from "lodash";
import Utils from "@/mixin";
import { itemsPerPageOptions } from "@/plugins/common";

import DateInput from "@/components/DateInput.vue";
import LightBox from "@/components/LightBox.vue";

@Component({
  methods: {
    capitalize,
  },
  components: {
    DateInput,
    LightBox,
  },
  mixins: [Utils],
  mounted() {
    // console.log(this);
    this.getInitialData();
    this.itemsPerPageOptions = itemsPerPageOptions;
  },
  watch: {
    exportData: {
      handler() {
        this.getInitialData();
      },
      deep: false,
    },
    redeemType: {
      handler() {
        this.getInitialData();
      },
      deep: false,
    },
    shopType: {
      handler() {
        this.getInitialData();
      },
      deep: false,
    },
    itemPerPage: {
      handler() {
        this.getInitialData();
      },
      deep: false,
    },
    page: {
      handler() {
        this.getInitialData();
      },
      deep: false,
    },
    /* istanbul ignore next */
    historyList(nextProps, prevProps) {
      if (nextProps !== prevProps) {
        forEach(this.historyList, (i) => {
          this.total = i.total;
          this.itemPerPage = i.perPage;
        });
      }
    },
  },
})
export default class History extends Vue {
  page = 1;
  total = 0;
  itemsPerPageOptions = [];
  itemPerPage = 10;
  keyword = "";
  redeemType = "Reward";
  redeemItems = ["Reward", "Luckydraw"];
  headers = [
    { text: "Create Date", value: "createdAt", sortable: false },
    { text: "Title", value: "title", align: "start", sortable: false },
    { text: "Shop Code", value: "shopCode", sortable: false },
    { text: "Shop Type", value: "shopType", sortable: false },
    { text: "Description", value: "description", sortable: false },
    { text: "Image", value: "imageUrl", sortable: false },
    { text: "Quantity", value: "quantity", align: "center", sortable: false },
    { text: "Status", value: "status", align: "center", sortable: false },
    { text: "Type", value: "type", align: "center", sortable: false },
    {
      text: "Spent Point",
      value: "spentPoint",
      align: "center",
      sortable: false,
    },
    {
      text: "Redeem Point",
      value: "redeemPoint",
      align: "center",
      sortable: false,
    },
    { text: "Actions", value: "actions", sortable: false },
  ];
  imgArr: Array<string> = [];
  imgShow = false;
  exportData = {};
  shopType = { text: "All", value: "all" };
  shopItems = [
    { text: "All", value: "all" },
    { text: "Retail", value: "retail" },
    { text: "Wholesale", value: "wholesale" },
    { text: "Exclusivity", value: "exclusivity" },
  ];

  getHistory!: (searchForm?: object) => void;
  exportHistory!: (formData?: object) => void;
  exportAllHistory!: (formData?: object) => void;

  // eslint-disable-next-line
  onKeyDown(e: any) {
    const { keyCode } = e;
    if (keyCode === 13) {
      this.getInitialData();
    }
  }
  // eslint-disable-next-line
  showImg(image: any) {
    this.imgShow = true;
    if (typeof image === "string") {
      this.imgArr = [image];
    } else {
      this.imgArr = image;
    }
  }

  async exportAllRedeem() {
    const { exportData, keyword, redeemType, shopType } = this;
    const exportDataForm = {
      ...exportData,
      keyword,
      shopType: shopType.value,
      type: "shop",
      mode: toLower(redeemType),
    };
    await this.exportAllHistory(exportDataForm);
  }

  async exportRedeem(id: number | string) {
    const { keyword, redeemType } = this;
    const exportDataForm = {
      id,
      keyword,
      type: "shop",
      mode: toLower(redeemType),
    };
    await this.exportHistory(exportDataForm);
  }

  updateDateTime(value: string, name: string) {
    const newExportData = {
      ...this.exportData,
      [name]: value,
    };
    this.exportData = newExportData;
  }

  getInitialData() {
    const { page, itemPerPage, keyword, redeemType, exportData, shopType } =
      this;
    const searchForm = {
      ...exportData,
      shopType: shopType.value,
      page,
      perPage: itemPerPage,
      keyword,
      mode: toLower(redeemType),
      type: "shop",
    };
    this.getHistory(searchForm);
  }
  // eslint-disable-next-line
  generateURL(item: any): any {
    const returnUrl = item?.cardImageUrl;
    // if (!isEmpty(item?.cardImageUrl)) {
    //   const { id, shopCode, title } = item;
    //   const decodeUrl = decodeURIComponent(returnUrl);
    //   const splitUrl = decodeUrl.split("filename");
    //   const splitFileName = splitUrl[1].split('"');
    //   const type = splitFileName[1].split(".")[1];
    //   returnUrl =
    //     splitUrl[0] +
    //     "filename" +
    //     splitFileName[0] +
    //     '"' +
    //     shopCode +
    //     "_" +
    //     id +
    //     "_" +
    //     title +
    //     "." +
    //     type +
    //     '"' +
    //     splitFileName[2];
    // }
    return returnUrl;
  }
}
