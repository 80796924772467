
import { Component, Ref, Vue } from "vue-property-decorator";
import { findIndex, isEmpty, get } from "lodash";
import Utils from "@/mixin";
import { Content as ContentType, VForm } from "@/type";

import DateInput from "@/components/DateInput.vue";
import TimeInput from "@/components/TimeInput.vue";

@Component({
  components: {
    DateInput,
    TimeInput,
  },
  props: {
    editId: {
      type: [Number, String],
      default: "",
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    toggleDialog: {
      type: Function,
      // eslint-disable-next-line
      default: /* istanbul ignore next */ () => {},
    },
    setInitialDialog: {
      type: Function,
      // eslint-disable-next-line
      default: /* istanbul ignore next */ () => {},
    },
  },
  mixins: [Utils],
  computed: {
    validate() {
      const {
        title,
        description,
        redeemPoint,
        image,
        imageBannerUrl,
      } = this.formData;
      if (
        (this.editId === "" &&
          (isEmpty(title) ||
            get(description, "length") === 0 ||
            parseInt(redeemPoint) === 0 ||
            image === undefined ||
            imageBannerUrl === undefined)) ||
        (this.editId !== "" &&
          (isEmpty(title) ||
            get(description, "length") === 0 ||
            parseInt(redeemPoint) === 0))
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    editId(nextProps, prevProps) {
      /* istanbul ignore else */
      if (prevProps !== nextProps && nextProps !== "") {
        this.modalTitle = "Edit";
        const { luckydraws } = this.luckyDraw;
        const index = findIndex(
          luckydraws,
          (i: ContentType) => i.id === nextProps
        );
        const data = luckydraws[index];
        // console.log(data);
        this.formData = {
          title: data.title,
          description: data.description,
          redeemPoint: data.redeemPoint,
          startAtDate: data.startAt.substring(0, 10),
          startAtTime: this.generateTime(data.startAt),
          stopAtDate: data.stopAt.substring(0, 10),
          stopAtTime: this.generateTime(data.stopAt),
          announceAtDate: data.announceAt.substring(0, 10),
          announceAtTime: this.generateTime(data.announceAt),
          type: data.type,
        };
      }
    },
  },
})
export default class ContentForm extends Vue {
  modalTitle = "Create";
  today = new Date();
  formData = {
    title: "",
    description: "",
    startAtDate: this.today.toISOString().substring(0, 10),
    startAtTime: this.generateTime(),
    stopAtDate: this.today.toISOString().substring(0, 10),
    stopAtTime: this.generateTime(),
    announceAtDate: this.today.toISOString().substring(0, 10),
    announceAtTime: this.generateTime(),
    redeemPoint: 0,
    type: "weekly",
    image: undefined,
    imageBannerUrl: undefined,
  };
  initialForm = {
    title: "",
    description: "",
    startAtDate: this.today.toISOString().substring(0, 10),
    startAtTime: this.generateTime(),
    stopAtDate: this.today.toISOString().substring(0, 10),
    stopAtTime: this.generateTime(),
    announceAtDate: this.today.toISOString().substring(0, 10),
    announceAtTime: this.generateTime(),
    redeemPoint: 0,
    type: "weekly",
    image: undefined,
    imageBannerUrl: undefined,
  };
  typeItems = ["weekly"];
  /* istanbul ignore next */
  rules = {
    required: (value: [number, string]) => !!value || "Required.",
    min: (value: number) => value > 0 || "Point must more than 0",
  };

  @Ref("form") readonly form!: VForm;
  createContent!: (formData: object) => void;
  editContent!: (formData: object) => void;
  toggleDialog!: () => void;

  updateDateTime(value: string, name: string) {
    const newFormData = {
      ...this.formData,
      [name]: value,
    };
    this.formData = newFormData;
  }

  generateTime(date?: string) {
    let today = new Date();
    if (date) today = new Date(date);
    const hour = today.getHours();
    const min = today.getMinutes();
    if (hour > 9) {
      if (min > 9) {
        return hour + ":" + min;
      } else {
        return hour + ":0" + min;
      }
    } else {
      if (min > 9) {
        return "0" + hour + ":" + min;
      } else {
        return "0" + hour + ":0" + min;
      }
    }
  }

  clearData() {
    if (this.$props.editId !== "") this.$props.setInitialDialog();
    this.toggleDialog();
    this.$nextTick(() => {
      this.modalTitle = "Create";
      this.formData = Object.assign({}, this.initialForm);
      /* istanbul ignore next */
      if (this.form) this.form.resetValidation();
    });
  }

  submitForm() {
    const { formData } = this;
    if (this.$props.editId !== "") {
      const submitForm = {
        title: get(formData, "title"),
        description: get(formData, "description"),
        startAt:
          get(formData, "startAtDate") +
          " " +
          get(formData, "startAtTime") +
          ":00",
        stopAt:
          get(formData, "stopAtDate") +
          " " +
          get(formData, "stopAtTime") +
          ":00",
        announceAt:
          get(formData, "announceAtDate") +
          " " +
          get(formData, "announceAtTime") +
          ":00",
        redeemPoint: get(formData, "redeemPoint").toString(),
        type: get(formData, "type"),
        image: formData.image,
        imageBannerUrl: formData.imageBannerUrl,
        editId: this.$props.editId,
      };
      this.editContent(submitForm);
    } else {
      const submitForm = {
        title: get(formData, "title"),
        description: get(formData, "description"),
        startAt:
          get(formData, "startAtDate") +
          " " +
          get(formData, "startAtTime") +
          ":00",
        stopAt:
          get(formData, "stopAtDate") +
          " " +
          get(formData, "stopAtTime") +
          ":00",
        announceAt:
          get(formData, "announceAtDate") +
          " " +
          get(formData, "announceAtTime") +
          ":00",
        redeemPoint: get(formData, "redeemPoint").toString(),
        type: get(formData, "type"),
        image: formData.image,
        imageBannerUrl: formData.imageBannerUrl,
      };
      this.createContent(submitForm);
    }
    this.clearData();
  }
}
