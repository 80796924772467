
import { Component, Vue } from "vue-property-decorator";
import { forEach, findIndex, get, isEmpty } from "lodash";
import { Product as ProductType, Point } from "@/type";
import Utils from "@/mixin";

import CreateProduct from "./Product/Form.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import LightBox from "@/components/LightBox.vue";

@Component({
  components: {
    ConfirmDialog,
    CreateProduct,
    LightBox,
  },
  mixins: [Utils],
  computed: {
    /* istanbul ignore next */
    pointRuleId() {
      if (!isEmpty(this.$route.params) && !isEmpty(this.pointList)) {
        return get(this, "$route.params.pointRuleId");
      } else {
        this.$router.push({ name: "PointRule" });
      }
      return;
    },
    /* istanbul ignore next */
    generateTable() {
      if (!isEmpty(this.pointList)) {
        const { pointList } = this;
        const tableList: ProductType[] = [];
        const index = findIndex(
          pointList,
          (i: Point) => i.id === this.pointRuleId
        );
        const { products } = pointList[index];
        forEach(products, (i) => {
          const product = new ProductType();
          product.id = i.id;
          product.code = i.code;
          product.name = i.name;
          product.description = i.description;
          product.image = i.image;
          product.point = i.point;
          product.order = i.order;
          tableList.push(product);
        });
        return tableList;
      } else {
        return [];
      }
    },
  },
  mounted() {
    const paramId = get(this, "$route.params.paramId");
    this.breadcrumbItems.push({
      text: "Product",
      disabled: true,
      href: "/point-rule/" + paramId,
    });
  },
})
export default class Product extends Vue {
  editId = "";
  deleteId = -1;
  dialog = false;
  confirmDialog = false;
  page = 1;
  itemPerPage = 10;
  breadcrumbItems = [
    {
      text: "Point Rule",
      disabled: false,
      href: "/point-rule",
    },
  ];
  headers = [
    { text: "Name", value: "name", align: "center", sortable: false },
    { text: "Code", value: "code", align: "center", sortable: false },
    {
      text: "Description",
      value: "description",
      align: "center",
      sortable: false,
    },
    { text: "Point", value: "point", align: "center", sortable: false },
    { text: "Order", value: "order", align: "center", sortable: false },
    {
      text: "Image",
      value: "image",
      align: "center",
      width: "100px",
      sortable: false,
    },
    { text: "Actions", value: "actions", width: "100px", sortable: false },
  ];
  imgArr: Array<string> = [];
  imgShow = false;

  removeProduct!: (formData?: object) => void;
  // eslint-disable-next-line
  showImg(image: any) {
    this.imgShow = true;
    if (typeof image === "string") {
      this.imgArr = [image];
    } else {
      this.imgArr = image;
    }
  }

  toggleDialog() {
    this.dialog = !this.dialog;
  }

  setInitialDialog() {
    this.editId = "";
  }

  editItem(paramId: string) {
    this.editId = paramId;
    this.toggleDialog();
  }

  toggleConfirmModal(paramId: number) {
    this.deleteId = paramId;
    this.confirmDialog = !this.confirmDialog;
  }

  cancel() {
    this.deleteId = -1;
    this.confirmDialog = !this.confirmDialog;
  }

  actionItem() {
    const { deleteId } = this;
    const pointRuleId = get(this, "$route.params.pointRuleId");
    this.removeProduct({ pointRuleId, deleteId });
    this.cancel();
  }
}
