
import moment from "moment";
import { Component, Ref, Vue } from "vue-property-decorator";
import { findIndex, get } from "lodash";
import Utils from "@/mixin";
import { HeroReward, VForm } from "@/type";

@Component({
  props: {
    editId: {
      type: [Number, String],
      default: "",
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    toggleDialog: {
      type: Function,
      // eslint-disable-next-line
      default: /* istanbul ignore next */ () => {},
    },
    setInitialDialog: {
      type: Function,
      // eslint-disable-next-line
      default: /* istanbul ignore next */ () => {},
    },
  },
  mixins: [Utils],
  computed: {
    validate() {
      const { startDate, endDate, description } = this.formData;
      if (
        (this.editId === "" &&
          (get(description, "length") === 0 ||
            startDate === "" ||
            endDate === "")) ||
        (this.editId !== "" &&
          (get(description, "length") === 0 ||
            startDate === "" ||
            endDate === ""))
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    editId(nextProps, prevProps) {
      /* istanbul ignore else */
      if (prevProps !== nextProps && nextProps !== "") {
        this.modalTitle = "Edit";
        const { heroRewardList } = this;
        const index = findIndex(
          heroRewardList,
          (i: HeroReward) => i.id === nextProps
        );
        const data = heroRewardList[index];
        this.formData = {
          startDate: moment(data.startDate, "YYYY-MM-DD HH:mm:ss").format(
            "YYYY-MM-DD"
          ),
          endDate: moment(data.endDate, "YYYY-MM-DD HH:mm:ss").format(
            "YYYY-MM-DD"
          ),
          description: data.description,
          level: data.level,
        };
      }
    },
  },
})
export default class HeroRewardForm extends Vue {
  modalTitle = "Create";
  formData = {
    startDate: "",
    endDate: "",
    description: "",
    level: 6,
  };
  initialForm = {
    startDate: "",
    endDate: "",
    description: "",
    level: 6,
  };
  levelItems = [
    { text: "6 - พนักงานขาย", value: 6 },
    { text: "5 - หัวหน้าเขต", value: 5 },
    { text: "4 - หัวหน้าภาค", value: 4 },
  ];
  startMenu = false;
  endMenu = false;
  /* istanbul ignore next */
  rules = {
    required: (value: [number, string]) => !!value || "Required.",
  };

  @Ref("form") readonly form!: VForm;
  createHeroReward!: (formData: object) => void;
  editHeroReward!: (formData: object) => void;
  toggleDialog!: () => void;

  clearData() {
    if (this.$props.editId !== "") this.$props.setInitialDialog();
    this.toggleDialog();
    this.$nextTick(() => {
      this.modalTitle = "Create";
      this.formData = Object.assign({}, this.initialForm);
      /* istanbul ignore next */
      if (this.form) this.form.resetValidation();
    });
  }

  submitForm() {
    const { formData } = this;
    if (this.$props.editId !== "") {
      const form = {
        ...formData,
        editId: this.$props.editId,
        startDate: moment(formData.startDate).format(),
        endDate: moment(formData.endDate).format(),
      };
      this.editHeroReward(form);
    } else {
      const form = {
        ...formData,
        startDate: moment(formData.startDate).format(),
        endDate: moment(formData.endDate).format(),
      };
      this.createHeroReward(form);
    }
    this.clearData();
  }
}
