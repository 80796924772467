
import { Component, Ref, Vue } from "vue-property-decorator";
import { isEmpty, get } from "lodash";
import Utils from "@/mixin";
import { VForm } from "@/type";

@Component({
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    toggleDialog: {
      type: Function,
      // eslint-disable-next-line
      default: /* istanbul ignore next */ () => {},
    },
  },
  mixins: [Utils],
  computed: {
    validate() {
      const { name, image, order } = this.formData;
      if (isEmpty(name) || image === undefined || order === 0) {
        return true;
      } else {
        return false;
      }
    },
  },
})
export default class BannerForm extends Vue {
  formData = {
    name: "",
    image: undefined,
    isPublic: true,
    order: 0,
  };
  initialForm = {
    name: "",
    image: undefined,
    isPublic: true,
    order: 0,
  };
  publicItems = [
    { text: "Yes", value: true },
    { text: "No", value: false },
  ];
  /* istanbul ignore next */
  rules = {
    required: (value: [number, string]) => !!value || "Required.",
    min: (value: number) => value > 0 || "Point must more than 0",
  };

  @Ref("form") readonly form!: VForm;
  createBanner!: (formData: object) => void;
  toggleDialog!: () => void;

  clearData() {
    this.toggleDialog();
    this.$nextTick(() => {
      this.formData = Object.assign({}, this.initialForm);
      /* istanbul ignore next */
      if (this.form) this.form.resetValidation();
    });
  }

  submitForm() {
    const { formData } = this;
    const submitForm = {
      name: get(formData, "name"),
      image: formData.image,
      isPublic: get(formData, "isPublic"),
      order: get(formData, "order"),
    };
    this.createBanner(submitForm);
    this.clearData();
  }
}
