
import { Component, Vue } from "vue-property-decorator";
import Utils from "@/mixin";

@Component({
  mixins: [Utils],
  mounted() {
    // console.log(this);
  },
})
export default class Home extends Vue {}
