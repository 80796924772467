import { Module } from 'vuex'
import { findIndex, forEach, get, size } from 'lodash'

import { RootState, AlertMessage, PointState, Point } from '@/type'
import { PointList } from '@/type/Point'
import { REQUEST_DELETE, REQUEST_GET, REQUEST_POST, REQUEST_PUT } from './API_Request'
import { errorMessage } from '@/plugins/common'
import { GLOBAL_MESSAGE, POINT_RULE_MEESAGE } from '@/plugins/message'

export const state = {
  pointList: Array<Point>()
}

export const mutations = {
  async GET_POINT_RULE(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      const res = await REQUEST_GET('/point_rules', data.searchForm)
      // console.log(res);
      const pointList = get(res, 'data.data', [])
      if (size(pointList) > 0) {
        const arrayList: Array<Point> = []
        forEach(pointList, i => {
          arrayList.push({ ...i, total: get(res, 'data.total', pointList.length), perPage: get(res, 'data.perPage') })
        })
        state.pointList = arrayList
      } else {
        state.pointList = []
      }
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async CREATE_BRAND(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      // Generate Form
      const formData = new FormData()
      formData.append('brand', get(data, 'formData.brand'))
      formData.append('nameTH', get(data, 'formData.nameTH'))
      formData.append('nameEN', get(data, 'formData.nameEN'))
      formData.append('image', data.formData?.image)
      formData.append('order', get(data, 'formData.order'))
      formData.append('shopType', get(data, 'formData.shopType'))

      const res = await REQUEST_POST('/point_rules/brand', formData)
      // console.log(res);
      switch (get(res, 'status')) {
        case 201: {
          data.toggleLoading()
          const alert = new AlertMessage(POINT_RULE_MEESAGE.CREATE.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          location.reload()
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
      /* istanbul ignore else */
      if (error) alert = new AlertMessage(POINT_RULE_MEESAGE.CREATE.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  },
  async CREATE_PRODUCT(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      // Generate Form
      const formData = new FormData()
      formData.append('name', get(data, 'formData.name'))
      formData.append('code', get(data, 'formData.code'))
      formData.append('description', get(data, 'formData.description'))
      formData.append('point', get(data, 'formData.point'))
      formData.append('image', data.formData?.image)
      formData.append('order', get(data, 'formData.order'))
      const pointId: number = data.formData?.id
      const index = findIndex(state?.pointList, (i: Point) => i.id === pointId)
      const res = await REQUEST_POST('/point_rules/brand/' + pointId + '/product', formData)
      // console.log(res);
      switch (get(res, 'status')) {
        case 201: {
          state.pointList?.[index].products.push(get(res, 'data'))
          data.toggleLoading()
          const alert = new AlertMessage(POINT_RULE_MEESAGE.CREATE.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          // location.reload()
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
      /* istanbul ignore else */
      if (error) alert = new AlertMessage(POINT_RULE_MEESAGE.CREATE.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  },
  async EDIT_BRAND(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      // Generate Form
      const formData = new FormData()
      formData.append('brand', get(data, 'formData.brand'))
      formData.append('nameTH', get(data, 'formData.nameTH'))
      formData.append('nameEN', get(data, 'formData.nameEN'))
      formData.append('order', get(data, 'formData.order'))
      formData.append('shopType', get(data, 'formData.shopType'))
      /* istanbul ignore else */
      if (data.formData?.image !== undefined) formData.append('image', data.formData?.image)

      const res = await REQUEST_PUT('/point_rules/brand/' + get(data, 'formData.editId'), formData)
      // console.log(res);
      switch (get(res, 'status')) {
        case 200: {
          data.toggleLoading()
          forEach(state.pointList, i => {
            /* istanbul ignore else */
            if (i.id === res.data?.id) {
              i.brand = res.data?.brand
              i.nameTH = res.data?.nameTH
              i.nameEN = res.data?.nameEN
              i.order = res.data?.order
              i.shopType = res.data?.shopType
              i.image = res.data?.image
            }
          })
          const alert = new AlertMessage(POINT_RULE_MEESAGE.EDIT.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
      /* istanbul ignore else */
      if (error) alert = new AlertMessage(POINT_RULE_MEESAGE.EDIT.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  },
  async EDIT_PRODUCT(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      // Generate Form
      const formData = new FormData()
      formData.append('name', get(data, 'formData.name'))
      formData.append('code', get(data, 'formData.code'))
      formData.append('description', get(data, 'formData.description'))
      formData.append('point', get(data, 'formData.point'))
      formData.append('order', get(data, 'formData.order'))
      /* istanbul ignore else */
      if (data.formData?.image !== undefined) formData.append('image', data.formData?.image)
      const pointId = get(data, 'formData.id')
      const editId = get(data, 'formData.editId')
      const pointIndex = findIndex(state?.pointList, (i: Point) => i.id === pointId)

      const res = await REQUEST_PUT('/point_rules/product/' + editId, formData)
      // console.log(res);
      switch (get(res, 'status')) {
        case 201: {
          const products = state.pointList[pointIndex].products
          forEach(products, i => {
            /* istanbul ignore else */
            if (i.id === res.data?.id) {
              i.name = res.data?.name
              i.code = res.data?.code
              i.description = res.data?.description
              i.point = res.data?.point
              i.order = res.data?.order
              i.image = res.data?.image
            }
          })
          data.toggleLoading()
          const alert = new AlertMessage(POINT_RULE_MEESAGE.EDIT.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
      /* istanbul ignore else */
      if (error) alert = new AlertMessage(POINT_RULE_MEESAGE.EDIT.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  },
  async REMOVE_BRAND(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      const paramId: number = get(data, 'paramId')

      const res = await REQUEST_DELETE('/point_rules/brand/' + paramId)
      // console.log(res);
      switch (get(res, 'status')) {
        case 204: {
          const pointList: Array<PointList> = []
          forEach(state.pointList, i => {
            /* istanbul ignore else */
            if (get(i, 'id') !== paramId) {
              pointList.push(i)
            }
          })

          state.pointList = pointList
          data.toggleLoading()
          const alert = new AlertMessage(POINT_RULE_MEESAGE.DELETE.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
      /* istanbul ignore else */
      if (error) alert = new AlertMessage(POINT_RULE_MEESAGE.DELETE.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  },
  async REMOVE_PRODUCT(state: any, data: any) { // eslint-disable-line
    data.toggleLoading()
    try {
      const pointRuleId: number = get(data, 'formData.pointRuleId')
      const deleteId: number = get(data, 'formData.deleteId')
      const pointIndex = findIndex(state?.pointList, (i: Point) => i.id === pointRuleId)

      const res = await REQUEST_DELETE('/point_rules/product/' + deleteId)
      // console.log(res);
      switch (get(res, 'status')) {
        case 204: {
          const products = state.pointList[pointIndex].products
          const pointList: Array<PointList> = []
          const deleteIndex = findIndex(products, { id: deleteId })
          // console.log(deleteIndex);
          forEach(products, (i, index) => {
            /* istanbul ignore else */
            if (parseInt(index) !== deleteIndex) {
              pointList.push(i)
            }
          })
          forEach(state.pointList, i => {
            /* istanbul ignore else */
            if (get(i, 'id') === pointRuleId) {
              i.products = pointList
            }
          })

          data.toggleLoading()
          const alert = new AlertMessage(POINT_RULE_MEESAGE.DELETE.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
      /* istanbul ignore else */
      if (error) alert = new AlertMessage(POINT_RULE_MEESAGE.DELETE.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return error
    }
  }
}

export const actions = {
  getPointRule(context: any, searchForm: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    context.commit('GET_POINT_RULE', { toggleLoading, searchForm })
  },
  createBrand(context: any, formData: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm: AlertMessage) => context.dispatch('toggleAlertMessage', alertForm)
    context.commit('CREATE_BRAND', { toggleLoading, formData, toggleAlert })
  },
  createProduct(context: any, formData: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm: AlertMessage) => context.dispatch('toggleAlertMessage', alertForm)
    context.commit('CREATE_PRODUCT', { toggleLoading, formData, toggleAlert })
  },
  editBrand(context: any, formData: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm: AlertMessage) => context.dispatch('toggleAlertMessage', alertForm)
    context.commit('EDIT_BRAND', { toggleLoading, formData, toggleAlert })
  },
  editProduct(context: any, formData: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm: AlertMessage) => context.dispatch('toggleAlertMessage', alertForm)
    context.commit('EDIT_PRODUCT', { toggleLoading, formData, toggleAlert })
  },
  removeBrand(context: any, formData: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm: AlertMessage) => context.dispatch('toggleAlertMessage', alertForm)
    context.commit('REMOVE_BRAND', { toggleLoading, paramId: formData, toggleAlert })
  },
  removeProduct(context: any, formData: object) { // eslint-disable-line
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm: AlertMessage) => context.dispatch('toggleAlertMessage', alertForm)
    context.commit('REMOVE_PRODUCT', { toggleLoading, formData, toggleAlert })
  },
}

export const getters = {
  // Add Logic Before Computed
  pointList(state: any): PointList | undefined { // eslint-disable-line
    return state.pointList
  },
}

export const points: Module<PointState, RootState> = {
  state,
  mutations,
  actions,
  getters
}